<template>
  <div>
    <div class="caption font-weight-medium primary--text uppercase my-2">
      Wenn:
    </div>
    <v-card>
      <v-card-title>
        <v-btn-toggle
          dense
          mandatory
          v-model="conditionsDataLocal.logicalOperator"
          ><v-btn small value="all">ALLE</v-btn
          ><v-btn small value="any">MIND. EINE</v-btn></v-btn-toggle
        >
        <div class="caption font-weight-medium primary--text uppercase ml-2">
          Erfüllt
        </div>
        <v-spacer></v-spacer
        ><v-btn
          small
          depressed
          color="error"
          :disabled="conditionsDataLocal.conditions.length === 0"
          @click="conditionsDataLocal.conditions = []"
          ><v-icon small left>mdi-reload</v-icon>Zurücksetzen</v-btn
        ></v-card-title
      >
      <v-card-text>
        <div
          v-for="(condition, index) in conditionsDataLocal.conditions"
          :key="index"
        >
          <condition-builder-item
            v-if="condition.type === 'single'"
            :conditionItem="condition"
            :dataVariables="dataVariables"
            @deleteCondition="deleteCondition(index)"
          ></condition-builder-item>
          <condition-builder-group
            v-else-if="condition.type === 'group'"
            :conditionGroupItem="condition"
            :dataVariables="dataVariables"
            @deleteConditionGroup="deleteCondition(index)"
          ></condition-builder-group>
          <div
            v-if="index < conditionsDataLocal.conditions.length - 1"
            class="caption font-weight-medium primary--text uppercase mt-n5 mb-2"
          >
            Und:
          </div>
        </div>
      </v-card-text>
      <v-card-actions
        ><v-btn text small class="ml-2 mb-2" @click="addCondition"
          ><v-icon left>mdi-plus</v-icon>Bedingung hinzufügen</v-btn
        >
        <v-btn text small class="mb-2" @click="addConditionGroup"
          ><v-icon left>mdi-plus</v-icon>Gruppe hinzufügen</v-btn
        ></v-card-actions
      >
    </v-card>

    <div class="caption font-weight-medium primary--text uppercase my-2">
      DANN:
    </div>
    <v-card>
      <v-card-title>
        <data-chip
          color="green"
          iconLeft="check-circle"
          text="Zielvorgabe erfüllt"
          smallIcon
        ></data-chip>
      </v-card-title>
    </v-card>

    <div class="caption font-weight-medium primary--text uppercase my-2">
      SONST:
    </div>
    <v-card>
      <v-card-title>
        <data-chip
          color="red"
          iconLeft="close-circle"
          text="Zielvorgabe nicht erfüllt"
          smallIcon
        ></data-chip>
      </v-card-title>
    </v-card>
  </div>
</template>

<script>
import ConditionBuilderItem from "@/components/member-management/performance-targets/ConditionBuilderItem.vue";
import ConditionBuilderGroup from "@/components/member-management/performance-targets/ConditionBuilderGroup.vue";
import DataChip from "@/components/_systemwide/DataChip.vue";
export default {
  name: "condition-builder",
  props: {
    conditionsData: {},
    dataVariables: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  components: { ConditionBuilderItem, ConditionBuilderGroup, DataChip },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
    };
  },
  model: {
    prop: "conditionsData",
    event: "conditionsDataChange",
  },
  created() {},
  computed: {
    conditionsDataLocal: {
      get: function () {
        return this.conditionsData;
      },
      set: function (value) {
        this.$emit("conditionsDataChange", value);
      },
    },
  },
  methods: {
    addCondition() {
      this.conditionsDataLocal.conditions.push({
        type: "single",
        rightArgument: {},
        leftArgument: { value: "", type: "constant" },
        operator: "==",
      });
    },
    addConditionGroup() {
      this.conditionsDataLocal.conditions.push({
        type: "group",
        logicalOperator: "all",
        conditions: [
          {
            type: "single",
            rightArgument: {},
            leftArgument: {},
            operator: "==",
          },
        ],
      });
    },
    deleteCondition(index) {
      this.conditionsDataLocal.conditions.splice(index, 1);
      console.log(index);
    },
  },
};
</script>
