<template>
  <div>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-form class="col s8">
              <v-col cols="12">
                <Subheader
                  icon="mdi-star"
                  title="Standardorganisation"
                ></Subheader>
                <div class="mt-7">
                  <v-card>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-select
                              v-model="preferedOrgID"
                              :items="memberships"
                              outlined
                              single-line
                              clearable
                              label="Standardorganisation"
                              hint="Beim Öffnen der Anwendung wird automatisch diese Organisation aufgerufen. Damit wird die Auswahl aus der Liste der verfügbaren Organisationen übersprungen."
                              persistent-hint
                              item-text="organization.displayName"
                              item-value="organization.id"
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            depressed
                            color="success"
                            :loading="loading1"
                            @click="updateSettings"
                          >
                            <v-icon left> mdi-content-save </v-icon>
                            Speichern
                          </v-btn>
                        </v-card-actions>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
              <v-col cols="12">
                <Subheader
                  icon="mdi-domain"
                  title="Mitgliedschaften in Organisationen verwalten"
                ></Subheader>
                <div class="mt-7">
                  <v-data-iterator
                    :items="memberships"
                    :sort-by="sortBy.toLowerCase()"
                    :sort-desc="sortDesc"
                    no-data-text="Du bist noch nicht Mitglied einer Organisation. Kontaktiere Deinen Administrator, um einer Organisation beizutreten."
                  >
                    <template v-slot:default="props">
                      <v-card>
                        <v-list>
                          <v-list-item
                            v-for="item in props.items"
                            :key="item.organization.id"
                            :disabled="item.access.isDisabled"
                          >
                            <!-- <v-avatar
                              size="48"
                              :color="
                                $vuetify.theme.dark
                                  ? 'grey darken-3'
                                  : 'grey lighten-3'
                              "
                              rounded
                              class="mr-4"
                            >
                              <v-img
                                :src="
                                  item.organization.avatarUrl.size75x75 || ''
                                "
                              ></v-img>
                            </v-avatar> -->
                            <v-list-item-content>
                              <v-list-item-title class="title">
                                {{ item.organization.displayName }}
                                <v-icon
                                  color="red darken-1"
                                  :disabled="item.access.isDisabled"
                                  small
                                  v-if="item.organization.isPrefered"
                                >
                                  mdi-star
                                </v-icon>
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ item.organization.stationNumber }},
                                {{ item.organization.department }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-list-item-action-text
                                v-if="item.access.isDisabled"
                              >
                                <span class="error--text subtitle-2 mr-2"
                                  >Zugriff deaktiviert</span
                                >
                                <v-icon color="error">
                                  mdi-alert-circle
                                </v-icon></v-list-item-action-text
                              >
                              <v-btn
                                v-else
                                color="error"
                                depressed
                                :disabled="item.access.isDisabled"
                                @click="leaveOrganization(item.organization.id)"
                              >
                                <v-icon> mdi-logout </v-icon>Verlassen
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </template>
                  </v-data-iterator>
                </div>
              </v-col>
            </v-form>
          </v-row>
        </v-container>
      </template>
    </section>
    <router-view />
  </div>
</template>

<script>
import { db, auth } from "@/firebase";
import Subheader from "@/components/_systemwide/Subheader.vue";
import { USER } from "../../store/modules";
import { GET_MEMBERSHIPS, GET_USER_PROFILE } from "../../store/action-types";

export default {
  name: "settings-organizations",
  components: {
    Subheader,
  },
  data() {
    return {
      loading1: false,
      preferedOrgID: "",

      sortDesc: false,
      sortBy: "organization.stationNumber",
    };
  },
  computed: {
    memberships() {
      return this.$store.state.user.memberships;
    },
  },
  watch: {
    "$store.state.user.user.settings.preferedOrg.id"(value) {
      this.preferedOrgID = value;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${USER}${GET_MEMBERSHIPS}`);
      this.preferedOrgID = this.$store.state.user.user.settings.preferedOrg.id;
    },
    updateSettings() {
      this.loading1 = true;
      var hintRef = db.collection("users").doc(auth.currentUser.uid);
      hintRef
        .update({
          "settings.preferedOrg.id": this.preferedOrgID,
          // "settings.appearance": this.appearance,
        })
        .then(() => {
          this.loading1 = false;
          this.$store.dispatch(`${USER}${GET_USER_PROFILE}`, {
            uid: auth.currentUser.uid,
            allowRedirect: false,
          });
          // Analytics event -> appearance(light/dark/auto)
        })
        .catch(function (error) {
          console.error("Error updating document: ", error);
        });
    },
    leaveOrganization(id) {
      alert(
        "Bitte kontaktiere den Support und nenne die ID der Organisation:" + id
      );
    },
  },
};
</script>
