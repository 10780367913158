<template>
  <div>
    <NavigationBar showBackBtn titel="Details" :actions="actions">
    </NavigationBar>
    <section>
      <template>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <report-details-card
                :loading="helpers.dataIsLoading"
                :report="report"
              ></report-details-card>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-skeleton-loader
                v-if="helpers.dataIsLoading"
                elevation="2"
                type="card-heading, list-item, divider, article, divider, actions"
              ></v-skeleton-loader>
              <v-row v-else class="match-height">
                <v-col cols="6">
                  <statistics-single-card
                    title="Dauer"
                    color="blue-grey"
                    icon="mdi-timer-sand-complete"
                    :value="getReportDurationText()"
                  ></statistics-single-card>
                </v-col>
                <v-col cols="6">
                  <statistics-single-card
                    title="Teilnehmer"
                    color="blue-grey"
                    icon="mdi-counter"
                    :value="reportParticipantsCount"
                  ></statistics-single-card>
                </v-col>

                <!-- <v-col cols="12" md="6" sm="6">
                  <statistics-single-card
                    :title="
                      report.status === 'finished'
                        ? 'Abgeschlossen am'
                        : 'Fällig bis'
                    "
                    :color="report.status === 'finished' ? 'green' : 'red'"
                    :icon="
                      report.status === 'finished'
                        ? 'mdi-archive-lock-outline'
                        : 'mdi-calendar-alert'
                    "
                    value="XX.XX.2024"
                  ></statistics-single-card>
                </v-col> -->
                <!-- <v-col cols="12" md="6" sm="6">
                  <statistics-single-card
                    :title="
                      report.status === 'finished'
                        ? 'Abgeschlossen von'
                        : 'Berichtsverantwortlich'
                    "
                    :color="report.status === 'finished' ? 'green' : 'red'"
                    :icon="
                      report.status === 'finished'
                        ? 'mdi-account-lock'
                        : 'mdi-account-alert'
                    "
                    :value="report.assignedTo.displayName"
                  ></statistics-single-card>
                </v-col> -->
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-card>
                <v-card-title class="subtitle-1 font-weight-medium">
                  Anwesenheiten
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-divider></v-divider>
                <v-data-table
                  dense
                  :items="dutyAttendances"
                  :headers="helpers.attendanceTable.headers"
                  fixed-header
                  height="660px"
                  group-by="attendanceStatus"
                  sort-by="user.lastName"
                  item-key="meta.id"
                  disable-pagination
                  hide-default-footer
                  :mobile-breakpoint="0"
                  no-data-text="Keine Anwesenheiten erfasst"
                >
                  <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
                    <th :colspan="helpers.attendanceTable.headers.length">
                      <v-icon @click="toggle" class="mr-2"
                        >{{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
                      </v-icon>

                      {{ getAttendanceStatusText(items[0].attendanceStatus) }}
                    </th>
                    <th><v-icon small>mdi-sigma</v-icon>{{ items.length }}</th>
                  </template>
                  <template v-slot:[`item.user.lastName`]="{ item }">
                    {{ item.user.lastName }}
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-if="item.connectedTimeSheet.id"
                          icon
                          x-small
                          text
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          :to="{
                            name: 'accounting-time-sheets-details',
                            params: {
                              organizationId: $route.params.organizationId,
                              itemId: item.connectedTimeSheet.id,
                            },
                          }"
                          ><v-icon x-small>mdi-open-in-app</v-icon></v-btn
                        >
                      </template>
                      <span>Stundenmeldung öffnen</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.duration.start.timestamp`]="{ item }">
                    {{
                      item.attendanceStatus == "1"
                        ? timestampConverter(item.duration.start.timestamp)
                        : "–"
                    }}
                  </template>
                  <template v-slot:[`item.duration.end.timestamp`]="{ item }"
                    >{{
                      item.attendanceStatus == "1"
                        ? timestampConverter(item.duration.end.timestamp)
                        : "–"
                    }}
                  </template>
                  <template
                    v-slot:[`item.assignedPosition.unit.title`]="{ item }"
                  >
                    {{ item.assignedPosition.unit.title }} –
                    {{ item.assignedPosition.position.shortTitle }}
                  </template>
                  <template v-slot:[`item.note`]="{ item }">
                    <v-chip v-if="item.isWaiver" small label outlined
                      >Verzicht</v-chip
                    >{{ item.note }}
                  </template>
                </v-data-table>
              </v-card></v-col
            >
          </v-row>
          <v-row>
            <v-col cols="12">
              <Subheader icon="mdi-history" title="Logs"> </Subheader>
              <div class="">
                <v-timeline dense align-top>
                  <v-timeline-item
                    fill-dot
                    class="mb-2"
                    color="white"
                    icon-color="grey"
                    icon="mdi-pencil"
                    small
                  >
                    <v-card>
                      <v-card-text>
                        <v-textarea
                          v-model="helpers.commentForm.value"
                          dense
                          label="Kommentar hinzufügen"
                          hide-details="auto"
                          outlined
                          no-resize
                          auto-grow
                          rows="3"
                        >
                        </v-textarea>
                        <v-alert
                          v-if="helpers.commentForm.errorMessage"
                          dense
                          type="error"
                          text
                          class="mt-4 mb-0"
                        >
                          {{ helpers.commentForm.errorMessage }}</v-alert
                        >
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <!-- <v-checkbox
                          dense
                          disabled
                          label="Benachrichtigung per E-Mail senden"
                          persistent-hint
                          hint="Eine Benachrichtigung über diesen Eintrag wird per E-Mail an ausgewählte Personen gesendet."
                        ></v-checkbox> -->

                        <v-spacer></v-spacer
                        ><v-btn
                          class="mx-0"
                          color="success"
                          depressed
                          :loading="helpers.commentForm.isLoading"
                          :disabled="!helpers.commentForm.value"
                          @click="addComment"
                        >
                          Hinzufügen
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-timeline-item>

                  <v-slide-x-transition group>
                    <div
                      v-for="entry in logsAndComments"
                      :key="entry.id"
                      class="mb-2"
                    >
                      <v-timeline-item
                        color="white"
                        fill-dot
                        small
                        :icon-color="logEntryActionColor(entry.type)"
                        :icon="logEntryActionIcon(entry.type)"
                      >
                        <v-card flat class="elevation-2">
                          <v-card-text>
                            <v-row justify="space-between">
                              <v-col cols="7">
                                <strong>{{ entry.user.displayName }}</strong>
                                {{ logEntryActionText(entry.type) }}
                                <span v-if="entry.type === 'comment'">
                                  <br />{{ entry.text }}
                                </span>
                                <span v-if="entry.reason">
                                  <br />Grund: {{ entry.reason }}
                                </span></v-col
                              >
                              <v-col class="text-right" cols="5">{{
                                timestampConverter(entry.timestamp)
                              }}</v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-timeline-item>
                    </div>
                  </v-slide-x-transition>
                  <v-divider></v-divider>
                </v-timeline>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <custom-dialog
      v-if="helpers.approveReportDialog.model"
      @close="toggleApproveReportDialog()"
      title="Dientbericht genehmigen"
      text="Änderungen müssen vor der Genehmigung durchgeführt werden. Die Genehmigung wird dann entsprechend gekennzeichnet (Genehmigt mit Änderungen)."
      width="s"
      :errorMessage="helpers.approveReportDialog.errorMessage"
      :loading="helpers.approveReportDialog.isLoading"
      :btnAction="approveReport"
      btnText="Genehmigen"
      fullscreenOnMobile
    >
      <template v-slot:content>
        <v-form
          v-model="helpers.approveReportDialog.formIsValid"
          ref="approveDialogForm"
          @submit.prevent="approveReport()"
          ><v-textarea
            v-model="helpers.approveReportDialog.reasonValue"
            outlined
            label="Begründung/Vermerk"
            auto-grow
            persistent-hint
            hint="Optional"
            class="mt-2 pb-0 m0-0"
          ></v-textarea>
          <v-checkbox
            v-model="
              helpers.approveReportDialog.autoApproveCorrespondingTimeSheets
            "
            label="Stundenmeldungen für Dienstbericht automatisch genehmigen"
          ></v-checkbox>
        </v-form>
      </template>
    </custom-dialog>
    <custom-dialog
      v-if="helpers.moveBackToReviewDialog.model"
      @close="toggleMoveBackToReviewDialog()"
      title="Dientbericht zur Überprüfung übergeben"
      width="s"
      :errorMessage="helpers.moveBackToReviewDialog.errorMessage"
      :loading="helpers.moveBackToReviewDialog.isLoading"
      :btnAction="moveBackToReview"
      fullscreenOnMobile
    >
      <template v-slot:content>
        <v-form
          v-model="helpers.moveBackToReviewDialog.formIsValid"
          ref="allowMoveBackToReviewForm"
          @submit.prevent="moveBackToReview()"
          ><v-textarea
            v-model="helpers.moveBackToReviewDialog.reasonValue"
            outlined
            label="Begründung/Vermerk"
            auto-grow
            persistent-hint
            hint="Optional"
            class="mt-2 pb-0 m0-0"
          ></v-textarea>
        </v-form>
      </template>
    </custom-dialog>
    <custom-dialog
      v-if="helpers.rejectReportDialog.model"
      @close="toggleRejectReportDialog()"
      title="Dientbericht ablehnen"
      width="s"
      :errorMessage="helpers.rejectReportDialog.errorMessage"
      :loading="helpers.rejectReportDialog.isLoading"
      :btnAction="rejectReport"
      :btnDisabled="!helpers.rejectReportDialog.formIsValid"
      btnText="Ablehnen"
      fullscreenOnMobile
    >
      <template v-slot:content>
        <v-form
          v-model="helpers.rejectReportDialog.formIsValid"
          ref="rejectDialogForm"
          @submit.prevent="rejectReport()"
          ><v-textarea
            v-model="helpers.rejectReportDialog.reasonValue"
            outlined
            label="Begründung/Vermerk"
            auto-grow
            required
            :rules="[rules.required]"
            hide-details="auto"
            class="mt-2 pb-0 m0-0"
          ></v-textarea>
        </v-form>
      </template>
    </custom-dialog>
    <custom-dialog
      v-if="helpers.archiveReportDialog.model"
      @close="toggleArchiveReportDialog()"
      title="Dientbericht archivieren"
      width="s"
      :errorMessage="helpers.archiveReportDialog.errorMessage"
      :loading="helpers.archiveReportDialog.isLoading"
      :btnAction="archiveReport"
      :btnDisabled="!helpers.archiveReportDialog.formIsValid"
      btnText="Archivieren"
      fullscreenOnMobile
    >
      <template v-slot:content>
        <v-form
          v-model="helpers.archiveReportDialog.formIsValid"
          ref="archiveDialogForm"
          @submit.prevent="archiveReport()"
          ><v-textarea
            v-model="helpers.archiveReportDialog.reasonValue"
            outlined
            label="Begründung/Vermerk"
            auto-grow
            required
            :rules="[rules.required]"
            hide-details="auto"
            class="mt-2 pb-0 m0-0"
          ></v-textarea>
        </v-form>
      </template>
    </custom-dialog>
    <custom-dialog
      v-if="helpers.editReportDialog.model"
      @close="toggleEditReportDialog()"
      title="Dientbericht zur Korrektur übernehmen"
      text="Änderungen müssen vor der Genehmigung durchgeführt werden. Der Dienstbericht wird dann entsprechend gekennzeichnet (Korrekturmodus). Nur eine Person kann korrekturverantwortlich sein."
      width="s"
      :errorMessage="helpers.editReportDialog.errorMessage"
      :loading="helpers.editReportDialog.isLoading"
      :btnAction="editReport"
      btnText="Korrektur übernehmen"
      fullscreenOnMobile
    >
    </custom-dialog>
    <v-snackbar
      v-model="helpers.snackbar.isVisible"
      class="pb-4"
      bottom
      app
      timeout="3000"
      :color="helpers.snackbar.color"
      ><v-icon class="mr-1">mdi-check-circle</v-icon>
      {{ helpers.snackbar.message }}
    </v-snackbar>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import { db, auth, FieldValue, Timestamp, functions } from "@/firebase";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import ReportDetailsCard from "@/components/duty-management/ReportDetailsCard.vue";
import StatisticsSingleCard from "@/components/statistics/StatisticsSingleCard.vue";
import { DUTY_MANAGEMENT_REPORTS_review } from "@/data/permission-types.js";
export default {
  name: "duty-management-reports-details",
  components: {
    NavigationBar,
    Subheader,
    ReportDetailsCard,
    SupportTools,
    StatisticsSingleCard,
    CustomDialog,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
      helpers: {
        dataIsLoading: false,
        archiveReportDialog: {
          model: false,
          errorMessage: "",
          isLoading: false,
          reasonValue: "",
        },
        approveReportDialog: {
          model: false,
          errorMessage: "",
          isLoading: false,
          reasonValue: "",
          autoApproveCorrespondingTimeSheets: true,
        },
        rejectReportDialog: {
          model: false,
          errorMessage: "",
          isLoading: false,
          reasonValue: "",
        },
        moveBackToReviewDialog: {
          model: false,
          errorMessage: "",
          isLoading: false,
          reasonValue: "",
        },
        editReportDialog: {
          model: false,
          errorMessage: "",
          isLoading: false,
        },
        snackbar: {
          message: "",
          isVisible: false,
        },
        commentForm: {
          isLoading: false,
          value: "",
          errorMessage: "",
        },
        attendanceTable: {
          headers: [
            { text: "Vorname", value: "user.firstName" },
            { text: "Nachname", value: "user.lastName", divider: true },
            {
              text: "Startzeitpunkt",
              value: "duration.start.timestamp",
              divider: true,
            },
            {
              text: "Endzeitpunkt",
              value: "duration.end.timestamp",
              divider: true,
            },
            {
              text: "Abrechnungsart",
              value: "payrollType.title",
              divider: true,
            },
            {
              text: "Einsatzmittel/Position",
              value: "assignedPosition.unit.title",
              divider: true,
            },
            {
              text: "Bemerkung",
              value: "note",
              divider: true,
            },
          ],
          expandedRows: [],
        },
      },
      report: null,
      dutyAttendances: [],
      logs: [],
      comments: [],
    };
  },
  computed: {
    actions() {
      const reportStatus = this.report ? this.report.status : null;
      const actions = [
        {
          title: "Aktionen",
          icon: "mdi-dots-horizontal-circle",
          actionStyle: "textBtnMultiple",
          list: [
            {
              title: "Archivieren",
              permission: `${DUTY_MANAGEMENT_REPORTS_review}`,
              // customPermission: isEventOrganizerWithPermission,
              icon: "mdi-archive-cancel",
              disabled: reportStatus !== "toBeReviewed", // should only be true, if timeSheetStatus === 'toBeReviewed'
              function: this.toggleArchiveReportDialog,
            },
            { divider: true },
            {
              title: "Qualifikationsmeldung generieren",
              permission: `${DUTY_MANAGEMENT_REPORTS_review}`,
              // customPermission: isEventOrganizerWithPermission,
              icon: "mdi-school",
              disabled: true,
              // function: this.toggleRecalculateSalaryDialog,
            },
            {
              title: "Dienstbericht exportieren ",
              // permission: `${SCHEDULING_EVENTS_update}`,
              icon: "mdi-file-export",
              disabled: true,
              // function: this.createFromCSV,
            },
          ],
        },
        { divider: true },
        { actionStyle: "clipboardBtn" },
      ];
      const approveAction = {
        title: "Genehmigen",
        icon: "mdi-check-circle-outline",
        permission: `${DUTY_MANAGEMENT_REPORTS_review}`,
        color: "success",
        actionStyle: "textBtnSubmit",
        function: this.toggleApproveReportDialog,
      };
      const rejectAction = {
        title: "Ablehnen",
        permission: `${DUTY_MANAGEMENT_REPORTS_review}`,
        icon: "mdi-close-circle-outline",
        actionStyle: "textBtnSubmit",
        color: "error",
        function: this.toggleRejectReportDialog,
      };
      const editAction = {
        title: "Bearbeiten",
        permission: `${DUTY_MANAGEMENT_REPORTS_review}`,
        icon: "mdi-pencil",
        actionStyle: "textBtn",
        function: this.toggleEditReportDialog,
      };
      const allowReviewAction = {
        title: "Erneute Prüfung",
        permission: `${DUTY_MANAGEMENT_REPORTS_review}`,
        icon: "mdi-check-all",
        actionStyle: "textBtn",
        color: "purple",
        function: this.toggleMoveBackToReviewDialog,
      };
      if (reportStatus === "inProgress" || reportStatus === "inCorrection") {
        actions.unshift(editAction);
      } else if (reportStatus === "toBeReviewed") {
        actions.unshift(editAction);
        actions.unshift(rejectAction);
        actions.unshift(approveAction);
      } else if (
        reportStatus === "approved" ||
        reportStatus === "rejected" ||
        reportStatus === "archived"
      ) {
        actions.unshift(allowReviewAction);
      }
      return actions;
    },
    checkIfSupport() {
      return this.$store.getters["user/checkIfSupport"];
    },
    code() {
      return [
        { title: "report", data: this.report },
        { title: "dutyAttendances", data: this.dutyAttendances },
        { title: "logsAndComments", data: this.logsAndComments },
      ];
    },
    logsAndComments() {
      return this.logs
        .concat(this.comments)
        .sort((a, b) => b.timestamp - a.timestamp);
    },
    reportParticipantsCount() {
      return this.dutyAttendances.filter(
        (attendance) => attendance.attendanceStatus === "1"
      ).length;
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      this.getReport();
      this.getDutyAttendances();
      this.getLogs();
      this.getComments();
    },
    getReport() {
      return db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("dutyReports")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.report = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    getDutyAttendances() {
      this.dutyAttendances = [];
      return db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("dutyReports")
        .doc(this.$route.params.itemId)
        .collection("dutyAttendances")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.dutyAttendances.push(doc.data());
          });
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    getLogs() {
      this.logs = [];
      return db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("dutyReports")
        .doc(this.$route.params.itemId)
        .collection("logs")
        .get()
        .then((querySnapshot) => {
          const logs = [];
          querySnapshot.forEach((doc) => {
            logs.push(doc.data());
          });
          this.logs = logs;
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    getComments() {
      this.comments = [];
      return db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("dutyReports")
        .doc(this.$route.params.itemId)
        .collection("comments")
        .get()
        .then((querySnapshot) => {
          const comments = [];
          querySnapshot.forEach((doc) => {
            comments.push(doc.data());
          });
          this.comments = comments;
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    addComment() {
      if (this.helpers.commentForm.value != "") {
        this.helpers.commentForm.isLoading = true;
        const commentRef = db
          .collection("organizations")
          .doc(this.$route.params.organizationId)
          .collection("dutyReports")
          .doc(this.$route.params.itemId)
          .collection("comments")
          .doc();

        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

        return commentRef
          .set({
            user: {
              displayName: auth.currentUser.displayName,
              uid: auth.currentUser.uid,
            },
            type: "comment",
            text: this.helpers.commentForm.value,
            mentions: [],
            timestamp: FieldValue.serverTimestamp(),
            id: commentRef.id,
          })
          .then(async () => {
            this.getComments();
            await delay(800);
            this.helpers.commentForm.value = "";
            this.helpers.commentForm.isLoading = false;
          })
          .catch(async (error) => {
            console.error("Error adding document: ", error);
            await delay(800);
            this.helpers.commentForm.errorMessage = error.message;
            this.helpers.commentForm.isLoading = false;
          });
      } else {
        this.helpers.commentForm.errorMessage =
          "Fehler: Es sind nicht alle Pflichtangaben ausgefüllt.";
      }
    },
    logEntryActionText(type) {
      switch (type) {
        case "created":
          return "hat den Dienstbericht erstellt.";
        case "approved":
          return "hat den Dienstbericht genehmigt.";
        case "toBeReviewed":
          return "hat den Dienstbericht zur Prüfung übergeben.";
        case "rejected":
          return "hat den Dienstbericht abgelehnt.";
        case "comment":
          return "hat einen Kommentar hinzugefügt:";
        case "reassigned":
        case "reassignedTakeOver":
          return "hat die Berichtsverantwortung für den Dienstbericht übernommen.";
        case "reassignedCorrection":
          return "hat den Dienstbericht zur Korrektur übernommen.";
        case "completed":
          return "hat den Dienstbericht festgeschrieben.";
        case "archived":
          return "hat den Dienstbericht archiviert.";
        default:
          break;
      }
    },
    logEntryActionColor(type) {
      switch (type) {
        case "created":
          return "primary";
        case "approved":
        case "completed":
          return "green";
        case "toBeReviewed":
          return "purple";
        case "rejected":
          return "red";
        case "reassigned":
        case "reassignedTakeOver":
        case "reassignedCorrection":
          return "primary";
        case "comment":
          return "grey";
        case "archived":
          return "grey blue";
        default:
          break;
      }
    },
    logEntryActionIcon(type) {
      switch (type) {
        case "created":
          return "mdi-plus";
        case "approved":
          return "mdi-check";
        case "toBeReviewed":
          return "mdi-check-all";
        case "rejected":
          return "mdi-close";
        case "reassigned":
        case "reassignedTakeOver":
        case "reassignedCorrection":
          return "mdi-account-switch";
        case "comment":
          return "mdi-comment-text";
        case "completed":
          return "mdi-archive-check";
        case "archived":
          return "mdi-archive-cancel";
        default:
          break;
      }
    },
    timestampConverter(timestamp) {
      const options = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
    getAttendanceStatusText(statusId) {
      switch (statusId) {
        case "1":
          return "Anwesend";
        case "2":
          return "Entschuldigt";
        case "3":
          return "Unentschuldigt";
        default:
          return "Fehler";
      }
    },
    getReportDurationText() {
      // Retrieve the start and end timestamps
      const startTimestamp = this.report.duration.start.timestamp;
      let endTimestamp = this.report.duration.end.timestamp;

      // If endTimestamp is null, use the current timestamp
      if (!endTimestamp) {
        endTimestamp = Timestamp.now();
      }

      // Calculate the duration in milliseconds
      const durationMs = endTimestamp - startTimestamp;

      // Convert the duration to hours and minutes
      const durationMinutes = Math.floor(durationMs / 60);
      const hours = Math.floor(durationMinutes / 60);
      const minutes = durationMinutes % 60;

      // Return the duration in a readable format
      return `${hours}h ${minutes}min`;
    },
    toggleEditReportDialog() {
      if (
        this.report.status === "inProgress" ||
        (this.report.status === "inCorrection" &&
          this.report.correctedBy.uid === auth.currentUser.uid)
      ) {
        this.$router.push({
          name: "duty-management-reports-live",
          params: {
            organizationId: this.$route.params.organizationId,
            itemId: this.$route.params.itemId,
          },
        });
      } else {
        this.helpers.editReportDialog.model =
          !this.helpers.editReportDialog.model;
        this.helpers.editReportDialog.errorMessage = "";
      }
    },
    toggleArchiveReportDialog() {
      this.helpers.archiveReportDialog.model =
        !this.helpers.archiveReportDialog.model;
      this.helpers.archiveReportDialog.errorMessage = "";
      this.helpers.archiveReportDialog.reasonValue = "";
    },
    toggleApproveReportDialog() {
      this.helpers.approveReportDialog.model =
        !this.helpers.approveReportDialog.model;
      this.helpers.approveReportDialog.errorMessage = "";
      this.helpers.approveReportDialog.reasonValue = "";
    },
    toggleRejectReportDialog() {
      this.helpers.rejectReportDialog.model =
        !this.helpers.rejectReportDialog.model;
      this.helpers.rejectReportDialog.errorMessage = "";
      this.helpers.rejectReportDialog.reasonValue = "";
    },
    toggleMoveBackToReviewDialog() {
      this.helpers.moveBackToReviewDialog.model =
        !this.helpers.moveBackToReviewDialog.model;
      this.helpers.moveBackToReviewDialog.errorMessage = "";
      this.helpers.moveBackToReviewDialog.reasonValue = "";
    },
    editReport() {
      this.helpers.editReportDialog.isLoading = true;
      const callFunction = functions.httpsCallable(
        "dutyManagement-reassignReport"
      );
      return callFunction({
        organizationId: this.$route.params.organizationId,
        dutyReportId: this.report.meta.id,
        reassignTo: {
          uid: auth.currentUser.uid,
        },
        reassignMode: "correction",
      })
        .then(() => {
          this.$router.push({
            name: "duty-management-reports-live",
            params: {
              organizationId: this.$route.params.organizationId,
              itemId: this.$route.params.itemId,
            },
          });
        })
        .catch((error) => {
          this.helpers.editReportDialog.isLoading = false;
          this.helpers.editReportDialog.errorMessage = error.message;
          console.error("Error starting correction mode report: ", error);
        });
    },
    approveReport() {
      this.helpers.approveReportDialog.isLoading = true;
      const callFunction = functions.httpsCallable(
        "dutyManagement-approveReport"
      );
      return callFunction({
        organizationId: this.$route.params.organizationId,
        dutyReportId: this.report.meta.id,
        reason: this.helpers.approveReportDialog.reasonValue,
        autoApproveCorrespondingTimeSheets:
          this.helpers.approveReportDialog.autoApproveCorrespondingTimeSheets,
      })
        .then(() => {
          this.getReport();
          this.getDutyAttendances();
          this.getLogs();
          this.helpers.approveReportDialog.isLoading = false;
          this.toggleApproveReportDialog();
          this.helpers.snackbar.message = "Dienstbericht genehmigt.";
          this.helpers.snackbar.isVisible = true;
        })
        .catch((error) => {
          this.helpers.approveReportDialog.isLoading = false;
          this.helpers.approveReportDialog.errorMessage = error.message;
          console.error("Error approving report: ", error);
        });
    },
    rejectReport() {
      this.$refs.rejectDialogForm.validate();
      if (this.helpers.rejectReportDialog.formIsValid) {
        this.helpers.rejectReportDialog.isLoading = true;
        const callFunction = functions.httpsCallable(
          "dutyManagement-rejectReport"
        );
        return callFunction({
          organizationId: this.$route.params.organizationId,
          dutyReportId: this.report.meta.id,
          reason: this.helpers.rejectReportDialog.reasonValue,
        })
          .then(() => {
            this.getReport();
            this.getLogs();
            this.helpers.rejectReportDialog.isLoading = false;
            this.toggleRejectReportDialog();
            this.helpers.snackbar.message = "Dienstbericht abgelehnt.";
            this.helpers.snackbar.isVisible = true;
          })
          .catch((error) => {
            this.helpers.rejectReportDialog.isLoading = false;
            this.helpers.rejectReportDialog.errorMessage = error.message;
            console.error("Error approving report: ", error);
          });
      } else {
        this.helpers.rejectReportDialog.errorMessage =
          "Fehler: Es sind nicht alle Pflichtangaben auusgefüllt.";
      }
    },
    archiveReport() {
      this.$refs.archiveDialogForm.validate();
      if (this.helpers.archiveReportDialog.formIsValid) {
        this.helpers.archiveReportDialog.isLoading = true;
        const callFunction = functions.httpsCallable(
          "dutyManagement-archiveReport"
        );
        return callFunction({
          organizationId: this.$route.params.organizationId,
          dutyReportId: this.report.meta.id,
          reason: this.helpers.archiveReportDialog.reasonValue,
        })
          .then(() => {
            this.getReport();
            this.getLogs();
            this.helpers.archiveReportDialog.isLoading = false;
            this.toggleArchiveReportDialog();
            this.helpers.snackbar.message = "Dienstbericht archiviert.";
            this.helpers.snackbar.isVisible = true;
          })
          .catch((error) => {
            this.helpers.archiveReportDialog.isLoading = false;
            this.helpers.archiveReportDialog.errorMessage = error.message;
            console.error("Error archiving report: ", error);
          });
      } else {
        this.helpers.archiveReportDialog.errorMessage =
          "Fehler: Es sind nicht alle Pflichtangaben auusgefüllt.";
      }
    },
    moveBackToReview() {
      this.helpers.moveBackToReviewDialog.isLoading = true;
      const callFunction = functions.httpsCallable(
        "dutyManagement-moveBackToReview"
      );
      return callFunction({
        organizationId: this.$route.params.organizationId,
        dutyReportId: this.report.meta.id,
        reason: this.helpers.moveBackToReviewDialog.reasonValue,
      })
        .then(() => {
          this.getReport();
          this.getLogs();
          this.helpers.moveBackToReviewDialog.isLoading = false;
          this.toggleMoveBackToReviewDialog();
          this.helpers.snackbar.message =
            "Dienstbericht erneut zur Überprüfung übergeben.";
          this.helpers.snackbar.isVisible = true;
        })
        .catch((error) => {
          this.helpers.moveBackToReviewDialog.isLoading = false;
          this.helpers.moveBackToReviewDialog.errorMessage = error.message;
          console.error("Error approving report: ", error);
        });
    },
  },
};
</script>
