<template>
  <div>
    <v-row class="match-height">
      <v-col
        cols="12"
        :sm="!noActionsAvailable ? '8' : '12'"
        :md="!noActionsAvailable ? '8' : '12'"
        class=""
      >
        <v-card
          ><v-img
            src="@/assets/images/dashboardHero.jpg"
            max-height="150px"
            gradient="to bottom left, rgba(35,38,56,.2), rgba(35,38,56,.8)"
          >
            <div class="mx-6">
              <h3
                class="mb-4 text-h3 font-weight-medium"
                style="color: white; position: absolute; bottom: 0"
              >
                {{ greetingText }}, {{ firstName }}!
              </h3>
            </div>
          </v-img></v-card
        >
      </v-col>
      <v-col v-if="!noActionsAvailable" cols="12" sm="4" md="4" xs="6">
        <v-row class="match-height" dense>
          <v-col
            v-for="(item, index) in quickActions"
            :key="index"
            cols="6"
            sm="6"
            md="6"
            lg="6"
          >
            <!-- cols="6"
              lg="3"
              md="3"
              sm="3" -->
            <!-- :color="
                  $vuetify.theme.dark
                    ? `${item.color} lighten-4`
                    : `${item.color} lighten-5`
                " -->
            <v-card
              class="d-flex align-center rounded-lg"
              :disabled="item.disabled"
              height="70"
              @click="item.action"
              :style="item.isMoreAction ? '' : ''"
            >
              <!-- @click="item.action" -->

              <v-list-item three-line class="text-center mt-1">
                <v-list-item-content>
                  <!-- :color="
                        $vuetify.theme.dark
                          ? `${item.color} darken-4`
                          : `${item.color} darken-4`
                      " -->
                  <v-icon color="primary">{{ item.icon }}</v-icon>
                  <!-- :class=" $vuetify.theme.dark ? `${item.color}--text
                    text--darken-4` : `${item.color}--text text--darken-4` " -->
                  <v-list-item-subtitle
                    class="primary--text mt-n2 font-weight-medium"
                    >{{ item.text }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- </v-card-text> -->
    <!-- </v-card> -->
  </div>
</template>

<script>
// import { SCHEDULING } from "@/store/modules.js";
// import { GET_EVENTS } from "@/store/action-types.js";
import {
  AVAILABILITY_LEAVES_create,
  ACCOUNTING_TIME_SHEETS_create,
} from "@/data/permission-types.js";
export default {
  name: "self-service-quick-actions-card",
  props: {
    noActionsAvailable: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  computed: {
    greetingText() {
      // return greeting text depending on time of the day: "Guten Morgen", "Hallo", "Guten Abend",
      const date = new Date();
      const hours = date.getHours();
      if (hours >= 0 && hours < 12) {
        return "Guten Morgen";
      } else if (hours >= 12 && hours < 18) {
        return "Hallo";
      } else {
        return "Guten Abend";
      }
    },
    firstName() {
      return this.$store.state.user.user.firstName;
    },
    quickActions() {
      return [
        {
          id: "1",
          text: "Abwesenheit melden",
          icon: "mdi-airplane-plus",
          permission: `${AVAILABILITY_LEAVES_create}`,
          color: "blue-grey",
          action: () => {
            this.$router.push({
              name: "availability-leaves-new",
              params: {
                organizationId: this.$route.params.organizationId,
              },
            });
          },
        },
        {
          id: "2",
          text: "Stunden melden",
          icon: "mdi-timer-plus",
          permission: `${ACCOUNTING_TIME_SHEETS_create}`,
          color: "blue-grey",
          action: () => {
            this.$router.push({
              name: "accounting-time-sheets-new",
              params: {
                organizationId: this.$route.params.organizationId,
              },
            });
          },
        },
        {
          id: "3",
          text: "Auslagenersatz beantragen",
          icon: "mdi-invoice-text-plus",
          color: "blue-grey",
          disabled: true,
          action: () => {
            //   this.$router.push({
            //     name: "availability-leaves-new",
            //     params: {
            //       organizationId: this.$route.params.organizationId,
            //     },
            //   });
          },
        },
        {
          id: "4",
          text: "Sonstige Aktionen",
          icon: "mdi-dots-horizontal-circle",
          color: "blue-grey",
          isMoreAction: true,
          disabled: true,
          action: () => {
            // this.toggleOtherActionsDialog()
          },
        },
      ].filter((item) => this.checkPermission(item.permission));
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    checkPermission(item) {
      if (item == "" || item == undefined) {
        return true;
      } else {
        return this.$store.getters["organization/checkPermissionByID"](item);
      }
    },
  },
};
</script>
