var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('NavigationBar',{attrs:{"titel":"Details","actions":_vm.actions,"showBackBtn":""}}),_c('section',[[_c('v-container',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('Subheader',{attrs:{"icon":"mdi-book","title":"Informationen"}}),_c('div',{staticClass:"mt-7"},[_c('v-card',[_c('v-card-title',{staticClass:"subheading"},[_vm._v(" "+_vm._s(_vm.data.template.title)+" ")]),(_vm.data.template.description)?_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.data.template.description)+" ")]):_vm._e(),_c('v-card-text',[_c('span',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-sort-numeric-ascending")]),_vm._v(" "+_vm._s(_vm.data.template.sortKey)+" ")],1)]),_c('v-divider'),_c('v-card-text',[_c('h3',{staticClass:"pb-2"},[_vm._v(" "+_vm._s(_vm.data.data.title)+" ")]),(_vm.data.data.type.id)?_c('div',{staticClass:"py-2"},[_c('v-icon',{attrs:{"small":"","color":_vm.data.data.type.color}},[_vm._v("mdi-circle")]),_c('span',{staticClass:"ml-5"},[_vm._v(_vm._s(_vm.data.data.type.title))])],1):_vm._e(),_c('div',{staticClass:"py-2"},[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-calendar-clock")]),_c('span',{staticClass:"ml-5"},[_vm._v(_vm._s(_vm.data.data.duration.start.time)+" Uhr bis "+_vm._s(_vm.data.data.duration.end.time)+" Uhr")]),(
                        _vm.data.data.duration.start.isOpen &&
                        !_vm.data.data.duration.end.isOpen
                      )?_c('span',{staticClass:"ml-3 error--text"},[_vm._v("Startzeit offen")]):_vm._e(),(
                        !_vm.data.data.duration.start.isOpen &&
                        _vm.data.data.duration.end.isOpen
                      )?_c('span',{staticClass:"ml-3 error--text"},[_vm._v("Endzeit offen")]):_vm._e(),(
                        _vm.data.data.duration.start.isOpen &&
                        _vm.data.data.duration.end.isOpen
                      )?_c('span',{staticClass:"ml-3 error--text"},[_vm._v("Start- und Endzeit offen")]):_vm._e()],1),(_vm.data.data.location.name)?_c('div',{staticClass:"py-2"},[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-map-marker")]),_c('span',{staticClass:"ml-5"},[_vm._v(_vm._s(_vm.data.data.location.name))])],1):_vm._e(),(_vm.data.data.description)?_c('div',{staticClass:"py-2"},[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-calendar-text")]),_c('span',{staticClass:"ml-5"},[_vm._v(_vm._s(_vm.data.data.description))])],1):_vm._e(),(_vm.data.data.config.organizer.isAppointed)?_c('div',{staticClass:"py-2"},[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-account-hard-hat")]),_c('span',{staticClass:"ml-5"},[_vm._v("Organisation: "+_vm._s(_vm.data.data.config.organizer.description))])],1):_vm._e(),(_vm.data.data.config.attendance.isLimited)?_c('div',{staticClass:"py-2"},[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-account-multiple-check")]),_c('span',{staticClass:"ml-5"},[_vm._v("Teilnahmebeschränkung: "+_vm._s(_vm.data.data.config.attendance.description))]),_vm._l((_vm.data.data.config.attendance
                        .allowedDivisions),function(item){return _c('v-chip',{key:item.id,staticClass:"ml-2 my-1",attrs:{"label":"","small":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-home-group")]),_vm._v(_vm._s(item.title))],1)}),_vm._l((_vm.data.data.config.attendance
                        .allowedUserGroups),function(item){return _c('v-chip',{key:item.id,staticClass:"ml-2 my-1",attrs:{"label":"","small":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-folder-account")]),_vm._v(_vm._s(item.title))],1)}),_vm._l((_vm.data.data.config.attendance
                        .allowedPersons),function(item){return _c('v-chip',{key:item.uid,staticClass:"ml-2 my-1",attrs:{"label":"","small":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-account-circle")]),_vm._v(_vm._s(item.displayName))],1)})],2):_vm._e(),(
                      _vm.data.data.config.responses.deadline.isCustom ||
                      _vm.data.data.config.attendance.isMandatory ||
                      _vm.data.data.config.responses.areBinding ||
                      _vm.data.data.config.responses.areLocked
                    )?_c('div',{staticClass:"py-2"},[(_vm.data.data.config.responses.deadline.isCustom)?_c('v-chip',{staticClass:"mr-2 my-1",attrs:{"label":""}},[_vm._v(" Rückmeldefrist "+_vm._s(_vm.data.data.config.responses.deadline.inMinutes)+" Minuten vor Beginn")]):_vm._e(),(_vm.data.data.config.attendance.isMandatory)?_c('v-chip',{staticClass:"mr-2 my-1",attrs:{"label":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-alert-circle")]),_vm._v("Anwesenheitspflicht")],1):_vm._e(),(_vm.data.data.config.responses.areBinding)?_c('v-chip',{staticClass:"mr-2 my-1",attrs:{"label":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-check-decagram")]),_vm._v("Rückmeldungen verbindlich")],1):_vm._e(),(_vm.data.data.config.responses.areLocked)?_c('v-chip',{staticClass:"mr-2 my-1",attrs:{"label":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-lock")]),_vm._v("Rückmeldungen gesperrt")],1):_vm._e()],1):_vm._e()])],1)],1)],1)],1)],1)]],2),(_vm.checkIfSupport)?_c('section',[_c('support-tools',{attrs:{"sources":_vm.code}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }