import * as fb from "@/firebase";
// import { auth } from "../../firebase";
// import router from "../router/index";
import { db } from "@/firebase";
import {
  OWNER_ACCESS,
  ADMIN_ACCESS,
  SUPPORT_ACCESS,
} from "@/data/permission-types.js";

import {
  GET_ORGANIZATION,
  GET_MEMBERSHIP,
  UPDATE_ORGANISATION_INFO,
  UPDATE_ORGANISATION_STANDORT,
  UPDATE_ORGANIZATION_LINKS,
  RESET_ORGANISATION,
} from "../action-types";
import {
  SET_LOADING,
  SET_ORGANISATION,
  SET_MEMBERSHIP,
} from "../mutation-types";

export default {
  namespaced: true,

  state: {
    loading: false,
    membership: null,
    activeOrganization: null,
  },

  mutations: {
    [SET_LOADING](state, payload) {
      state.loading = payload;
    },

    [SET_ORGANISATION](state, payload) {
      state.activeOrganization = payload;
    },

    [SET_MEMBERSHIP](state, payload) {
      state.membership = payload;
    },
  },

  actions: {
    [RESET_ORGANISATION]({ commit }) {
      commit(SET_ORGANISATION, {});
      commit(SET_MEMBERSHIP, {});
    },
    async [GET_ORGANIZATION]({ commit }, payload) {
      commit(SET_LOADING, true);

      return new Promise((resolve, reject) => {
        const docRef = db
          .collection("organizations")
          .doc(payload.organizationId);

        const unsubscribe = docRef.onSnapshot(
          {
            // Listen for document metadata changes
            includeMetadataChanges: true,
          },
          (doc) => {
            if (doc.exists) {
              commit(SET_ORGANISATION, doc.data());
              resolve(); // Resolve the promise if successful
            } else {
              console.log("Organization document not found.");
              reject(new Error("Organization document not found."));
            }
          },
          (error) => {
            console.error("Error fetching organization:", error);
            reject(error);
          }
        );

        // Returning the unsubscribe function so you can later stop listening if needed
        return unsubscribe;
      });
    },

    [UPDATE_ORGANISATION_INFO]({ commit }, payload) {
      commit(SET_LOADING, true);
      fb.organizationCollection
        .doc(payload.organizationId)
        .update({
          fullName: payload.fullName,
          displayName: payload.displayName,
          stationNumber: payload.stationNumber,
          department: payload.department,
          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .then(function () {})
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },

    [UPDATE_ORGANISATION_STANDORT]({ commit }, payload) {
      commit(SET_LOADING, true);
      fb.organizationCollection
        .doc(payload.organizationId)
        .update({
          "location.geopoint": new fb.GeoPoint(
            payload.latitude,
            payload.longitude
          ),
          "location.adress.street": payload.location.adress.street,
          "location.adress.additionalInfo":
            payload.location.adress.additionalInfo,
          "location.adress.postalCode": payload.location.adress.postalCode,
          "location.adress.city": payload.location.adress.city,
          // "location.adress.country": payload.location.adress.country,
          "contact.email": payload.contact.email,
          "contact.phone": payload.contact.phone,
          "contact.fax": payload.contact.fax,
          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .then(function () {})
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },

    [UPDATE_ORGANIZATION_LINKS]({ commit }, payload) {
      commit(SET_LOADING, true);
      fb.organizationCollection
        .doc(payload.organizationId)
        .update({
          links: payload.links,
          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .then(function () {})
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },

    async [GET_MEMBERSHIP]({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const docRef = fb.organizationCollection
          .doc(payload.organizationId)
          .collection("membershipsPrivate")
          .doc(payload.userId);

        const unsubscribe = docRef.onSnapshot(
          {
            // Listen for document metadata changes
            includeMetadataChanges: true,
          },
          (doc) => {
            if (doc.exists) {
              commit(SET_MEMBERSHIP, doc.data());
              resolve(); // Resolve the promise if successful
            } else {
              console.log("Membership document not found.");
              reject(new Error("Membership document not found."));
            }
          },
          (error) => {
            console.error("Error fetching membership:", error);
            reject(error);
          }
        );

        // Returning the unsubscribe function so you can later stop listening if needed
        return unsubscribe;
      });
    },
  },

  getters: {
    /**
     * @description Determines if the specified feature is enabled for the organizazion.
     * @param {string} id the id of the requested feature .
     * @return {boolean} true|false if the feature is enabled.
     */
    checkExtensionAvailabilityById: (state) => (id) => {
      const enabledExtensions =
        state.activeOrganization.config.extensions.activated || [];
      if (enabledExtensions.includes(id)) {
        return true;
      } else {
        return false;
      }
    },

    /**
     * @description Determines if the specified feature is enabled for the organizazion.
     * @param {string} id the id of the requested feature .
     * @return {boolean} true|false if the feature is enabled.
     */
    checkFeatureAvailabilityById: (state) => (id) => {
      const enabledFeatures =
        state.activeOrganization.config.features.enabled || [];
      if (enabledFeatures.includes(id) || enabledFeatures.includes("all")) {
        return true;
      } else {
        return false;
      }
    },

    /**
     * @description Determines if the user has permission for the requested action.
     * @param {string} id the needed permission for this action.
     * @return {boolean} true|false if the user is allowed the requested action.
     */
    checkPermissionByID: (state) => (id) => {
      const permissions =
        state.membership.access.permissions.allPermissionsIds || [];
      if (
        permissions.includes(id) ||
        permissions.includes(`${ADMIN_ACCESS}`) ||
        permissions.includes(`${OWNER_ACCESS}`) ||
        permissions.includes(`${SUPPORT_ACCESS}`)
      ) {
        return true;
      } else {
        return false;
      }
    },

    /**
     * @description Determines if the user has permission for at least one of the requested actions.
     * @param {array} ids the array providing the requested actions.
     * @param {boolean} includeSupport if the support check should be included in the response. (Some actions should also not be allowed for support/service)
     * @return {boolean} true|false if the user is allowed at least one of the requested actions.
     */
    checkPermissionByMultipleIds: (state) => (ids, includeSupport) => {
      const permissions =
        state.membership.access.permissions.allPermissionsIds || [];
      if (includeSupport) {
        return ids.some(
          (id) =>
            permissions.includes(id) ||
            permissions.includes(`${ADMIN_ACCESS}`) ||
            permissions.includes(`${OWNER_ACCESS}`) ||
            permissions.includes(`${SUPPORT_ACCESS}`)
        );
      } else {
        return ids.some(
          (id) =>
            permissions.includes(id) ||
            permissions.includes(`${ADMIN_ACCESS}`) ||
            permissions.includes(`${OWNER_ACCESS}`)
        );
      }
    },
    checkIfSupport: (state) => {
      const permissions =
        state.membership.access.permissions.allPermissionsIds || [];
      const accessType = state.membership.access.type || "";
      return (
        permissions.includes(`${SUPPORT_ACCESS}`) || accessType === "support"
      );
    },
    checkIfItService: (state) => {
      const accessType = state.membership.access.type || "";
      return accessType === "it-service";
    },
    isLoading: () => {
      return true;
      // if (state.membership == null || state.activeOrganisation == null) {
      //   return true
      // }
      // else {
      //   return true
      // }
    },
  },
};
