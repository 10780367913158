<template>
  <div>
    <NavigationBar titel="Anwesenheit" dense :actions="actions"></NavigationBar>

    <section v-if="helpers.showSelector">
      <v-container fluid>
        <v-card>
          <v-card-text>
            <v-container fluid class="mb-n10">
              <v-form
                @submit.prevent="startQuery"
                v-model="helpers.formIsValid"
                ref="form"
              >
                <v-row>
                  <v-col cols="12">
                    <p>Zeitraum auswählen</p>
                    <v-radio-group
                      v-model="query.rangeSelector.mode"
                      row
                      hide-details="auto"
                    >
                      <v-radio
                        label="Letzte Dienstberichte anzeigen"
                        value="lastDutyReports"
                      ></v-radio>
                      <v-radio
                        label="Eigenen Zeitraum auswählen"
                        value="customRange"
                      ></v-radio> </v-radio-group
                  ></v-col>
                  <v-col
                    v-if="query.rangeSelector.mode === 'customRange'"
                    cols="12"
                    sm="12"
                    md="3"
                  >
                    <v-text-field
                      v-model="query.rangeSelector.start"
                      type="date"
                      label="Startdatum"
                      hint="DD.MM.YYYY"
                      outlined
                      :disabled="loading"
                      clearable
                      hide-details
                      prepend-inner-icon="mdi-calendar-start"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    v-if="query.rangeSelector.mode === 'customRange'"
                    cols="12"
                    sm="12"
                    md="3"
                  >
                    <v-text-field
                      v-model="query.rangeSelector.end"
                      type="date"
                      label="Enddatum"
                      hint="DD.MM.YYYY"
                      outlined
                      :rules="[rules.required]"
                      required
                      hide-details
                      :disabled="loading"
                      prepend-inner-icon="mdi-calendar-start"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <p>Nach Dienstart filtern</p>
                    <v-radio-group
                      v-model="query.dutyTypeSelector.mode"
                      row
                      hide-details="auto"
                    >
                      <v-radio
                        label="Alle Dienstberichte anzeigen"
                        value="all"
                      ></v-radio>
                      <v-radio
                        label="Dienstsarten auswählen"
                        value="filterByDutyType"
                      ></v-radio> </v-radio-group
                  ></v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    v-if="query.dutyTypeSelector.mode === 'filterByDutyType'"
                  >
                    <v-select
                      v-model="query.dutyTypeSelector.selection"
                      label="Dienstarten auswählen"
                      :items="dutyTypes"
                      outlined
                      multiple
                      required
                      clearable
                      item-text="title"
                      item-value="meta.id"
                      :disabled="
                        query.dutyTypeSelector.mode !== 'filterByDutyType'
                      "
                      prepend-inner-icon="mdi-list-status"
                      hide-details="auto"
                    >
                      <template v-slot:selection="data">
                        <data-chip
                          :color="data.item.color"
                          class="mr-1"
                          small
                          :iconLeft="data.item.icon"
                          :text="data.item.title"
                        ></data-chip>
                      </template> </v-select
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <p>Nach Personen filtern</p>
                    <v-radio-group
                      v-model="query.personSelector.mode"
                      row
                      disabled
                      hide-details="auto"
                    >
                      <v-radio
                        label="Alle Personen anzeigen"
                        value="all"
                        disabled
                      ></v-radio>
                      <v-radio
                        label="Filter nach Personen"
                        value="filterByPersons"
                        disabled
                      ></v-radio>
                      <v-radio
                        label="Filter nach Abteilung"
                        value="filterByDivisions"
                        disabled
                      ></v-radio> </v-radio-group
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <p>Sortieren nach Startzeitpunkt</p>
                    <v-radio-group
                      v-model="query.orderBy"
                      row
                      hide-details="auto"
                    >
                      <v-radio label="Neueste zuerst" value="desc"></v-radio>
                      <v-radio
                        label="Älteste zuerst"
                        value="asc"
                      ></v-radio> </v-radio-group
                  ></v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="success"
              :loading="helpers.initialLoading"
              :disabled="!helpers.formIsValid"
              @click="startQuery()"
              class="mr-5"
              >Abfrage starten</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-container>
    </section>
    <section v-else class="mt-2 mx-2">
      <v-card v-if="dutyReports.length" height="100%">
        <div class="table-container">
          <table class="table-layout">
            <thead>
              <tr>
                <th class="team-header first-column transparent"></th>
                <th
                  v-for="dutyReport in dutyReports"
                  :key="dutyReport.id"
                  class="event-header"
                >
                  <attendance-records-event-card
                    :dutyReport="dutyReport"
                  ></attendance-records-event-card>
                </th>
                <th class="event-header" v-if="!query.allDocsLoaded">
                  <v-card
                    flat
                    height="100%"
                    class="d-flex align-center justify-center"
                    ><v-card-text
                      ><v-btn
                        outlined
                        text
                        :loading="helpers.nextLoading"
                        @click="loadNextEvents()"
                        >Nächste <v-icon right>mdi-chevron-right</v-icon></v-btn
                      ></v-card-text
                    ></v-card
                  >
                </th>
              </tr>
            </thead>
            <tbody>
              <!-- units -->
              <!-- tasks -->

              <!-- responses for each user -->

              <!-- responses -->
              <template v-for="userGroup in rowGroups">
                <!-- header for each user group -->
                <tr class="v-row-group__header role-header" :key="userGroup.id">
                  <td class="first-column role-name">
                    <attendance-records-group-header-card
                      :title="userGroup.title"
                      :isOpen="isGroupOpen(userGroup.id)"
                      :groupCount="sortedTeamMembers(userGroup).length"
                      @toggle="toggleGroup(userGroup.id)"
                    >
                    </attendance-records-group-header-card>
                  </td>
                </tr>

                <!-- responses for each user -->
                <template v-if="isGroupOpen(userGroup.id)">
                  <!-- user details -->
                  <tr
                    v-for="user in sortedTeamMembers(userGroup)"
                    :key="user.user.uid"
                    class="member-row"
                  >
                    <td class="first-column member-name">
                      <attendance-records-person-card
                        :user="user"
                      ></attendance-records-person-card>
                    </td>

                    <!-- response for each event -->
                    <td v-for="dutyReport in dutyReports" :key="dutyReport.id">
                      <attendance-records-response-status-card
                        :responses="getResponses(dutyReport, user)"
                        :dutyReport="dutyReport"
                        :user="user"
                      ></attendance-records-response-status-card>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
            <tfoot>
              <!-- responses summary -->
              <tr>
                <td
                  class="sum-name"
                  :class="
                    $vuetify.theme.dark
                      ? ['grey darken-2', 'border-dark']
                      : ['grey lighten-3', 'border-light']
                  "
                >
                  <v-card
                    flat
                    height="100%"
                    class="d-block align-center text-truncate transparent"
                  >
                    <v-list-item dense class="px-2">
                      <v-icon class="ml-2">mdi-sigma </v-icon>
                      <span class="font-weight-medium ml-2 text-truncate"
                        >Summe</span
                      >
                    </v-list-item>
                  </v-card>
                </td>

                <!-- responses summary for each event -->
                <td v-for="dutyReport in dutyReports" :key="dutyReport.id">
                  <attendance-records-responses-summary-card
                    :count="getSummaryCount(dutyReport)"
                  ></attendance-records-responses-summary-card>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </v-card>
      <v-card v-else>
        <v-card-text class="d-flex justify-center align-center">
          <span class="font-weight-medium">Keine Dienstberichte vorhanden</span>
        </v-card-text>
      </v-card>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import { db, Timestamp } from "@/firebase";
import { DUTY_MANAGEMENT, ADMIN } from "@/store/modules.js";
import { GET_USER_LIST, GET_REPORT_TYPES } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import DataChip from "@/components/_systemwide/DataChip.vue";
import AttendanceRecordsEventCard from "@/components/duty-management/AttendanceRecordsEventCard.vue";
import AttendanceRecordsPersonCard from "@/components/duty-management/AttendanceRecordsPersonCard.vue";
import AttendanceRecordsResponsesSummaryCard from "@/components/duty-management/AttendanceRecordsResponsesSummaryCard.vue";
import AttendanceRecordsResponseStatusCard from "@/components/duty-management/AttendanceRecordsResponseStatusCard.vue";
import AttendanceRecordsGroupHeaderCard from "@/components/duty-management/AttendanceRecordsGroupHeaderCard.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";

export default {
  name: "duty-management-attendance-records",
  components: {
    NavigationBar,
    DataChip,
    AttendanceRecordsEventCard,
    AttendanceRecordsPersonCard,
    AttendanceRecordsResponseStatusCard,
    AttendanceRecordsResponsesSummaryCard,
    AttendanceRecordsGroupHeaderCard,
    SupportTools,
  },
  data() {
    return {
      actions: [
        {
          title: "Filter",
          icon: "mdi-filter",
          actionStyle: "textBtn",
          function: () => {
            this.helpers.showSelector = true;
          },
        },
      ],
      helpers: {
        showSelector: true,
        initialLoading: false,
        nextLoading: false,
        formIsValid: false,
      },
      query: {
        allDocsLoaded: false,
        orderBy: "desc",
        rangeSelector: {
          mode: "lastDutyReports",
          start: null,
          end: null,
        },
        personSelector: {
          mode: "all",
          selection: [],
        },
        dutyTypeSelector: {
          mode: "all",
          selection: [],
        },
      },
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },

      groupOpenStatus: {},
      dutyReports: [],
      lastVisible: null,
    };
  },
  computed: {
    dutyTypes() {
      // sort these by sortKey ascending
      const dutyType = this.$store.state.dutyManagement.dutyTypes;
      return dutyType.sort((a, b) => a.sortKey - b.sortKey);
    },
    memberships() {
      return this.$store.state.admin.membershipsPublic;
    },
    // membershipsPrepared() {
    //   var range = {
    //     start: this.dutyReport.duration.start.timestampToDate,
    //     end: this.dutyReport.duration.end.timestampToDate,
    //   };
    //   var membershipsFiltered = this.memberships.filter((obj) => {
    //     return (
    //       new Date(obj.user.memberSinceToDate) <= new Date(range.start) && // All members that where members before or at the start of this event
    //       !(new Date(obj.user.memberUntilToDate) <= new Date(range.end)) && // All members that didn't leave before or at the event of this event
    //       obj.access.type === "member"
    //     );
    //   });

    //   var memberships = membershipsFiltered;
    //   // if (this.dutyReport.status !== "planned") {}
    //   const result = memberships.map((item) => {
    //     const container = {
    //       groupByGroup:
    //         item.group.sortKey.toString().padStart(4, "0") +
    //         "_" +
    //         item.group.title,
    //       groupByDivision:
    //         item.user.division.sortKey.toString().padStart(4, "0") +
    //         "_" +
    //         item.user.division.title,
    //       group: {
    //         title: item.group.title,
    //         sortKey: item.group.sortKey,
    //         id: item.group.id,
    //       },
    //       user: {
    //         displayName: item.user.displayName,
    //         uid: item.user.uid,
    //         // photoURL = item.user.photoURL,
    //         // position = item.user.dutyPosition,
    //       },
    //     };
    //     return container;
    //   });
    //   return result;
    // },
    rowGroups() {
      const uniqueGroups = [];
      const groupIds = new Set();

      this.memberships.forEach((member) => {
        if (!groupIds.has(member.group.id)) {
          groupIds.add(member.group.id);
          uniqueGroups.push(member.group);
        }
      });

      return uniqueGroups.sort((a, b) => a.sortKey - b.sortKey);
    },
    checkIfSupport() {
      return this.$store.getters["user/checkIfSupport"];
    },
    code() {
      return [
        { title: "dutyReports", data: this.dutyReports },
        { title: "memberships", data: this.memberships },
        { title: "rowGroups", data: this.rowGroups },
        { title: "groupOpenStatus", data: this.groupOpenStatus },
      ];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.$store.dispatch(`${DUTY_MANAGEMENT}${GET_REPORT_TYPES}`, {
        organizationId: this.$route.params.organizationId,
      });
      await this.$store.dispatch(`${ADMIN}${GET_USER_LIST}`, {
        organizationId: this.$route.params.organizationId,
        queryIsFiltered: true,
        query: {
          type: "active",
        },
      });
    },
    async startQuery() {
      // check if form is valid
      if (!this.helpers.formIsValid) {
        alert("Prüfe Deine Eingabe und versuche es erneut.");
      }

      // then check (if query is custom range) if start date is before end date
      if (
        this.query.rangeSelector.mode === "customRange" &&
        new Date(this.query.rangeSelector.start) >
          new Date(this.query.rangeSelector.end)
      ) {
        alert("Fehler: Startdatum der Abfrage muss vor dem Enddatum liegen.");
        return;
      }

      // then start query
      this.helpers.initialLoading = true;
      this.dutyReports = [];
      this.query.allDocsLoaded = false; // Reset the flag when starting a new query
      await this.getDutyReports();

      for (const userGroup of this.rowGroups) {
        this.groupOpenStatus[userGroup.id] = true;
      }

      setTimeout(() => {
        this.helpers.showSelector = false;
        this.helpers.initialLoading = false;
      }, 2);
    },
    async getDutyReports(limit = 10, startAfter = null) {
      if (this.query.allDocsLoaded) {
        return; // Exit early if all documents are already loaded
      }

      var queryDate = new Date();
      queryDate.setHours(queryDate.getHours());
      const dutyReportsRef = db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("dutyReports");

      let dutyReportsQueryRef = dutyReportsRef.where("status", "in", [
        "toBeReviewed",
        "approved",
      ]);

      if (this.query.rangeSelector.mode === "lastDutyReports") {
        dutyReportsQueryRef = dutyReportsQueryRef.where(
          "duration.start.timestamp",
          "<=",
          Timestamp.fromDate(new Date(queryDate))
        );
      } else if (this.query.rangeSelector.mode === "customRange") {
        dutyReportsQueryRef = dutyReportsQueryRef
          .where(
            "duration.start.timestamp",
            ">=",
            Timestamp.fromDate(new Date(this.query.rangeSelector.start))
          )
          .where(
            "duration.start.timestamp",
            "<=",
            Timestamp.fromDate(new Date(this.query.rangeSelector.end))
          );
      }

      if (this.query.dutyTypeSelector.mode !== "all") {
        dutyReportsQueryRef = dutyReportsQueryRef.where(
          "type.id",
          "in",
          this.query.dutyTypeSelector.selection
        );
      }

      dutyReportsQueryRef = dutyReportsQueryRef.orderBy(
        "duration.start.timestamp",
        this.query.orderBy
      );

      if (startAfter) {
        dutyReportsQueryRef = dutyReportsQueryRef.startAfter(startAfter);
      }

      return dutyReportsQueryRef
        .limit(limit)
        .get()
        .then(async (querySnapshot) => {
          if (querySnapshot.empty) {
            this.query.allDocsLoaded = true; // Set the flag if no new documents are returned
            return;
          }

          this.lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
          const docs = querySnapshot.docs;
          const dutyReports = await Promise.all(
            docs.map(async (doc) => {
              const responsesRef = dutyReportsRef
                .doc(doc.id)
                .collection("dutyAttendances");
              const responsesSnapshot = await responsesRef.get();
              const dutyReport = {
                ...doc.data(),
                responses: responsesSnapshot.docs.map((responseDoc) =>
                  responseDoc.data()
                ),
              };
              return dutyReport;
            })
          );
          return this.dutyReports.push(...dutyReports);
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
    loadNextEvents() {
      if (this.query.allDocsLoaded) {
        return; // Exit early if all documents are already loaded
      }

      this.helpers.nextLoading = true;
      this.getDutyReports(10, this.lastVisible)
        .then(() => {
          this.helpers.nextLoading = false;
        })
        .catch(() => {
          this.helpers.nextLoading = false;
        });
    },
    toggleGroup(userGroupId) {
      this.groupOpenStatus = {
        ...this.groupOpenStatus,
        [userGroupId]: !this.groupOpenStatus[userGroupId],
      };
    },
    isGroupOpen(userGroupId) {
      return this.groupOpenStatus[userGroupId];
    },
    sortedTeamMembers(userGroup) {
      return this.memberships
        .filter((member) => member.group.id === userGroup.id)
        .sort((a, b) => a.user.displayName.localeCompare(b.user.displaName));
    },
    isUserInvited() {
      return true;
    },
    isUserActive(dutyReport, user) {
      const eventStartTimestamp = dutyReport.duration.start.timestamp
        ? dutyReport.duration.start.timestamp.toDate()
        : null;
      const eventEndTimestamp = dutyReport.duration.end.timestamp
        ? dutyReport.duration.end.timestamp.toDate()
        : null;

      const memberSince = user.user.memberSince;
      const memberUntil = user.user.memberUntil;

      if (!eventStartTimestamp || !eventEndTimestamp) {
        // Handle the case where one of the timestamps is null
        return false;
      }

      if (
        memberSince <= eventStartTimestamp &&
        memberUntil >= eventEndTimestamp
      ) {
        return false;
      }

      return true;
    },
    getResponses(dutyReport, user) {
      const emptyResponse = {
        meta: {
          uid: user.user.uid,
          lastUpdatedBy: "",
        },
        attendanceStatus: "0",
        assignedPosition: {
          unit: { title: null, id: null },
          position: { title: null, id: null },
          comboId: "",
        },
        comment: "",
        payrollType: null,
        duration: {
          start: {
            date: null,
            time: null,
            timestamp: null,
          },
          end: {
            date: null,
            time: null,
            timestamp: null,
          },
          inHours: null,
          inMinutes: null,
        },
      };

      const responses = dutyReport.responses.filter(
        (response) => response.user.uid === user.user.uid
      );

      if (!this.isUserActive(dutyReport, user)) {
        emptyResponse.attendanceStatus = "-2";
      } else if (!responses && !this.isUserInvited(dutyReport, user)) {
        emptyResponse.attendanceStatus = "-1";
      }

      return responses ? responses : [...emptyResponse];
    },
    getSummaryCount(dutyReport) {
      return {
        totalPresent: dutyReport.responses.filter(
          (response) => response.attendanceStatus === "1"
        ).length,
        totalExcused: dutyReport.responses.filter(
          (response) => response.attendanceStatus === "2"
        ).length,
        totalUnexcused: dutyReport.responses.filter(
          (response) => response.attendanceStatus === "3"
        ).length,
      };
    },
  },
};
</script>

<style scoped>
table {
  border-spacing: 6px;
  border-collapse: separate;
}
table > thead > tr > th {
  position: sticky;
  top: 0;
}
tbody {
  display: table-row-group;
  vertical-align: middle;
  unicode-bidi: isolate;
  border-spacing: 0;
}

tr {
  display: table-row;
  vertical-align: inherit;
  unicode-bidi: isolate;
}

.crossed::after,
.crossed::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.crossed::after {
  background: linear-gradient(
    to bottom right,
    transparent calc(50% - 1px),
    rgba(0, 0, 0, 0.12),
    transparent calc(50% + 1px)
  );
}

.crossed::before {
  background: linear-gradient(
    to top right,
    transparent calc(50% - 1px),
    rgba(0, 0, 0, 0.12),
    transparent calc(50% + 1px)
  );
}
.border-light {
  border: thin solid rgba(0, 0, 0, 0.12) !important;
}

.border-light.lighten-4 {
  border: thin solid rgba(0, 0, 0, 0.12) !important;
}

.border-dark {
  border: thin solid hsla(0, 0%, 100%, 0.12) !important;
}

.border-dark.lighten-4 {
  border: thin solid hsla(0, 0%, 100%, 0.12) !important;
}

.table-container {
  overflow-x: auto;
  display: flex;
}

.table-layout {
  table-layout: fixed;
  min-width: max-content;
}

.team-header {
  width: 225px;

  max-height: 100px;
  position: sticky !important;
  left: 0;
  top: 0;
  z-index: 2;
}

.event-header {
  width: 140px;
  max-width: 140px !important;
  position: sticky !important;
  top: 0;
}

.role-header {
  height: 30px;
}

.first-column {
  position: sticky;
  left: 0;
  max-width: 190px !important;
  width: 190px !important;
}

@media (max-width: 600px) {
  .first-column {
    max-width: 135px !important;
    width: 135px !important;
  }
}

.role-name {
  top: 0;
  border-right: none;
}

.sum-name {
  position: sticky;
  left: 0;
  top: 0;
  border-right: none;
  max-width: 190px !important;
  z-index: 2;
}

.role-colspan {
  border-left: none;
}

.task-row {
  min-height: 55px !important;
  height: 55px !important;
  max-height: 55px !important;
}

.task-cell {
  position: sticky;
  left: 0;
  max-width: 190px !important;
  z-index: 2;
}

.member-row {
  /* min-height: 55px !important;
  height: 55px !important;
  max-height: 55px !important; */
}

.member-name {
  z-index: 2;
}
</style>
