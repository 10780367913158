<template>
  <div>
    <template>
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="12">
            <Subheader icon="mdi-card-text" title="Allgemein"></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="8">
                        <v-text-field
                          v-model.trim="unitLocal.title"
                          :counter="55"
                          label="Bezeichnung"
                          hide-details="auto"
                          :rules="[rules.required]"
                          required
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4"
                        ><v-text-field
                          v-model.number="unitLocal.priority"
                          type="number"
                          label="Priorität"
                          :rules="[rules.required]"
                          required
                          hide-details="auto"
                          outlined
                        ></v-text-field
                      ></v-col>
                      <v-col cols="12" sm="6" md="8">
                        <v-checkbox
                          v-model="unitLocal.forecastingEnabled"
                          label="Vorausplanung für Einsatzmittel aktivieren"
                          persistent-hint
                          disabled
                          hint="Anhand gespeicherter Besetzungen, Funktionen und Abmeldungen wird die Verfügbarkeit und Besetzbarkeit automatisiert prognostiziert."
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-checkbox
                          v-model="unitLocal.isExternal"
                          label="Externes Einsatzmittel"
                        ></v-checkbox>
                      </v-col>
                      <!-- <v-col cols="12" sm="12" md="12">
                        <v-file-input
                          v-model="file"
                          outlined
                          chips
                          show-size
                          clearable
                          accept=".png"
                          label="Grafik auswählen"
                          hint="Bild im Format .png hochladen, es empfiehlt sich eine freigestellte Grafik!"
                          persistent-hint
                          prepend-icon=""
                          prepend-inner-icon="mdi-paperclip"
                        >
                        </v-file-input>
                      </v-col> -->
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12">
            <Subheader icon="mdi-car-seat" title="Besetzungen"></Subheader>
          </v-col>
          <v-col
            v-for="(crewCombination, index) in unitLocal.crewCombinations"
            :key="index"
            cols="12"
          >
            <v-card>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="2"
                      ><v-text-field
                        v-model.number="crewCombination.priority"
                        type="number"
                        dense
                        label="Priorität"
                        :rules="[rules.required]"
                        required
                        hide-details="auto"
                        outlined
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" sm="6" md="6"
                      ><v-text-field
                        v-model.trim="crewCombination.title"
                        dense
                        hide-details="auto"
                        label="Bezeichnung"
                        :rules="[rules.required]"
                        required
                        outlined
                      ></v-text-field
                    ></v-col>

                    <v-col cols="12" sm="12" md="4">
                      <v-text-field
                        v-model.trim="crewCombination.strength"
                        label="Besetzung"
                        required
                        dense
                        hide-details="auto"
                        :rules="[
                          rules.required,
                          rules.besetzung1,
                          rules.besetzung2,
                          rules.besetzung3,
                        ]"
                        hint="Besetzung nach gängigem Format, Beispiel:
                          1:5"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      v-for="(position, index) in crewCombination.positions"
                      cols="12"
                      sm="12"
                      md="4"
                      :key="index"
                    >
                      <v-card outlined>
                        <v-card-text>
                          <v-select
                            v-model="position.position"
                            :items="positions"
                            item-text="title"
                            menu-props="auto"
                            outlined
                            clearable
                            :rules="[rules.required]"
                            label="Funktion"
                            return-object
                            hide-details="auto"
                            no-data-text="Keine Funktionen verfügbar"
                            placeholder="Funktion auswählen"
                            small-chips
                          >
                          </v-select>

                          <v-checkbox
                            v-if="!!position.position.title"
                            v-model="position.isOptional"
                            label="Besetzung ist optional"
                            hide-details="auto"
                          ></v-checkbox>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="error"
                            text
                            class="hidden-md-and-down"
                            @click="deletePosition(index, position)"
                          >
                            <v-icon left>mdi-delete</v-icon>Entfernen
                          </v-btn>
                          <v-btn
                            color="error"
                            icon
                            text
                            class="hidden-lg-and-up"
                            @click="deletePosition(index, position)"
                          >
                            <v-icon left>mdi-delete</v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>

                    <v-col cols="12" align="center">
                      <v-btn color="success" @click="addPosition(index)">
                        <v-icon left>mdi-plus</v-icon>Funktion hinzufügen
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <!-- <v-btn
                        text
                        class="hidden-md-and-down"
                        @click="duplicateCrewCombination()"
                      >
                        <v-icon left>mdi-content-duplicate</v-icon>Duplizieren
                      </v-btn>
                      <v-btn
                        icon
                        class="hidden-lg-and-up"
                        @click="duplicateCrewCombination()"
                      >
                        <v-icon>mdi-content-duplicate</v-icon>
                      </v-btn> -->
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  text
                  class="hidden-md-and-down"
                  @click="deleteCrewCombination(crewCombination)"
                >
                  <v-icon left>mdi-delete</v-icon>Entfernen
                </v-btn>
                <v-btn
                  color="error"
                  icon
                  text
                  class="hidden-lg-and-up"
                  @click="deleteCrewCombination(crewCombination)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" align="center">
            <v-btn color="success" @click="addCrewCombination()">
              <v-icon left>mdi-plus</v-icon>Besetzung hinzufügen
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import { db } from "@/firebase";
import Subheader from "@/components/_systemwide/Subheader.vue";
import { AVAILABILITY } from "@/store/modules.js";
import { GET_POSITIONS } from "@/store/action-types.js";
export default {
  name: "unit-editor",
  props: {
    unit: {},
  },
  components: {
    Subheader,
  },
  data() {
    return {
      file: null,

      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
        besetzung1: (value) =>
          (value && value.length <= 6) ||
          "Darf nicht länger als 6 Zeichen sein",
        besetzung2: (value) =>
          /[0-9:]$/.test(value) || "Darf nur Nummern und : enthalten",
        besetzung3: (value) =>
          /^\d?\d?\d?\d:\d?\d?\d?\d$/.test(value) ||
          "Muss gängigem Format für Besetzung _:_ folgen",
      },
    };
  },
  model: {
    prop: "unit",
    event: "unitChange",
  },
  computed: {
    unitLocal: {
      get: function () {
        return this.unit;
      },
      set: function (value) {
        this.$emit("unitChange", value);
      },
    },
    positions() {
      const input = this.$store.state.availability.positions;

      const filtered = input.map((item) => {
        return {
          title: item.title,
          shortTitle: item.shortTitle,
          color: item.color,
          priority: item.priority,
          substitute: item.substitute,
          preconditions: item.preconditions,
          id: item.meta.id,
        };
      });

      return filtered.sort((a, b) => {
        return a.priority - b.priority;
      });
    },
    // documentsFiltered() {
    //   var input = this.documents;
    //   var filtered = input.filter((item) => {
    //     return item.position !== null;
    //   });
    //   return filtered;
    // },
    // funktionenIDs() {
    //   return this.documentsFiltered.map((a) => a.position.id);
    // },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${AVAILABILITY}${GET_POSITIONS}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    addCrewCombination() {
      const crewCombinationRef = db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("units_crewCombinations")
        .doc();
      this.unitLocal.crewCombinations.push({
        id: crewCombinationRef.id,
        priority: null,
        title: null,
        strength: null,
        positions: [],
      });
    },
    deleteCrewCombination(crewCombination) {
      this.unitLocal.crewCombinations.splice(
        this.unitLocal.crewCombinations.indexOf(crewCombination),
        1
      );
    },
    addPosition(index) {
      this.unitLocal.crewCombinations[index].positions.push({
        position: {
          title: null,
          shortTitle: null,
          color: null,
          id: null,
        },
        isOptional: false,
      });
    },
    deletePosition(index, position) {
      this.unitLocal.crewCombinations[index].positions.splice(
        this.unitLocal.crewCombinations[index].positions.indexOf(position),
        1
      );
    },
  },
};
</script>
