<template>
  <div>
    <v-card outlined @click="toggleDutyReportDetailsDialog" height="100%">
      <v-card-title
        class="font-weight-medium text-left subtitle-2 pt-1 pb-0 px-2 d-block text-truncate"
      >
        {{ dutyReport.title }}
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text class="text-left px-2 pt-0 pb-1">
        <data-chip
          xSmall
          :text="dutyReport.type.shortTitle"
          :color="dutyReport.type.color"
        ></data-chip>
        <br />
        <div class="text-truncate">
          <v-icon x-small left class="mr-1">mdi-calendar-start</v-icon>
          <small>{{
            timestampConverter(dutyReport.duration.start.timestamp)
          }}</small>
          <br />
          <v-icon x-small left class="mr-1">mdi-calendar-end</v-icon>
          <small>{{
            timestampConverter(dutyReport.duration.end.timestamp)
          }}</small>
          <br />
          <v-icon x-small left class="mr-1">mdi-map-marker</v-icon
          ><small>{{ dutyReport.location.name }}</small>
        </div>
      </v-card-text>
    </v-card>
    <CustomDialog
      v-if="showDutyReportDetailsDialog"
      @close="toggleDutyReportDetailsDialog()"
      title="Dienstdetails"
      fullscreenOnMobile
      width="s"
      showCloseBtn
      hideCloseBtn
      hideActionBtn
    >
      <template v-slot:content>
        <report-details-card
          :report="dutyReport"
          showDetailsButton
          outlined
        ></report-details-card>
      </template>
    </CustomDialog>
  </div>
</template>

<script>
import DataChip from "@/components/_systemwide/DataChip.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import ReportDetailsCard from "@/components/duty-management/ReportDetailsCard.vue";
export default {
  name: "attendance-records-event-card",
  components: {
    DataChip,
    CustomDialog,
    ReportDetailsCard,
  },
  props: {
    dutyReport: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDutyReportDetailsDialog: false,
    };
  },
  methods: {
    toggleDutyReportDetailsDialog() {
      this.showDutyReportDetailsDialog = !this.showDutyReportDetailsDialog;
    },
    timestampConverter(timestamp) {
      const options = {
        weekday: "short",
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
  },
};
</script>
