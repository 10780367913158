<template>
  <div>
    <NavigationBar
      titel="Dienstberichte"
      isPreview
      :actions="actions"
    ></NavigationBar>
    <TippCard
      hintID="Dienstverwaltung_Dienstberichte_1_V1"
      text="Veranstaltungen, Dienste, Einsätze/Alarme etc. können in Form von Dienstberichten dokumentiert werden. Entsprechend der Berichts- und Organisationseinstellungen werden möglichst viele Daten automatisch vorausgefüllt. Nach Abschluss eines Berichts beginnt ein Prüf- und Genehmigungsprozess bevor der Bericht abgeschlossen und in die Stundenabrechnung übergeben wird."
    >
    </TippCard>
    <section>
      <template>
        <v-container fluid>
          <!-- <v-row>
            <v-col
              v-for="data in statisticsData"
              :key="data.title"
              md="4"
              sm="4"
              class="align-center"
            >
              <statistics-single-card
                :title="data.title"
                :color="data.color"
                :icon="data.icon"
                :value="data.value"
              ></statistics-single-card>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col cols="12">
              <reports-data-table :reports="reports"></reports-data-table>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import ReportsDataTable from "@/components/duty-management/ReportsDataTable.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
// import StatisticsSingleCard from "@/components/statistics/StatisticsSingleCard.vue";
import { DUTY_MANAGEMENT } from "@/store/modules.js";
import { GET_REPORTS } from "@/store/action-types.js";
import {
  DUTY_MANAGEMENT_REPORTS_create,
  DUTY_MANAGEMENT_REPORTS_read_all,
} from "@/data/permission-types.js";
export default {
  name: "duty-management-reports",
  components: {
    NavigationBar,
    ReportsDataTable,
    SupportTools,
    TippCard,
    // StatisticsSingleCard,
  },
  data() {
    return {
      actions: [
        {
          title: "Erstellen",
          permission: `${DUTY_MANAGEMENT_REPORTS_create}`,
          icon: "mdi-plus-circle",
          actionStyle: "textBtn",
          function: () => {
            this.$router.push({
              name: "duty-management-reports-new",
              params: {
                organizationId: this.$route.params.organizationId,
              },
            });
          },
        },
      ],
      helpers: {
        displayMode: "table",
      },

      statisticsData: [
        {
          title: "Offen",
          icon: "mdi-history",
          color: "red",
          value: "4",
        },
        {
          title: "In Bearbeitung",
          icon: "mdi-pencil-box-multiple",
          color: "orange",
          value: "2",
        },
        {
          title: "Zu prüfen",
          icon: "mdi-check-all",
          color: "green",
          value: "4",
        },
      ],
    };
  },
  computed: {
    reports() {
      return this.$store.state.dutyManagement.reports;
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [{ title: "reports", data: this.reports }];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      var allowedToReadAllDutyReports = this.$store.getters[
        "organization/checkPermissionByID"
      ](`${DUTY_MANAGEMENT_REPORTS_read_all}`);
      if (allowedToReadAllDutyReports) {
        this.$store.dispatch(`${DUTY_MANAGEMENT}${GET_REPORTS}`, {
          organizationId: this.$route.params.organizationId,
          query: {
            queryIsFiltered: false,
          },
        });
      } else {
        this.$store.dispatch(`${DUTY_MANAGEMENT}${GET_REPORTS}`, {
          organizationId: this.$route.params.organizationId,
          query: {
            queryIsFiltered: true,
            onlyOwn: true,
          },
        });
      }
    },
  },
};
</script>
