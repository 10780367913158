<template>
  <div>
    <NavigationBar titel="Aufgabenverteilung"></NavigationBar>
    <section>
      <template>
        <v-container fluid>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <v-data-iterator
                :items="assignments"
                :search="assignmentsSearch"
                sort-by="title"
                hide-default-footer
                disable-pagination
                no-data-text="Keine Aufgabengebiete vorhanden."
                no-results-text="Keine Aufgabengebiete zum Suchbegriff gefunden."
              >
                <template v-slot:header>
                  <v-row>
                    <v-col>
                      <v-card class="mb-2">
                        <v-card-title>
                          <v-text-field
                            v-model="assignmentsSearch"
                            outlined
                            label="Aufgabengebiete durchsuchen"
                            dense
                            prepend-inner-icon="mdi-magnify"
                            clearable
                            hide-details
                          ></v-text-field>
                        </v-card-title>
                      </v-card>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:default="props">
                  <v-row>
                    <v-col
                      v-for="assignment in props.items"
                      :key="assignment.meta.id"
                      cols="12"
                    >
                      <assignment-detail-card
                        :assignment="assignment"
                      ></assignment-detail-card>
                    </v-col>
                  </v-row>
                </template>
              </v-data-iterator>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import AssignmentDetailCard from "@/components/member-management/assignments/AssignmentDetailCard.vue";
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import { GET_ASSIGNMENTS } from "@/store/action-types.js";

export default {
  name: "my-organization-assignments",
  components: {
    NavigationBar,
    AssignmentDetailCard,
  },
  data() {
    return {
      assignmentsSearch: "",
    };
  },
  computed: {
    assignments() {
      return this.$store.state.memberManagement.assignments;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_ASSIGNMENTS}`, {
        organizationId: this.$route.params.organizationId,
        queryIsFiltered: true,
        query: {
          type: "excludeHiddenAssignments",
        },
      });
    },
  },
};
</script>
