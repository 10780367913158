import * as fb from "@/firebase";
// import { auth } from "../../firebase";
import router from "@/router/index";
// import { db } from "@/firebase";

import {
  SET_LEAVES,
  SET_LOADING,
  SET_UNITS,
  SET_POSITIONS,
  SET_REQUESTS,
  SET_ARCHIVED_LEAVES,
  SET_ARCHIVED_REQUESTS,
  SET_REQUEST_TYPES,
  SET_REQUESTS_STATUS,
} from "../mutation-types";
import {
  GET_LEAVES,
  CREATE_LEAVE,
  UPDATE_LEAVE,
  GET_UNITS,
  CREATE_UNIT,
  GET_POSITIONS,
  CREATE_POSITION,
  CREATE_POSITIONS,
  UPDATE_POSITION,
  DELETE_POSITION,
  DELETE_UNIT,
  UPDATE_UNIT,
  GET_REQUESTS,
  GET_REQUESTS_STATUS,
  GET_ARCHIVED_LEAVES,
  GET_ARCHIVED_REQUESTS,
  CREATE_REQUEST,
  UPDATE_REQUEST,
  UPDATE_REQUEST_RESPONSE,
  // DELETE_REQUEST,
  GET_REQUEST_TYPES,
  CREATE_REQUEST_TYPE,
  CREATE_REQUEST_TYPES,
  UPDATE_REQUEST_TYPE,
  DELETE_REQUEST_TYPE,
  UPDATE_AVAILABILITY_CONFIG,
} from "../action-types";

export default {
  namespaced: true,

  state: {
    // Data
    leaves: [],
    requests: [],
    requestTypes: [],
    requestsStatus: [],
    units: [],
    positions: [],
    archivedRequests: [],
    archivedLeaves: [],
  },

  mutations: {
    [SET_LOADING](state, payload) {
      state.loading = payload;
    },
    [SET_LEAVES](state, payload) {
      state.leaves = payload;
    },
    [SET_REQUESTS](state, payload) {
      state.requests = payload;
    },
    [SET_REQUEST_TYPES](state, payload) {
      state.requestTypes = payload;
    },
    [SET_REQUESTS_STATUS](state, payload) {
      state.requestsStatus = payload;
    },
    [SET_ARCHIVED_REQUESTS](state, payload) {
      state.archivedRequests = payload;
    },
    [SET_ARCHIVED_LEAVES](state, payload) {
      state.archivedLeaves = payload;
    },
    [SET_UNITS](state, payload) {
      state.units = payload;
    },
    [SET_POSITIONS](state, payload) {
      state.positions = payload;
    },
  },

  actions: {
    [GET_LEAVES]({ commit }, payload) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      const data = [];
      var limit = 300;
      if (payload.paginationLimit != undefined) {
        limit = payload.paginationLimit;
      }

      var endDateLimit = new Date();
      endDateLimit.setHours(endDateLimit.getHours() - 1); // 1h before now

      const baseReference = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("leaves")
        .where(
          "duration.end.timestamp",
          ">=",
          fb.Timestamp.fromDate(new Date(endDateLimit))
        );
      var reference = baseReference; // base case queries all entries
      if (payload.query.queryIsFiltered) {
        if (payload.query.filterByUid) {
          reference = reference.where("user.uid", "==", payload.uid);
        }
        if (payload.query.onlyOwn) {
          reference = reference.where(
            "user.uid",
            "==",
            fb.auth.currentUser.uid
          );
        }
      }

      reference
        .limit(limit)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const object = {
              ...doc.data(),

              // Overwrite
              duration: {
                start: {
                  timestamp: doc.data().duration.start.timestamp,
                  formattedTimestamp: doc
                    .data()
                    .duration.start.timestamp.toDate()
                    .toLocaleString("de-DE", options),
                },
                end: {
                  timestamp: doc.data().duration.end.timestamp,
                  formattedTimestamp: doc
                    .data()
                    .duration.end.timestamp.toDate()
                    .toLocaleString("de-DE", options),
                  isOpen: doc.data().duration.end.isOpen,
                },
              },
            };
            data.push(object);
          });
          commit(SET_LEAVES, data);
        })
        .catch(function (error) {
          console.error("Error getting document:", error);
          return false;
        });
    },
    [GET_ARCHIVED_LEAVES]({ commit }, payload) {
      const leaves = [];
      var options = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      var limit = 300;
      if (payload.paginationLimit != undefined) {
        limit = payload.paginationLimit;
      }

      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("leaves")
        .where(
          "duration.start.timestamp",
          ">=",
          fb.Timestamp.fromDate(new Date(payload.query.range.start))
        )
        .where(
          "duration.start.timestamp",
          "<=",
          fb.Timestamp.fromDate(new Date(payload.query.range.end))
        )
        .orderBy("duration.start.timestamp", "asc")
        .limit(limit)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const object = {
              ...doc.data(),
              // overwrite
              duration: {
                start: {
                  timestamp: doc.data().duration.start.timestamp,
                  formattedTimestamp: doc
                    .data()
                    .duration.start.timestamp.toDate()
                    .toLocaleString("de-DE", options),
                },
                end: {
                  timestamp: doc.data().duration.end.timestamp,
                  formattedTimestamp: doc
                    .data()
                    .duration.end.timestamp.toDate()
                    .toLocaleString("de-DE", options),
                  isOpen: doc.data().duration.end.isOpen,
                },
              },
            };
            leaves.push(object);

            return commit(SET_ARCHIVED_LEAVES, leaves);
          });
        })
        .catch((error) => console.log(error));
    },
    [CREATE_LEAVE]({ commit }, payload) {
      commit(SET_LOADING, true);

      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("leaves")
        .doc();
      return docRef
        .set({
          duration: {
            start: {
              timestamp: payload.duration.start.timestamp,
            },
            end: {
              timestamp: payload.duration.end.timestamp,
              isOpen: payload.duration.end.isOpen,
            },
          },
          reason: {
            id: payload.reason.id,
            details: payload.reason.details,
          },
          automation: {
            eventResponses: {
              areAutomated: payload.automation.eventResponses.areAutomated,
              status: payload.automation.eventResponses.status,
              comment: payload.automation.eventResponses.comment,
            },
          },
          user: {
            displayName: payload.user.displayName,
            membershipId: payload.user.membershipId,
            uid: payload.user.uid,
          },
          meta: {
            id: docRef.id,
            createdBy: {
              uid: fb.auth.currentUser.uid,
              membershipId: fb.auth.currentUser.uid,
              displayName: fb.auth.currentUser.displayName,
            },
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        })
        .then(function () {
          return commit(SET_LOADING, false);
        });
    },
    [UPDATE_LEAVE]({ commit }, payload) {
      commit(SET_LOADING, true);
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("leaves")
        .doc(payload.itemId)
        .update({
          duration: {
            start: {
              timestamp: payload.duration.start.timestamp,
            },
            end: {
              timestamp: payload.duration.end.timestamp,
              isOpen: payload.duration.end.isOpen,
            },
          },
          reason: {
            id: payload.reason.id,
            details: payload.reason.details,
          },
          automation: {
            eventResponses: {
              areAutomated: payload.automation.eventResponses.areAutomated,
              status: payload.automation.eventResponses.status,
              comment: payload.automation.eventResponses.comment,
            },
          },
          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": {
            uid: fb.auth.currentUser.uid,
            membershipId: fb.auth.currentUser.uid,
            displayName: fb.auth.currentUser.displayName,
          },
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },

    // REQUEST
    [GET_REQUESTS]({ dispatch, commit }, payload) {
      const requests = [];
      const dataID = [];
      var options = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      var limit = 60;
      var queryDate = new Date();
      queryDate.setDate(queryDate.getDate() - 1);
      if (payload.paginationLimit != undefined) {
        limit = payload.paginationLimit;
      }

      var baseReference = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("requests")
        .where("status", "==", "planned")
        .where(
          "timeSlots.start.timestamp",
          ">=",
          fb.Timestamp.fromDate(new Date(queryDate))
        );
      var reference = baseReference;
      if (payload.query.onlyOwn) {
        //queryIsFiltered is not available
        var ownUserGroup = payload.query.ownUserGroup; //rootState.organization.membership.group.id;
        var ownUid = fb.auth.currentUser.uid;

        reference = baseReference.where(
          "config.attendance.allowedAttendeesIds",
          "array-contains-any",
          ["all", ownUid, ownUserGroup]
        );
      }

      return (
        reference
          // .orderBy("duration.start.timestamp", "asc")
          .limit(limit)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const object = {
                ...doc.data(),

                // Overwrite
                timeSlots: {
                  data: doc.data().timeSlots.data,
                  start: {
                    formattedTimestamp: doc
                      .data()
                      .timeSlots.start.timestamp.toDate()
                      .toLocaleString("de-DE", options),
                    timestamp: doc.data().timeSlots.start.timestamp,
                  },
                  end: {
                    formattedTimestamp: doc
                      .data()
                      .timeSlots.end.timestamp.toDate()
                      .toLocaleString("de-DE", options),
                    timestamp: doc.data().timeSlots.end.timestamp,
                  },
                },
              };
              requests.push(object);

              dataID.push(doc.id);

              commit(SET_REQUESTS, requests);
            });
            console.log(requests);
            return requests;
          })
          .then((result) => {
            if (payload.withStatus == true) {
              return dispatch(GET_REQUESTS_STATUS, {
                organizationId: payload.organizationId,
                requests: result,
              });
            }
          })
          .catch((error) => console.log(error))
      );
    },
    [GET_REQUESTS_STATUS]({ commit }, payload) {
      // var output;
      var ids = payload.requests.map((a) => a.meta.id);
      var userResponses = [];
      ids
        .forEach((item) => {
          fb.organizationCollection
            .doc(payload.organizationId)
            .collection("requests")
            .doc(item)
            .collection("requestResponses")
            .doc(fb.auth.currentUser.uid)
            .get()
            .then((doc) => {
              if (doc.exists) {
                const data = {
                  userResponse: {
                    ...doc.data(),
                    meta: {
                      requestId: item,
                      uid: doc.data().meta.uid,
                    },
                  },
                };
                userResponses.push(data);
              } else {
                const data = {
                  userResponse: {
                    status: {
                      id: "0",
                    },
                    comment: "",
                    type: "standard",
                    meta: {
                      requestId: item,
                      uid: fb.auth.currentUser.uid,
                    },
                  },
                };
                userResponses.push(data);
              }
            });
          commit(SET_REQUESTS_STATUS, userResponses);
        })
        .catch((error) => console.log(error), false);
    },
    [GET_ARCHIVED_REQUESTS]({ commit }, payload) {
      const requests = [];
      var options = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      var limit = 300;
      if (payload.paginationLimit != undefined) {
        limit = payload.paginationLimit;
      }

      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("requests")
        .where("status", "in", payload.query.status)
        // .where("type.id", "in", payload.query.types)
        // .where(
        //   "config.attendance.allowedAttendeesIds",
        //   "array-contains-any",
        //   payload.query.groups // Include events where only specific persons are invited
        // )
        .where(
          "timeSlots.start.timestamp",
          ">=",
          fb.Timestamp.fromDate(new Date(payload.query.range.start))
        )
        .where(
          "timeSlots.start.timestamp",
          "<=",
          fb.Timestamp.fromDate(new Date(payload.query.range.end))
        )
        .orderBy("timeSlots.start.timestamp", "asc")
        .limit(limit)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const object = {
              ...doc.data(),
              // overwrite
              timeSlots: {
                data: doc.data().timeSlots.data,
                start: {
                  formattedTimestamp: doc
                    .data()
                    .timeSlots.start.timestamp.toDate()
                    .toLocaleString("de-DE", options),
                  timestamp: doc.data().timeSlots.start.timestamp,
                },
                end: {
                  formattedTimestamp: doc
                    .data()
                    .timeSlots.end.timestamp.toDate()
                    .toLocaleString("de-DE", options),
                  timestamp: doc.data().timeSlots.end.timestamp,
                },
              },
            };
            requests.push(object);

            return commit(SET_ARCHIVED_REQUESTS, requests);
          });
        })
        .catch((error) => console.log(error));
    },

    [CREATE_REQUEST]({ commit }, payload) {
      commit(SET_LOADING, true);
      var customResponseDeadlineTimestamp = ""; //= payload.timeSlots.start.timestamp;
      if (payload.config.responses.deadline.isCustom) {
        customResponseDeadlineTimestamp =
          payload.config.responses.deadline.timestamp;
      }

      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("requests")
        .doc();
      docRef
        .set({
          title: payload.title,
          description: payload.description,
          type: {
            id: payload.type.id,
            title: payload.type.title,
            shortTitle: payload.type.shortTitle,
            color: payload.type.color,
            description: payload.type.description,
          },
          location: {
            name: payload.location.name,
          },
          timeSlots: {
            start: {
              timestamp: payload.timeSlots.start.timestamp,
            },
            end: {
              timestamp: payload.timeSlots.end.timestamp,
            },
            data: payload.timeSlots.data,
          },

          config: {
            organizer: {
              isAppointed: payload.config.organizer.isAppointed,
              hasAdditionalPermissions:
                payload.config.organizer.hasAdditionalPermissions,
              description: payload.config.organizer.description,
              personIds: payload.config.organizer.personIds,
              personData: payload.config.organizer.personData,
            },
            attendance: {
              isMandatory: payload.config.attendance.isMandatory,
              isLimited: payload.config.attendance.isLimited,
              description: payload.config.attendance.description,
              allowedAttendeesIds:
                payload.config.attendance.allowedAttendeesIds,
              allowedPersons: payload.config.attendance.allowedPersons,
              allowedUserGroups: payload.config.attendance.allowedUserGroups,
              allowedDivisions: payload.config.attendance.allowedDivisions,
            },
            responses: {
              areBinding: payload.config.responses.areBinding,
              areLocked: payload.config.responses.areLocked,
              deadline: {
                isCustom: payload.config.responses.deadline.isCustom,
                timestamp: customResponseDeadlineTimestamp,
              },
            },
          },

          status: "planned", // 'draft', 'planned', 'live', 'finished', 'canceled',
          statusAnalytics: { id: null, reason: null },
          internalNote: null,
          organizationId: payload.organizationId,
          meta: {
            id: docRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        })
        .then(function () {
          router.push({
            name: "scheduling-requests",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },

    [UPDATE_REQUEST]({ commit }, payload) {
      commit(SET_LOADING, true);
      var customResponseDeadlineTimestamp = payload.timeSlots.start.timestamp;
      if (payload.config.responses.deadline.isCustom) {
        customResponseDeadlineTimestamp =
          payload.config.responses.deadline.timestamp;
      }
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("requests")
        .doc(payload.itemId)
        .update({
          title: payload.title,
          description: payload.description,
          "type.id": payload.type.id,
          "type.title": payload.type.title,
          "type.shortTitle": payload.type.shortTitle,
          "type.color": payload.type.color,
          "type.description": payload.type.description,
          "location.name": payload.location.name,

          "timeSlots.data": payload.timeSlots.data,
          "timeSlots.start.timestamp": payload.timeSlots.start.timestamp,
          "timeSlots.end.timestamp": payload.timeSlots.end.timestamp,

          "config.organizer.isAppointed": payload.config.organizer.isAppointed,
          "config.organizer.hasAdditionalPermissions":
            payload.config.organizer.hasAdditionalPermissions,
          "config.organizer.description": payload.config.organizer.description,
          "config.organizer.personIds": payload.config.organizer.personIds,
          "config.organizer.personData": payload.config.organizer.personData,

          "config.attendance.isMandatory":
            payload.config.attendance.isMandatory,
          "config.attendance.isLimited": payload.config.attendance.isLimited,
          "config.attendance.description":
            payload.config.attendance.description,

          "config.attendance.allowedAttendeesIds":
            payload.config.attendance.allowedAttendeesIds,
          "config.attendance.allowedPersons":
            payload.config.attendance.allowedPersons,
          "config.attendance.allowedUserGroups":
            payload.config.attendance.allowedUserGroups,

          "config.responses.areBinding": payload.config.responses.areBinding,
          "config.responses.areLocked": payload.config.responses.areLocked,
          "config.responses.deadline.isCustom":
            payload.config.responses.deadline.isCustom,
          "config.responses.deadline.timestamp":
            customResponseDeadlineTimestamp,

          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .then(function () {
          router.push({
            name: "scheduling-requests",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },

    [UPDATE_REQUEST_RESPONSE]({ commit }, payload) {
      commit(SET_LOADING, true);
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("requests")
        .doc(payload.itemId)
        .collection("requestResponses")
        .doc(payload.uid)
        .set(
          {
            organizationId: payload.organizationId,
            requestId: payload.itemId,
            status: payload.status,
            comment: payload.comment,
            meta: {
              uid: payload.uid,
              lastUpdatedAt: fb.FieldValue.serverTimestamp(),
              lastUpdatedBy: fb.auth.currentUser.uid,
            },
          },
          { merge: true }
        )
        .then(function () {
          // router.push({
          //   name: "scheduling-event-details-tab-general",
          //   params: { organizationId: payload.organizationId, itemId: payload.itemId },
          // });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },

    // REQUEST_TYPE
    [GET_REQUEST_TYPES]({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const data = [];
        fb.organizationCollection
          .doc(payload.organizationId)
          .collection("requestTypes")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              data.push(doc.data());
            });
            data.sort((a, b) => parseFloat(a.sortKey) - parseFloat(b.sortKey));
            commit(SET_REQUEST_TYPES, data);
            resolve(querySnapshot);
          })
          .then(
            (response) => {
              resolve(response);
            },
            (error) => {
              console.log("Error getting document:", error);
              reject(error);
            }
          );
      });
    },
    [CREATE_REQUEST_TYPE]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("requestTypes")
        .doc();
      docRef
        .set({
          title: payload.title,
          shortTitle: payload.shortTitle,
          description: payload.description,
          color: payload.color,
          sortKey: payload.sortKey,
          organizationId: payload.organizationId,
          meta: {
            id: docRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        })
        .then(function () {
          router.push({
            name: "availability-config-tab-request-types",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [CREATE_REQUEST_TYPES]({ commit }, payload) {
      var batch = fb.db.batch();
      const batchRef = fb.db
        .collection("organizations")
        .doc(payload.organizationId)
        .collection("requestTypes");

      payload.requestTypes.forEach(function (requestType) {
        const newDocRef = batchRef.doc();
        const object = {
          ...requestType,
          organizationId: payload.organizationId,
          meta: {
            id: newDocRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        };
        batch.set(newDocRef, object);
      });
      batch
        .commit()
        .then(() => {
          return commit(SET_LOADING, false);
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    [UPDATE_REQUEST_TYPE]({ commit }, payload) {
      commit(SET_LOADING, true);
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("requestTypes")
        .doc(payload.itemId)
        .update({
          title: payload.title,
          shortTitle: payload.shortTitle,
          description: payload.description,
          color: payload.color,
          sortKey: payload.sortKey,
          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .then(function () {
          router.push({
            name: "availability-config-tab-request-types",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [DELETE_REQUEST_TYPE]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("requestTypes")
        .doc(payload.itemId);
      docRef
        .delete()
        .then(function () {
          router.push({
            name: "availability-config-tab-request-types",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },

    // UNIT
    [GET_UNITS]({ commit }, payload) {
      const data = [];
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("units")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            data.push({ ...doc.data() });
          });
          commit(SET_UNITS, data);
        })
        .catch(function (error) {
          console.error("Error getting document:", error);
          return false;
        });
    },
    [CREATE_UNIT]({ commit }, payload) {
      commit(SET_LOADING, true);
      console.log(payload.photoUrl);
      console.log(payload);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("units")
        .doc();
      docRef
        .set({
          title: payload.title,
          priority: payload.priority,
          isExternal: payload.isExternal,
          forecastingEnabled: payload.forecastingEnabled,
          photoUrl: payload.photoUrl,
          crewCombinations: payload.crewCombinations,
          positionIds: payload.positionIds,
          organizationId: payload.organizationId,
          meta: {
            id: docRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        })

        .then(() => {
          return router.push({
            name: "availability-units-tab-units",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [UPDATE_UNIT]({ commit }, payload) {
      commit(SET_LOADING, true);
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("units")
        .doc(payload.itemId)
        .update({
          title: payload.title,
          priority: payload.priority,
          isExternal: payload.isExternal,
          forecastingEnabled: payload.forecastingEnabled,
          crewCombinations: payload.crewCombinations,
          positionIds: payload.positionIds,
          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .then(function () {
          router.push({
            name: "availability-units-tab-units",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [DELETE_UNIT]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("units")
        .doc(payload.itemId);
      docRef
        .delete()
        .then(function () {
          router.push({
            name: "availability-units-tab-units",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    // Funktionen
    [GET_POSITIONS]({ commit }, payload) {
      const data = [];
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("positions")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            data.push({ ...doc.data() });
          });
          commit(SET_POSITIONS, data);
        })
        .catch(function (error) {
          console.error("Error getting document:", error);
          return false;
        });
    },
    [CREATE_POSITION]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("positions")
        .doc();
      docRef
        .set({
          title: payload.title,
          shortTitle: payload.shortTitle,
          priority: payload.priority,
          substitute: payload.substitute,
          color: payload.color,
          preconditions: {
            conjunctionType: payload.preconditions.conjunctionType,
            skillIds: payload.preconditions.skillIds,
          },

          organizationId: payload.organizationId,
          meta: {
            id: docRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        })
        .then(function () {
          router.push({
            name: "admin-config-tab-positions",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [CREATE_POSITIONS]({ commit }, payload) {
      var batch = fb.db.batch();
      const batchRef = fb.db
        .collection("organizations")
        .doc(payload.organizationId)
        .collection("positions");

      payload.positions.forEach(function (position) {
        const newDocRef = batchRef.doc();
        const object = {
          ...position,
          organizationId: payload.organizationId,
          meta: {
            id: newDocRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        };
        batch.set(newDocRef, object);
      });
      batch
        .commit()
        .then(() => {
          return commit(SET_LOADING, false);
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    [UPDATE_POSITION]({ commit }, payload) {
      commit(SET_LOADING, true);
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("positions")
        .doc(payload.itemId)
        .update({
          title: payload.title,
          shortTitle: payload.shortTitle,
          priority: payload.priority,
          substitute: payload.substitute,
          color: payload.color,
          "preconditions.conjunctionType":
            payload.preconditions.conjunctionType,
          "preconditions.skillIds": payload.preconditions.skillIds,

          organizationId: payload.organizationId,
          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .then(function () {
          router.push({
            name: "availability-units-tab-positions",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [DELETE_POSITION]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("positions")
        .doc(payload.itemId);
      docRef
        .delete()
        .then(function () {
          router.push({
            name: "availability-units-tab-positions",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },

    [UPDATE_AVAILABILITY_CONFIG]({ commit }, payload) {
      commit(SET_LOADING, true);
      let updateData = {};
      if (payload.type === "onlyLeaveConfig") {
        updateData = {
          "config.availability.leaves.notifyPersons":
            payload.leaves.notifyPersons,
          "config.availability.leaves.notifyUserGroups":
            payload.leaves.notifyUserGroups,
        };
      } else {
        updateData = {
          "config.availability.defaultDeadlineInMinutes":
            payload.defaultDeadlineInMinutes,
          "config.availability.showAllRequestsInList":
            payload.showAllRequestsInList,
        };
      }
      fb.organizationCollection
        .doc(payload.organizationId)
        .update({
          ...updateData,
          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
  },

  getters: {},
};
