var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.activeAccounts,"item-key":"user.uid","multi-sort":"","sort-by":"user.lastName","max-height":"800px auto"},on:{"click:row":_vm.viewAccount},scopedSlots:_vm._u([{key:`item.profil`,fn:function({ item }){return [_c('v-badge',{attrs:{"bordered":"","overlap":"","dot":"","bottom":"","color":_vm.avatarColorFromStatus(item.access.status)}},[_c('v-avatar',{staticClass:"font-weight-medium",attrs:{"size":"34","rounded":"","color":_vm.$vuetify.theme.dark
              ? 'rgba(255, 255, 255, 0.12)'
              : 'grey lighten-2'}},[_vm._v(_vm._s(_vm.initials(item.user.displayName)))])],1)]}},{key:`item.access.status`,fn:function({ item }){return [_c('v-chip',{class:item.access.status === 'activated'
            ? 'font-weight-medium green--text text--darken-4'
            : 'font-weight-medium red--text text--darken-4',attrs:{"color":item.access.status === 'activated'
            ? 'green lighten-4'
            : 'red lighten-4',"small":"","label":""}},[_vm._v(_vm._s(_vm.textFromUserStatus(item.access.status)))])]}},{key:`item.actions`,fn:function({ item }){return [_c('v-icon',{attrs:{"color":"red darken-1"},on:{"click":function($event){return _vm.viewAccount(item)}}},[_vm._v(" mdi-chevron-right ")])]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }