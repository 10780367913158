import {
  DUTY_MANAGEMENT_REPORTS_read,
  DUTY_MANAGEMENT_REPORTS_create,
  DUTY_MANAGEMENT_ATTENDANCE_RECORDS_read,
  DUTY_MANAGEMENT_ARCHIVE_read,
} from "@/data/permission-types.js";

export const dutyManagement = {
  path: "duty-management",
  name: "duty-management",
  component: () =>
    import(
      /* webpackChunkName: "duty-management" */ "../../views/duty-management/DutyManagementIndex.vue"
    ),
  meta: {
    requiresAuth: true,
    requiresPermission: true,
  },
  children: [
    // duty-management > reports
    {
      path: "reports",
      name: "duty-management-reports",
      component: () =>
        import(
          /* webpackChunkName: "duty-management-reports" */ "../../views/duty-management/DutyManagementReports.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${DUTY_MANAGEMENT_REPORTS_read}`,
      },
    },
    {
      path: "reports/new",
      name: "duty-management-reports-new",
      component: () =>
        import(
          /* webpackChunkName: "duty-management-reports-manage" */ "../../views/duty-management/reports/ReportNew.vue"
        ),
      meta: {
        layout: "content-without-drawer",
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${DUTY_MANAGEMENT_REPORTS_create}`,
      },
    },
    {
      path: "reports/live/:itemId",
      name: "duty-management-reports-live",
      component: () =>
        import(
          /* webpackChunkName: "duty-management-reports-manage" */ "../../views/duty-management/reports/ReportLive.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "",
      },
    },
    {
      path: "reports/:itemId",
      name: "duty-management-reports-details",
      component: () =>
        import(
          /* webpackChunkName: "duty-management-reports-manage" */ "../../views/duty-management/reports/ReportDetails.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${DUTY_MANAGEMENT_REPORTS_read}`,
      },
    },
    // duty-management > attendance records
    {
      path: "attendance-records",
      name: "duty-management-attendance-records",
      component: () =>
        import(
          /* webpackChunkName: "duty-management-attendance-records" */ "../../views/duty-management/DutyManagementAttendanceRecords.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${DUTY_MANAGEMENT_ATTENDANCE_RECORDS_read}`,
      },
    },
    // duty-management > archive
    {
      path: "archive",
      name: "duty-management-archive",
      component: () =>
        import(
          /* webpackChunkName: "duty-management-archive" */ "../../views/duty-management/DutyManagementArchive.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${DUTY_MANAGEMENT_ARCHIVE_read}`,
      },
    },
    // duty-management > config
    {
      path: "config",
      name: "duty-management-config",
      component: () =>
        import(
          /* webpackChunkName: "duty-management-config" */ "../../views/duty-management/DutyManagementConfig.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "",
      },
      children: [
        {
          path: "duty-types",
          name: "duty-management-config-tab-duty-types",
          component: () =>
            import(
              /* webpackChunkName: "duty-management-config" */ "../../views/duty-management/config/ConfigTabDutyTypes.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            // permissionID: `${duty-management_CONFIG_DUTY_TYPES_read}`,
          },
        },
        {
          path: "approval-process",
          name: "duty-management-config-tab-approval-process",
          component: () =>
            import(
              /* webpackChunkName: "duty-management-config" */ "../../views/duty-management/config/ConfigTabApprovalProcess.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            // permissionID: `${duty-management_CONFIG_DUTY_TYPES_read}`,
          },
        },
      ],
    },
    {
      path: "config/duty-types/new",
      name: "duty-management-config-tab-duty-types-duty-type-new",
      component: () =>
        import(
          /* webpackChunkName: "duty-management-config" */ "../../views/duty-management/config/duty-types/DutyTypeNew.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        // permissionID: `${X}`,
      },
    },
    {
      path: "config/duty-types/:itemId",
      name: "duty-management-config-tab-duty-types-duty-type-details",
      component: () =>
        import(
          /* webpackChunkName: "duty-management-config" */ "../../views/duty-management/config/duty-types/DutyTypeDetails.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        // permissionID: `${X}`,
      },
    },
    {
      path: "config/duty-types/:itemId/edit",
      name: "duty-management-config-tab-duty-types-duty-type-edit",
      component: () =>
        import(
          /* webpackChunkName: "duty-management-config" */ "../../views/duty-management/config/duty-types/DutyTypeEdit.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        // permissionID: `${X}`,
      },
    },
  ],
};
