export const importableCourseGroups = [
  {
    color: "#E91E63FF",
    icon: "fire",
    sortKey: 1,
    title: "Feuerwehrtechnische Lehrgänge",
    isImported: true,
    importVersion: 1,
    importId: "fire",
  },
  {
    color: "#F44336FF",
    icon: "medical-bag",
    sortKey: 4,
    title: "Medizinische Lehrgänge",
    isImported: true,
    importVersion: 1,
    importId: "medic",
  },
  {
    color: "#009688FF",
    icon: "school",
    sortKey: 7,
    title: "Ausbilder-/Trainerlehrgänge",
    isImported: true,
    importVersion: 1,
    importId: "train",
  },
  {
    color: "#607D8BFF",
    icon: "dots-horizontal-circle",
    sortKey: 7,
    title: "Sonstige Lehrgänge",
    isImported: true,
    importVersion: 1,
    importId: "other",
  },
];
