<template>
  <div>
    <NavigationBar
      titel="Details"
      :actions="actions"
      showBackBtn
    ></NavigationBar>
    <section v-if="helpers.dataIsLoading">
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-skeleton-loader type="sentences"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section v-else>
      <template>
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="7" sm="12">
              <v-card>
                <v-card-title class="subheading">
                  <span>{{ payslipData.user.displayName }}</span>
                  <v-spacer></v-spacer>
                  <payslip-status-chip
                    :status="payslipData.status"
                  ></payslip-status-chip>
                </v-card-title>
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" sm="6" md="6">
                      <v-card outlined>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              timestampConverter(
                                payslipData.period.start.timestamp
                              )
                            }}</v-list-item-title>
                            <v-list-item-subtitle
                              >Beginn Abrechnungsperiode</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-card outlined>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              timestampConverter(
                                payslipData.period.end.timestamp
                              )
                            }}</v-list-item-title>
                            <v-list-item-subtitle
                              >Ende Abrechnungsperiode</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-card outlined>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              payslipData.batch.title
                            }}</v-list-item-title>
                            <v-list-item-subtitle
                              >Abrechnungsstapel</v-list-item-subtitle
                            >
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn
                              icon
                              small
                              color="primary"
                              :to="{
                                name: 'accounting-payroll-details',
                                params: {
                                  itemId: payslipData.batch.id,
                                },
                              }"
                            >
                              <v-icon small>mdi-open-in-new</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-card outlined>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              getPayrollTypeText(payslipData.type)
                            }}</v-list-item-title>
                            <v-list-item-subtitle
                              >Abrechnungsvariante</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <v-card class="mt-6">
                <v-data-table
                  :headers="summaryHeaders"
                  :items="payslipData.lineItems"
                  disable-pagination
                  hide-default-footer
                  dense
                  mobile-breakpoint="0"
                  no-data-text="Keine Übersichtsdaten in Abrechnungszettel vorhanden"
                >
                  <template v-slot:body.append>
                    <tr class="font-weight-medium v-row-group__header">
                      <td class="text-left">Gesamt</td>
                      <td class="text-right font-weight-medium">
                        {{ payslipData.summary.totalBillableTime.hours }}
                      </td>
                      <td class="text-right font-weight-medium">
                        <pre
                          >{{ payslipData.summary.totalCompensation.amount }} {{
                            payslipData.summary.totalCompensation.currency
                          }}</pre
                        >
                      </td>
                    </tr>
                  </template>
                  <template v-slot:item.title="{ item }">
                    <div class="d-flex flex-column py-1">
                      <span>
                        <span>{{ item.title }}</span>
                        <div style="line-height: 1.4" class="mt-n1">
                          <small class="grey--text">
                            <span v-if="item.description"
                              >{{ item.description }}, </span
                            ><span>{{ item.inputs.data.length }} Einträge</span>
                          </small>
                        </div>
                      </span>
                    </div>
                  </template>
                  <template
                    v-slot:item.summary.totalCompensation.amount="{ item }"
                  >
                    <pre
                      >{{ item.summary.totalCompensation.amount }} {{
                        item.summary.totalCompensation.currency
                      }}</pre
                    >
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
            <v-col cols="12" md="5" sm="12">
              <v-card>
                <v-card-text>
                  <!-- <div class="d-flex justify-space-between align-center"> -->
                  <div>
                    <!-- <span class="">Geleistete Zeit</span> -->
                    <!-- <h4 class="text-h4 mb-2">{{  }}</h4> -->

                    <!-- <VueApexCharts
                        type="donut"
                        height="150"
                        width="150"
                        :options="timeSpendingChartConfig"
                        :series="timeSpendingChartSeries"
                      /> -->
                    <VueApexCharts
                      type="bar"
                      height="350"
                      :options="barChartOptions"
                      :series="barChartSeries"
                    />
                    <!-- </div> -->
                  </div>
                </v-card-text>

                <!-- <v-divider></v-divider>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead class="v-row-group__header">
                      <tr>
                        <th class="text-left">Abrechnungsart</th>
                        <th class="text-right">Anzahl in H</th>
                        <th class="text-right">Summe in EUR</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="payrollType in payrollTypes"
                        :key="payrollType.meta.id"
                      >
                        <td>{{ payrollType.title }}</td>
                        <td class="text-right">20</td>
                        <td class="text-right">
                          <pre>120,00 €</pre>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot
                      class="font-weight-medium v-row-group__header rounded-b-lg"
                    >
                      <tr>
                        <td class="text-left">Gesamt</td>
                        <td class="text-right">160</td>
                        <td class="text-right">
                          <pre>{{ getTotal("totalCompensation", items) }} €</pre>
                          <strong>
                            <pre>960,00 €</pre>
                          </strong>
                        </td>
                      </tr>
                    </tfoot></template
                  >
                </v-simple-table> -->
              </v-card>
            </v-col>
            <v-col
              cols="12"
              v-for="(lineItem, index) in payslipData.lineItems"
              :key="index"
            >
              <payslips-line-items-data-table
                :lineItem="lineItem"
              ></payslips-line-items-data-table>
            </v-col>

            <!-- <v-data-table
                :headers="headersCourses"
                :items="payslipData.inputs.courses"
                class="elevation-2"
                show-expand
                item-key="meta.id"
                :expanded.sync="expanded.courses"
                dense
                no-data-text="Keine Lehrgangsmeldungen vorhanden"
              >
                <template v-slot:top>
                  <v-card flat dense>
                    <v-card-title class="body-2 v-card__subtitle"
                      ><v-icon left small class="mr-2"
                        >mdi-book-education</v-icon
                      >Lehrgangsmeldungen
                      <v-spacer></v-spacer>
                      <v-btn
                        depressed
                        small
                        class="mr-2"
                        :disabled="
                          payslipData.inputs.courses.length ===
                          expanded.courses.length
                        "
                        ><v-icon left>mdi-expand-all</v-icon>Alle
                        ausklappen</v-btn
                      >
                      <v-btn
                        depressed
                        small
                        @click="expanded.courses = []"
                        :disabled="!expanded.courses.length"
                        ><v-icon left>mdi-collapse-all</v-icon>Alle
                        einklappen</v-btn
                      >
                    </v-card-title>
                  </v-card>
                </template>
                <template v-slot:item.title="{ item }">
                  <div class="d-flex flex-column">
                    <span class="d-block font-weight-semibold text-truncate"
                      >{{
                        item.additionalData.course.title
                          ? item.additionalData.course.title
                          : item.additionalData.course.externalName
                      }}

                      <v-tooltip bottom v-if="item.meta.id">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            x-small
                            text
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                            :to="{
                              name: 'accounting-time-sheets-details',
                              params: {
                                organizationId: $route.params.organizationId,
                                itemId: item.meta.id,
                              },
                            }"
                            ><v-icon x-small>mdi-open-in-app</v-icon></v-btn
                          >
                        </template>
                        <span>Lehrgangsmeldung öffnen</span>
                      </v-tooltip>
                    </span>
                  </div>
                </template>
                <template v-slot:item.totalSalary.amount="{ item }">
                  <pre
                    >{{ item.totalSalary.amount }} {{
                      item.totalSalary.currency
                    }}</pre
                  >
                </template>
                <template
                  v-slot:[`item.billableDuration.start.timestamp`]="{ item }"
                >
                  {{
                    timestampConverter(item.billableDuration.start.timestamp)
                  }}
                </template>
                <template
                  v-slot:[`item.billableDuration.end.timestamp`]="{ item }"
                >
                  {{ timestampConverter(item.billableDuration.end.timestamp) }}
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="mx-0 px-0">
                    <v-card-text>
                      <v-row dense>
                        <v-col cols="12" sm="6" md="6">
                          <v-card outlined>
                            <v-list-item dense>
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{
                                    item.description ? item.description : "N/A"
                                  }}
                                </v-list-item-title>
                                <v-list-item-subtitle
                                  class="font-weight-regular"
                                  >Bemerkungen</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <v-card outlined>
                            <v-list-item dense>
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  timestampConverter(
                                    item.workedDuration.start.timestamp
                                  )
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                  class="font-weight-regular"
                                  >Startzeitpunkt
                                  (Tatsächlich)</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <v-card outlined>
                            <v-list-item dense>
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  timestampConverter(
                                    item.workedDuration.end.timestamp
                                  )
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                  class="font-weight-regular"
                                  >Endzeitpunkt
                                  (Tatsächlich)</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </td>
                </template>
              </v-data-table> -->

            <!-- <v-data-table
                :headers="headersExpenses"
                :items="payslipData.inputs.expenses"
                class="elevation-2"
                show-expand
                item-key="meta.id"
                :expanded.sync="expanded.expenses"
                dense
                no-data-text="Keine Auslagen vorhanden"
              >
                <template v-slot:top>
                  <v-card flat dense>
                    <v-card-title class="body-2 v-card__subtitle"
                      ><v-icon left small class="mr-2">mdi-receipt-text</v-icon
                      >Auslagen
                      <v-spacer></v-spacer>
                      <v-btn
                        depressed
                        small
                        class="mr-2"
                        :disabled="
                          payslipData.inputs.expenses.length ===
                          expanded.expenses.length
                        "
                        ><v-icon left>mdi-expand-all</v-icon>Alle
                        ausklappen</v-btn
                      >
                      <v-btn
                        depressed
                        small
                        @click="expanded.expenses = []"
                        :disabled="!expanded.expenses.length"
                        ><v-icon left>mdi-collapse-all</v-icon>Alle
                        einklappen</v-btn
                      >
                    </v-card-title>
                  </v-card>
                </template>
              </v-data-table> -->

            <!-- <v-col cols="12">
              <v-data-table
                :headers="headersOther"
                :items="payslipData.inputs.otherInputs"
                class="elevation-2"
                show-expand
                item-key="meta.id"
                :expanded.sync="expanded.other"
                dense
                no-data-text="Keine sonstigen Einträge vorhanden"
              >
                <template v-slot:top>
                  <v-card flat dense>
                    <v-card-title class="body-2 v-card__subtitle"
                      ><v-icon left small class="mr-2"
                        >mdi-list-box-outline</v-icon
                      >Sonstige Einträge
                      <v-spacer></v-spacer>
                      <v-btn
                        depressed
                        small
                        class="mr-2"
                        :disabled="
                          payslipData.inputs.otherInputs.length ===
                          expanded.otherInputs.length
                        "
                        ><v-icon left>mdi-expand-all</v-icon>Alle
                        ausklappen</v-btn
                      >
                      <v-btn
                        depressed
                        small
                        @click="expanded.otherInputs = []"
                        :disabled="!expanded.otherInputs.length"
                        ><v-icon left>mdi-collapse-all</v-icon>Alle
                        einklappen</v-btn
                      >
                    </v-card-title>
                  </v-card>
                </template>
              </v-data-table>
            </v-col> -->
          </v-row>
          <v-row>
            <v-col cols="12">
              <Subheader icon="mdi-history" title="Logs"> </Subheader>
              <div class="">
                <v-timeline dense align-top>
                  <v-timeline-item
                    fill-dot
                    class="mb-2"
                    color="white"
                    icon-color="grey"
                    icon="mdi-pencil"
                    small
                  >
                    <v-card>
                      <v-card-text>
                        <v-textarea
                          v-model="helpers.commentForm.value"
                          dense
                          label="Kommentar hinzufügen"
                          hide-details="auto"
                          outlined
                          no-resize
                          auto-grow
                          rows="3"
                        >
                        </v-textarea>
                        <v-alert
                          v-if="helpers.commentForm.errorMessage"
                          dense
                          type="error"
                          text
                          class="mt-4 mb-0"
                        >
                          {{ helpers.commentForm.errorMessage }}</v-alert
                        >
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <!-- <v-checkbox
                          dense
                          disabled
                          label="Benachrichtigung per E-Mail senden"
                          persistent-hint
                          hint="Eine Benachrichtigung über diesen Eintrag wird per E-Mail an ausgewählte Personen gesendet."
                        ></v-checkbox> -->

                        <v-spacer></v-spacer
                        ><v-btn
                          class="mx-0"
                          color="success"
                          depressed
                          :loading="helpers.commentForm.isLoading"
                          :disabled="!helpers.commentForm.value"
                          @click="addComment"
                        >
                          Hinzufügen
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-timeline-item>

                  <v-slide-x-transition group>
                    <div
                      v-for="entry in logsAndComments"
                      :key="entry.id"
                      class="mb-2"
                    >
                      <v-timeline-item
                        color="white"
                        fill-dot
                        small
                        :icon-color="logEntryActionColor(entry.type)"
                        :icon="logEntryActionIcon(entry.type)"
                      >
                        <v-card flat class="elevation-2">
                          <v-card-text>
                            <v-row justify="space-between">
                              <v-col cols="7">
                                <strong>{{ entry.user.displayName }}</strong>
                                {{ logEntryActionText(entry.type) }}
                                <span v-if="entry.type === 'comment'">
                                  <br />{{ entry.text }}
                                </span>
                                <span v-if="entry.reason">
                                  <br />Grund: {{ entry.reason }}
                                </span></v-col
                              >
                              <v-col class="text-right" cols="5">{{
                                timestampConverter(entry.timestamp)
                              }}</v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-timeline-item>
                    </div>
                  </v-slide-x-transition>
                  <v-divider></v-divider>
                </v-timeline>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import { db, auth, FieldValue } from "@/firebase";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import VueApexCharts from "vue-apexcharts";
import PayslipsLineItemsDataTable from "@/components/accounting/payslips/PayslipsLineItemsDataTable.vue";
import { ACCOUNTING } from "@/store/modules.js";
import { GET_PAYROLL_TYPES } from "@/store/action-types.js";
// import {
//   SCHEDULING_CONFIG_EVENT_TYPES_create,
//   SCHEDULING_CONFIG_EVENT_TYPES_delete,
// } from "@/data/permission-types.js";

export default {
  name: "accounting-payroll-details",
  components: {
    NavigationBar,
    Subheader,
    PayslipsLineItemsDataTable,
    SupportTools,
    VueApexCharts,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
      expanded: { timeSheets: [], courses: [], expenses: [], otherInputs: [] },
      summaryHeaders: [
        { text: "Abrechnungsgruppe", value: "title" },
        {
          text: "Geleistete Zeit in H",
          value: "summary.totalBillableTime.hours",
          align: "end",
        },
        {
          text: "Summe in EUR",
          value: "summary.totalCompensation.amount",
          align: "end",
        },
      ],

      helpers: {
        dataIsLoading: true,
        approveReportDialog: {
          model: false,
          errorMessage: "",
          isLoading: false,
          reasonValue: "",
        },
        rejectReportDialog: {
          model: false,
          errorMessage: "",
          isLoading: false,
          reasonValue: "",
        },
        moveBackToReviewDialog: {
          model: false,
          errorMessage: "",
          isLoading: false,
          reasonValue: "",
        },
        snackbar: {
          message: "",
          isVisible: false,
        },
        commentForm: {
          isLoading: false,
          value: "",
          errorMessage: "",
        },
        payslipsTable: {
          expandedRows: [],
        },
      },
      actions: [
        {
          title: "Exportieren",
          //   permission: `${SCHEDULING_CONFIG_EVENT_TYPES_create}`,
          icon: "mdi-file-export",
          actionStyle: "textBtn",
          disabled: true,
          function: this.editItem,
        },
        { divider: true },
        { actionStyle: "clipboardBtn" },
      ],

      timeSpendingChartConfig: {
        chart: {
          height: 157,
          width: 130,
          parentHeightOffset: 0,
          type: "donut",
        },
        labels: ["20h", "20h", "20h", "20h", "20h", "20h", "20h", "20h"],
        // colors: [
        //   //   donutChartColors.donut.series1,
        //   //   donutChartColors.donut.series2,
        //   //   donutChartColors.donut.series3,
        //   //   donutChartColors.donut.series4,
        //   //   donutChartColors.donut.series5,
        //   //   donutChartColors.donut.series6,
        //   "#22A95E",
        //   "#24B364",
        //   "#56CA00",
        //   "#53D28C",
        //   "#7EDDA9",
        //   "#A9E9C5",
        // ],
        stroke: { width: 0 },
        dataLabels: {
          enabled: false,
          formatter(val) {
            return `${Number.parseInt(val)}%`;
          },
        },
        legend: { show: false },
        tooltip: { theme: false },
        grid: { padding: { top: 0 } },
        plotOptions: {
          pie: {
            donut: {
              size: "75%",
              labels: {
                show: true,
                value: {
                  fontSize: "1.125rem",
                  color:
                    "rgba(var(--v-theme-on-background), var(--v-high-emphasis-opacity))",
                  fontWeight: 500,
                  offsetY: -15,
                  formatter(val) {
                    return `${Number.parseInt(val)}%`;
                  },
                },
                name: { offsetY: 20 },
                total: {
                  show: true,
                  fontSize: ".7rem",
                  label: "Summe",
                  color:
                    "rgba(var(--v-theme-on-background), var(--v-disabled-opacity))",
                  formatter() {
                    return "160h";
                  },
                },
              },
            },
          },
        },
      },

      timeSpendingChartSeries: [12.5, 12.5, 12.5, 12.5, 12.5, 12.5, 12.5, 12.5],

      sortDesc: true,
      sortBy: "title",

      payslipData: {
        user: {
          displayName: null,
          uid: null,
        },
        // title: null,
        // persons: [],
        // period: {
        //   start: {
        //     timestamp: null,
        //   },
        //   end: {
        //     timestamp: null,
        //   },
        // },
      },
      logs: [],
      comments: [],
    };
  },
  computed: {
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [
        { title: "payslip", data: this.payslipData },
        { title: "payrollTypes", data: this.payrollTypes },
        { title: "logsAndComments", data: this.logsAndComments },
      ];
    },
    payrollTypes() {
      return this.$store.state.accounting.payrollTypes;
    },
    logsAndComments() {
      return this.logs
        .concat(this.comments)
        .sort((a, b) => b.timestamp - a.timestamp);
    },
    barChartOptions() {
      return {
        chart: {
          type: "bar",
        },
        xaxis: {
          categories: this.payslipData.lineItems.map((item) => item.title),
        },
        title: {
          text: "Total Billable Time by Line Item",
        },
      };
    },
    barChartSeries() {
      return [
        {
          name: "Total Billable Time (hours)",
          data: this.payslipData.lineItems.map(
            (item) => item.summary.totalBillableTime.hours
          ),
        },
      ];
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    async initialize() {
      await this.getPayslip();
      await this.getPayrollTypes();
      await this.getLogs();
      await this.getComments();
      this.helpers.dataIsLoading = false;
    },
    async getPayslip() {
      try {
        const doc = await db
          .collection("organizations")
          .doc(this.$route.params.organizationId)
          .collection("payslips")
          .doc(this.$route.params.itemId)
          .get();
        if (doc.exists) {
          this.payslipData = doc.data();
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.log("Error getting document:", error);
        return false;
      }
    },
    async getPayrollTypes() {
      try {
        await this.$store.dispatch(`${ACCOUNTING}${GET_PAYROLL_TYPES}`, {
          organizationId: this.$route.params.organizationId,
        });
      } catch (error) {
        console.log("Error getting payroll types:", error);
      }
    },
    async getLogs() {
      try {
        const querySnapshot = await db
          .collection("organizations")
          .doc(this.$route.params.organizationId)
          .collection("payslips")
          .doc(this.$route.params.itemId)
          .collection("logs")
          .get();

        const logs = [];
        querySnapshot.forEach((doc) => {
          logs.push(doc.data());
        });
        this.logs = logs;
      } catch (error) {
        console.log("Error getting document:", error);
        return false;
      }
    },
    async getComments() {
      try {
        const querySnapshot = await db
          .collection("organizations")
          .doc(this.$route.params.organizationId)
          .collection("payslips")
          .doc(this.$route.params.itemId)
          .collection("comments")
          .get();

        const comments = [];
        querySnapshot.forEach((doc) => {
          comments.push(doc.data());
        });
        this.comments = comments;
      } catch (error) {
        console.log("Error getting document:", error);
        return false;
      }
    },
    getPayrollTypeText(type) {
      switch (type) {
        case "regular":
          return "Regulärer Turnus";
        case "special":
          return "Sonderzahlung";
        default:
          return "Unbekannt";
      }
    },
    addComment() {
      if (this.helpers.commentForm.value != "") {
        this.helpers.commentForm.isLoading = true;
        const commentRef = db
          .collection("organizations")
          .doc(this.$route.params.organizationId)
          .collection("dutyReports")
          .doc(this.$route.params.itemId)
          .collection("comments")
          .doc();

        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

        return commentRef
          .set({
            user: {
              displayName: auth.currentUser.displayName,
              uid: auth.currentUser.uid,
            },
            type: "comment",
            text: this.helpers.commentForm.value,
            mentions: [],
            timestamp: FieldValue.serverTimestamp(),
            id: commentRef.id,
          })
          .then(async () => {
            this.getComments();
            await delay(800);
            this.helpers.commentForm.value = "";
            this.helpers.commentForm.isLoading = false;
          })
          .catch(async (error) => {
            console.error("Error adding document: ", error);
            await delay(800);
            this.helpers.commentForm.errorMessage = error.message;
            this.helpers.commentForm.isLoading = false;
          });
      } else {
        this.helpers.commentForm.errorMessage =
          "Fehler: Es sind nicht alle Pflichtangaben ausgefüllt.";
      }
    },
    getTotal(value, array) {
      let total = array
        .reduce((acc, item) => acc + parseFloat(item[value]), 0)
        .toFixed(2);

      // return total but use a comma as decimal separator
      return total.replace(".", ",");
    },
    getTotalValue(field, items) {
      return items.reduce((sum, item) => sum + (item[field] || 0), 0);
    },
    getTotalDurationInHours(items) {
      return (
        items.reduce((sum, item) => sum + (item.durationInMin || 0), 0) / 60
      );
    },
    combineExpenses(payslips) {
      return payslips.reduce((allExpenses, payslip) => {
        return allExpenses.concat(payslip.expenses || []);
      }, []);
    },
    combineTimeSheets(payslips, type) {
      return payslips.reduce((allTimeSheets, payslip) => {
        if (payslip.timeSheets && Array.isArray(payslip.timeSheets)) {
          return allTimeSheets.concat(
            payslip.timeSheets.filter((ts) => ts.type === type)
          );
        }
        return allTimeSheets;
      }, []);
    },
    combineOtherInputs(payslips) {
      return payslips.reduce((allOtherInputs, payslip) => {
        return allOtherInputs.concat(payslip.otherInputs || []);
      }, []);
    },
    logEntryActionText(type) {
      switch (type) {
        case "created":
          return "hat den Abrechnungsstapel erstellt.";
        case "approved":
          return "hat den Abrechnungsstapel genehmigt.";
        case "toBeReviewed":
          return "hat den Abrechnungsstapel zur Prüfung übergeben.";
        case "rejected":
          return "hat den Abrechnungsstapel abgelehnt.";
        case "comment":
          return "hat einen Kommentar hinzugefügt:";
        case "reassigned":
          return "hat den Abrechnungsstapel neu zugewiesen.";
        case "completed":
          return "hat den Abrechnungsstapel geschlossen.";
        default:
          break;
      }
    },
    logEntryActionColor(type) {
      switch (type) {
        case "created":
          return "primary";
        case "approved":
        case "completed":
          return "green";
        case "toBeReviewed":
          return "purple";
        case "rejected":
          return "red";
        case "reassigned":
          return "primary";
        case "comment":
          return "grey";
        default:
          break;
      }
    },
    logEntryActionIcon(type) {
      switch (type) {
        case "created":
          return "mdi-plus";
        case "approved":
          return "mdi-check";
        case "toBeReviewed":
          return "mdi-check-all";
        case "rejected":
          return "mdi-close";
        case "reassigned":
          return "mdi-account-switch";
        case "comment":
          return "mdi-comment-text";
        case "completed":
          return "mdi-archive-check";
        default:
          break;
      }
    },
    timestampConverter(timestamp) {
      const options = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
  },
};
</script>

<style></style>
