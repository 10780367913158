<template>
  <div>
    <section>
      <TippCard
        hintID="Mitgliederverwaltung_Lehrgänge_1_V1"
        text="Lehrgänge gehören einer Lehrgangsgruppe an und können den Personaldaten von Mitgliedern zugewiesen werden."
      >
      </TippCard>
    </section>
    <v-snackbar v-model="helpers.snackbar.model" timeout="5000"
      >{{ helpers.snackbar.text }}.</v-snackbar
    >
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-folder-multiple"
                title="Lehrgangsgruppen"
                :actions="courseGroupActions"
              ></Subheader>
              <div class="mt-7">
                <v-data-table
                  :headers="courseGroupHeaders"
                  :items="courseGroups"
                  sort-by="sortKey"
                  :sort-desc="false"
                  class="elevation-2"
                  no-data-text="Keine Lehrgangsgruppen vorhanden"
                  @click:row="openCourseGroup"
                >
                  <template v-slot:[`item.icon`]="{ item }">
                    <data-avatar
                      :icon="item.icon"
                      :color="item.color"
                      small
                    ></data-avatar>
                  </template>
                </v-data-table>
              </div>
            </v-col>
            <v-col cols="12">
              <Subheader
                icon="mdi-book-education"
                title="Lehrgänge"
                :actions="coursesActions"
              ></Subheader>
              <div class="mt-7">
                <v-data-table
                  :headers="courseHeaders"
                  :items="courses"
                  group-by="groupByValue"
                  sort-by="sortKey"
                  :sort-desc="false"
                  class="elevation-2"
                  no-data-text="Keine Lehrgänge vorhanden"
                  @click:row="openCourse"
                >
                  <template v-slot:[`item.type`]="{ item }">
                    <data-chip
                      :color="colorFromType(item.type)"
                      :text="textFromType(item.type)"
                      :iconLeft="iconFromType(item.type)"
                      small
                    >
                    </data-chip>
                  </template>
                  <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
                    <th :colspan="courseHeaders.length" class="group-header-th">
                      <v-icon @click="toggle" class="mr-2"
                        >{{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
                      </v-icon>
                      <span>
                        {{ stripPrefix(items[0].groupByValue) }}
                      </span>
                    </th>
                  </template>
                </v-data-table>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section>
      <custom-dialog
        v-if="helpers.dataImportCourseGroups.dialogModel"
        @close="toggleCourseGroupsImportDialog()"
        title="Lehrgangsgruppen importieren"
        text="Importierte Lehrgangsgruppen stehen nach dem Import sofort zur Verfügung."
        width="m"
        :errorMessage="helpers.dataImportCourseGroups.errorMessage"
        :btnAction="importCourseGroups"
        :btnDisabled="
          helpers.dataImportCourseGroups.selectedImportTemplates.length === 0
        "
        :loading="helpers.dataImportCourseGroups.loading"
        btnText="Auswahl importieren"
      >
        <template v-slot:content>
          <v-form
            v-model="helpers.dataImportCourseGroups.formIsValid"
            @submit.prevent="importCourseGroups()"
          >
            <v-card outlined class="mt-2">
              <v-data-table
                v-model="helpers.dataImportCourseGroups.selectedImportTemplates"
                :items="helpers.dataImportCourseGroups.importTemplates"
                :headers="helpers.dataImportCourseGroups.tableHeaders"
                :search="helpers.dataImportCourseGroups.search"
                item-key="title"
                show-select
                :sort-by="helpers.dataImportCourseGroups.sortSelector"
                :sort-desc="false"
                checkbox-color="primary"
                disable-pagination
                hide-default-footer
                mobile-breakpoint="0"
                fixed-header
                height="35vh"
                class="mt-1"
                no-results-text="Keine Ergebnisse für Suchbegriff"
              >
                <template v-slot:top>
                  <v-card flat>
                    <v-card-subtitle>
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model="helpers.dataImportCourseGroups.search"
                            label="Nach Lehrgangsgruppen suchen"
                            prepend-inner-icon="mdi-magnify"
                            dense
                            hide-details="auto"
                            outlined
                            clearable
                          ></v-text-field>
                        </v-col>
                        <v-col>
                          <span class="mr-4">Sortieren nach</span>
                          <v-chip-group
                            v-model="
                              helpers.dataImportCourseGroups.sortSelector
                            "
                            mandatory
                            active-class="primary--text"
                          >
                            <v-chip
                              label
                              value="sortKey"
                              class="transparent-background font-weight-medium mr-2 mb-3"
                              ><v-icon left small
                                >mdi-sort-numeric-variant</v-icon
                              >Sortierschlüssel
                            </v-chip>
                            <v-chip
                              label
                              value="title"
                              class="transparent-background font-weight-medium mb-3"
                              ><v-icon left small
                                >mdi-sort-alphabetical-variant</v-icon
                              >Alphabetisch</v-chip
                            >
                          </v-chip-group>
                        </v-col>
                      </v-row>
                    </v-card-subtitle>
                  </v-card>
                  <v-divider></v-divider>
                </template>
                <template v-slot:[`item.title`]="{ item }">
                  <div class="d-flex flex-column">
                    <span
                      class="d-block font-weight-semibold text--primary text-truncate"
                      >{{ item.title }}</span
                    >
                    <!-- <small class="grey--text">{{ item.description }}</small> -->
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </v-form>
        </template>
      </custom-dialog>
      <custom-dialog
        v-if="helpers.dataImportCourses.dialogModel"
        @close="toggleCoursesImportDialog()"
        title="Lehrgänge importieren"
        text="Importierte Lehrgänge stehen nach dem Import sofort zur Verfügung."
        width="m"
        :errorMessage="helpers.dataImportCourses.errorMessage"
        :btnAction="importCourses"
        :btnDisabled="
          helpers.dataImportCourses.selectedImportTemplates.length === 0
        "
        :loading="helpers.dataImportCourses.loading"
        btnText="Auswahl importieren"
      >
        <template v-slot:content>
          <v-form
            v-model="helpers.dataImportCourses.formIsValid"
            @submit.prevent="importCourses()"
          >
            <v-select
              v-model="helpers.dataImportCourses.selectedCourseGroup"
              :items="courseGroups"
              item-text="title"
              outlined
              return-object
              label="Lehrgangsgruppe für Import auswählen"
              hint="Die ausgewählte Lehrgangsgruppe wird für alle zu importierenden Lehrgängen verwendet."
              persistent-hint
              dense
              class="my-2"
              :rules="[rules.required]"
            ></v-select>

            <v-card outlined class="mt-2">
              <v-data-table
                v-model="helpers.dataImportCourses.selectedImportTemplates"
                :items="helpers.dataImportCourses.importTemplates"
                :headers="helpers.dataImportCourses.tableHeaders"
                :search="helpers.dataImportCourses.search"
                item-key="title"
                show-select
                :sort-by="helpers.dataImportCourses.sortSelector"
                :sort-desc="false"
                checkbox-color="primary"
                disable-pagination
                hide-default-footer
                mobile-breakpoint="0"
                fixed-header
                height="35vh"
                class="mt-1"
                no-results-text="Keine Ergebnisse für Suchbegriff"
              >
                <template v-slot:top>
                  <v-card flat>
                    <v-card-subtitle>
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model="helpers.dataImportCourses.search"
                            label="Nach Lehrgängen suchen"
                            prepend-inner-icon="mdi-magnify"
                            dense
                            hide-details="auto"
                            outlined
                            clearable
                          ></v-text-field>
                        </v-col>
                        <v-col>
                          <span class="mr-4">Sortieren nach</span>
                          <v-chip-group
                            v-model="helpers.dataImportCourses.sortSelector"
                            mandatory
                            active-class="primary--text"
                          >
                            <v-chip
                              label
                              value="sortKey"
                              class="transparent-background font-weight-medium mr-2 mb-3"
                              ><v-icon left small
                                >mdi-sort-numeric-variant</v-icon
                              >Sortierschlüssel
                            </v-chip>
                            <v-chip
                              label
                              value="title"
                              class="transparent-background font-weight-medium mb-3"
                              ><v-icon left small
                                >mdi-sort-alphabetical-variant</v-icon
                              >Alphabetisch</v-chip
                            >
                          </v-chip-group>
                        </v-col>
                      </v-row>
                    </v-card-subtitle>
                  </v-card>
                  <v-divider></v-divider>
                </template>
                <template v-slot:[`item.title`]="{ item }">
                  <div class="d-flex flex-column">
                    <span
                      class="d-block font-weight-semibold text--primary text-truncate"
                      >{{ item.title }}</span
                    >
                    <!-- <small class="grey--text">{{ item.description }}</small> -->
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </v-form>
        </template>
      </custom-dialog>
    </section>
  </div>
</template>

<script>
import DataChip from "@/components/_systemwide/DataChip.vue";
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import {
  GET_COURSE_GROUPS,
  GET_COURSES,
  CREATE_COURSES,
  CREATE_COURSE_GROUPS,
} from "@/store/action-types.js";
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import DataAvatar from "@/components/_systemwide/DataAvatar.vue";
import { MEMBER_MANAGEMENT_CONFIG_COURSES_create } from "@/data/permission-types.js";
import { importableCourseGroups } from "@/data/import/importable-courseGroups.js";
import { importableCourses } from "@/data/import/importable-courses.js";

export default {
  name: "member-management-config-courses",
  components: {
    TippCard,
    Subheader,
    CustomDialog,
    DataAvatar,
    DataChip,
  },
  data() {
    return {
      courseGroupActions: [
        {
          title: "Erstellen",
          icon: "mdi-plus",
          permission: `${MEMBER_MANAGEMENT_CONFIG_COURSES_create}`,
          actionStyle: "textBtn",
          function: this.createCourseGroup,
        },
        {
          title: "Importieren",
          icon: "mdi-import",
          permission: `${MEMBER_MANAGEMENT_CONFIG_COURSES_create}`,
          actionStyle: "textBtn",
          function: this.toggleCourseGroupsImportDialog,
        },
      ],
      coursesActions: [
        {
          title: "Erstellen",
          icon: "mdi-plus",
          permission: `${MEMBER_MANAGEMENT_CONFIG_COURSES_create}`,
          actionStyle: "textBtn",
          function: this.createCourse,
        },
        {
          title: "Importieren",
          icon: "mdi-import",
          permission: `${MEMBER_MANAGEMENT_CONFIG_COURSES_create}`,
          actionStyle: "textBtn",
          function: this.toggleCoursesImportDialog,
        },
      ],
      courseGroupHeaders: [
        {
          text: "",
          value: "icon",
          sortable: false,
          width: "1%",
        },
        {
          text: "Bezeichnung",
          value: "title",
        },
        {
          text: "Sortierschlüssel",
          value: "sortKey",
        },
      ],
      courseHeaders: [
        {
          text: "Bezeichnung",
          value: "title",
        },
        {
          text: "Lehrgangsart",
          value: "type",
        },
        {
          text: "Kurzbeschreibung",
          value: "description",
          sortable: false,
        },
      ],

      helpers: {
        dataImportCourseGroups: {
          dialogModel: false,
          loading: false,
          formIsValid: false,
          importTemplates: importableCourseGroups,
          sortSelector: "sortKey",
          errorMessage: "",
          search: "",
          selectedImportTemplates: [],
          tableHeaders: [
            {
              text: "Titel",
              value: "title",
              align: "start",
            },
          ],
        },
        dataImportCourses: {
          dialogModel: false,
          loading: false,
          formIsValid: false,
          importTemplates: importableCourses,
          sortSelector: "priority",
          errorMessage: "",
          search: "",
          selectedImportTemplates: [],
          selectedCourseGroup: null,
          tableHeaders: [
            {
              text: "Titel",
              value: "title",
              align: "start",
            },
          ],
        },
        snackbar: { model: false, text: "" },
      },
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
    };
  },
  computed: {
    courses() {
      const data = this.$store.state.memberManagement.courses;
      const result = data.map((item) => {
        const container = {
          ...item,
          groupByValue:
            item.group.sortKey.toString().padStart(4, "0") +
            "_" +
            item.group.title,
        };
        return container;
      });
      return result;
    },
    courseGroups() {
      return this.$store.state.memberManagement.courseGroups;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_COURSE_GROUPS}`, {
        organizationId: this.$route.params.organizationId,
      });
      this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_COURSES}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    openCourse(item) {
      this.$router.push({
        name: "member-management-config-courses-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item.meta.id,
        },
      });
    },
    openCourseGroup(item) {
      this.$router.push({
        name: "member-management-config-courses-course-group-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item.meta.id,
        },
      });
    },
    colorFromType(type) {
      switch (type) {
        case "base":
          return "#9C27B0FF";
        case "advanced":
          return "#2196F3FF";
        case "training":
          return "#CDDC39FF";
        case "internship":
          return "#FF9800FF";
        case "renewal":
          return "#9C27B0FF";
        case "other":
        default:
          return "#9E9E9EFF";
      }
    },
    textFromType(type) {
      switch (type) {
        case "base":
          return "Grundlehrgang";
        case "advanced":
          return "Aufbaulehrgang";
        case "training":
          return "Fortbildung";
        case "internship":
          return "Praktikum";
        case "renewal":
          return "Rezertifizierung";
        default:
          return "Fehler";
      }
    },
    iconFromType(type) {
      switch (type) {
        case "base":
          return "mdi-book-open-variant";
        case "advanced":
          return "mdi-stairs-up";
        case "training":
          return "mdi-book-information-variant";
        default:
          return "mdi-alert-circle";
      }
    },
    toggleCourseGroupsImportDialog() {
      this.helpers.dataImportCourseGroups.dialogModel =
        !this.helpers.dataImportCourseGroups.dialogModel;
    },
    toggleCoursesImportDialog() {
      this.helpers.dataImportCourses.dialogModel =
        !this.helpers.dataImportCourses.dialogModel;
    },
    stripPrefix(item) {
      return item.substring(item.indexOf("_") + 1);
    },
    createCourse() {
      this.$router.push({
        name: "member-management-config-courses-new",
        params: {
          organizationId: this.$route.params.organizationId,
        },
      });
    },
    createCourseGroup() {
      this.$router.push({
        name: "member-management-config-courses-course-group-new",
        params: {
          organizationId: this.$route.params.organizationId,
        },
      });
    },
    importCourses() {
      if (this.helpers.dataImportCourses.selectedImportTemplates.length > 0) {
        this.helpers.dataImportCourses.loading = true;

        const coursesToImport =
          this.helpers.dataImportCourses.selectedImportTemplates.map((item) => {
            return {
              ...item,
              group: {
                title: this.helpers.dataImportCourses.selectedCourseGroup.title,
                color: this.helpers.dataImportCourses.selectedCourseGroup.color,
                sortKey:
                  this.helpers.dataImportCourses.selectedCourseGroup.sortKey,
                icon: this.helpers.dataImportCourses.selectedCourseGroup.icon,
                id: this.helpers.dataImportCourses.selectedCourseGroup.meta.id,
              },
            };
          });

        this.$store
          .dispatch(`${MEMBER_MANAGEMENT}${CREATE_COURSES}`, {
            organizationId: this.$route.params.organizationId,

            courses: coursesToImport,
          })
          .then(() => {
            return this.initialize();
          })
          .then(() => {
            this.toggleCoursesImportDialog();
            this.helpers.dataImportCourses.loading = false;
            this.helpers.dataImportCourses.selectedImportTemplates = [];
            this.helpers.dataImportCourses.search = "";
            this.helpers.snackbar.model = true;
            this.helpers.snackbar.text = "Import erfolgreich";
          })
          .catch((error) => {
            this.helpers.dataImportCourses.loading = false;
            console.log(error);
            this.helpers.dataImportCourses.errorMessage = error.message;
          });
      } else {
        alert(
          "Wähle mindestens einen Lehrgang aus, um den Import duchrzuführen."
        );
      }
    },
    importCourseGroups() {
      if (
        this.helpers.dataImportCourseGroups.selectedImportTemplates.length > 0
      ) {
        this.helpers.dataImportCourseGroups.loading = true;
        this.$store
          .dispatch(`${MEMBER_MANAGEMENT}${CREATE_COURSE_GROUPS}`, {
            organizationId: this.$route.params.organizationId,
            courseGroups:
              this.helpers.dataImportCourseGroups.selectedImportTemplates,
          })
          .then(() => {
            return this.initialize();
          })
          .then(() => {
            this.toggleCourseGroupsImportDialog();
            this.helpers.dataImportCourseGroups.loading = false;
            this.helpers.dataImportCourseGroups.selectedImportTemplates = [];
            this.helpers.dataImportCourseGroups.search = "";
            this.helpers.snackbar.model = true;
            this.helpers.snackbar.text = "Import erfolgreich";
          })
          .catch((error) => {
            this.helpers.dataImportCourseGroups.loading = false;
            console.log(error);
            this.helpers.dataImportCourseGroups.errorMessage = error.message;
          });
      } else {
        alert(
          "Wähle mindestens eine Lehrgangsgruppe aus, um den Import duchrzuführen."
        );
      }
    },
  },
};
</script>

<style>
.group-header-th {
  height: 32px !important;
}
</style>
