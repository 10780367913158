<template>
  <div>
    <NavigationBar
      titel="Zielvorgaben"
      :actions="actions"
      isPreview
    ></NavigationBar>
    <section>
      <TippCard
        hintID="Terminplanung_Konfiguration_Allgemein_1_V1"
        text="Veranstaltungen basieren auf einer Veranstaltungsart. Jeder Veranstaltungsart sind verschiedene Funktionen und Parameter zugewiesen, die sich teilweise auch auf bestehende Veranstaltungen auswirken können. Auf abgeschlossene Veranstaltungen haben Änderungen keine Auswirkung."
      >
      </TippCard>
    </section>
    <section>
      <template>
        <v-container fluid>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <div class="mt-7">
                <v-data-iterator
                  :items="performanceTargetsWithResults"
                  sort-by="sortKey"
                  no-data-text="Keine Zielvorgaben vorhanden"
                >
                  <template v-slot:default="props">
                    <v-row class="match-height">
                      <v-col
                        v-for="item in props.items"
                        :key="item.meta.id"
                        cols="12"
                      >
                        <performance-target-details-card
                          :performanceTarget="item"
                        ></performance-target-details-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <v-btn @click="startQuery()">startQuery()</v-btn>
  </div>
</template>

<script>
import { functions } from "@/firebase";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import PerformanceTargetDetailsCard from "@/components/member-management/performance-targets/PerformanceTargetDetailsCard.vue";
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import {
  GET_PERFORMANCE_TARGETS,
  GET_PERFORMANCE_TARGET_RESULTS,
} from "@/store/action-types.js";
import { MEMBER_MANAGEMENT_PERFORMANCE_TARGETS_create } from "@/data/permission-types.js";

export default {
  name: "member-management-performance-targets",
  components: {
    NavigationBar,
    TippCard,
    PerformanceTargetDetailsCard,
  },
  data() {
    return {
      actions: [
        {
          title: "Erstellen",
          icon: "mdi-plus",
          permission: `${MEMBER_MANAGEMENT_PERFORMANCE_TARGETS_create}`,
          actionStyle: "textBtn",
          function: () => {
            this.$router.push({
              name: "member-management-performance-targets-new",
              params: {
                organizationId: this.$route.params.organizationId,
              },
            });
          },
        },
      ],
    };
  },
  computed: {
    performanceTargets() {
      return this.$store.state.memberManagement.performanceTargets;
    },
    performanceTargetResults() {
      return this.$store.state.memberManagement.performanceTargetResults;
    },
    performanceTargetsWithResults() {
      return this.performanceTargets.map((performanceTarget) => {
        const matchingResults = this.performanceTargetResults
          .map((result) =>
            result.performanceTargets.map((targetResult) => ({
              ...targetResult,
              user: result.user,
            }))
          )
          .flat()
          .filter(
            (targetResult) =>
              targetResult.targetId === performanceTarget.meta.id
          );

        const groupedByDateAndStatus = matchingResults.reduce((acc, result) => {
          const date = result.calculationDate.split("T")[0]; // Get date in YYYY-MM-DD format
          if (!acc[date]) {
            acc[date] = {};
          }
          if (!acc[date][result.status]) {
            acc[date][result.status] = 0;
          }
          acc[date][result.status]++;
          return acc;
        }, {});

        const statuses = [
          "met",
          "notMet",
          // "metByIndividualRuling",
          // "notMetByIndividualRuling",
          // "fullySuspended",
          // "notStarted",
          // "temporarilySuspended",
          // "individualRulingRequired",
          "notApplicable",
          "unknown",
        ];

        const series = statuses.map((status) => ({
          name: this.statusText(status),
          data: Object.keys(groupedByDateAndStatus).map((date) => ({
            x: new Date(date),
            y: groupedByDateAndStatus[date][status] || 0,
          })),
        }));

        // Calculate the next and past cut-off dates based on the monitoring interval
        const currentDate = new Date();
        let nextCutOffDate = new Date(currentDate);
        let pastCutOffDate = new Date(currentDate);
        switch (performanceTarget.monitoringInterval.unit) {
          case "days":
          case "days+current":
            nextCutOffDate.setDate(
              currentDate.getDate() + performanceTarget.monitoringInterval.value
            );
            pastCutOffDate.setDate(
              currentDate.getDate() - performanceTarget.monitoringInterval.value
            );
            break;
          case "weeks":
          case "weeks+current":
            nextCutOffDate.setDate(
              currentDate.getDate() +
                performanceTarget.monitoringInterval.value * 7
            );
            pastCutOffDate.setDate(
              currentDate.getDate() -
                performanceTarget.monitoringInterval.value * 7
            );
            break;
          case "months":
          case "months+current":
            nextCutOffDate.setMonth(
              currentDate.getMonth() +
                performanceTarget.monitoringInterval.value
            );
            pastCutOffDate.setMonth(
              currentDate.getMonth() -
                performanceTarget.monitoringInterval.value
            );
            break;
          default:
            nextCutOffDate = new Date(currentDate);
            pastCutOffDate = new Date(currentDate);
        }

        const chartOptions = {
          chart: {
            type: "area",
            height: 200,
          },
          xaxis: {
            type: "datetime",
            title: {
              text: "Datum",
            },
          },
          yaxis: {
            title: {
              text: "Anzahl Personen",
            },
          },
          // title: {
          //   text: "Performance History",
          //   align: "left",
          // },
          annotations: {
            xaxis: [
              {
                x: nextCutOffDate.getTime(),
                borderColor: "#000",
                fillColor: "#FEB019",
                label: {
                  orientation: "horizontal",
                  textAnchor: "start",
                  text: `Next Cut-off: ${
                    nextCutOffDate.toISOString().split("T")[0]
                  }`,
                },
              },
              {
                x: pastCutOffDate.getTime(),
                borderColor: "#000",
                fillColor: "#FEB019",
                label: {
                  orientation: "horizontal",
                  textAnchor: "start",
                  text: `Past Cut-off: ${
                    pastCutOffDate.toISOString().split("T")[0]
                  }`,
                },
              },
            ],
          },
        };

        return {
          ...performanceTarget,
          results: matchingResults,
          series: series,
          chartOptions: chartOptions,
        };
      });
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_PERFORMANCE_TARGETS}`, {
        organizationId: this.$route.params.organizationId,
      });
      this.$store.dispatch(
        `${MEMBER_MANAGEMENT}${GET_PERFORMANCE_TARGET_RESULTS}`,
        {
          organizationId: this.$route.params.organizationId,
        }
      );
    },
    statusText(statusId) {
      switch (statusId) {
        case "met":
          return "Zielvorgabe erfüllt";
        case "notMet":
          return "Zielvorgabe nicht erfüllt";
        case "metByIndividualRuling":
          return "Zielvorgabe erfüllt (EA)";
        case "notMetByIndividualRuling":
          return "Zielvorgabe nicht erfüllt (EA)";
        case "fullySuspended":
          return "Zielvorgabe vollständig ausgesetzt";
        case "notStarted":
          return "Zielvorgabe nicht begonnen";
        case "temporarilySuspended":
          return "Zielvorgabe zeitweise ausgesetzt";
        case "individualRulingRequired":
          return "Einzelfallentscheidung erforderlich";
        case "notApplicable":
          return "Zielvorgabe nicht anwendbar";
        case "unknown":
          return "Zielvorgabe unbekannt";
        default:
          return "Fehler";
      }
    },
    startQuery() {
      const callFunction = functions.httpsCallable(
        "memberManagement-computePerformanceTargetResults"
      );
      return callFunction({
        organizationId: this.$route.params.organizationId,
      })
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.error("Error: ", error);
        });
    },
  },
};
</script>
