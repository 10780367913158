<template>
  <div>
    <section>
      <TippCard
        hintID="Terminplanung_Konfiguration_Allgemein_1_V1"
        text="Um das Erstellen von Veranstaltungen zu erleichtern, kannst Du diese aus Vorlagen heraus erstellen. Lege dafür die gewünschten Werte im Vorfeld fest und speichere diese als Vorlage ab. Beim Anlegen einer Veranstaltung kannst Du nun die bereits eingegeben Daten schnell und unkompliziert übernehmen."
      >
      </TippCard>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-book"
                title="Vorlagen"
                :actions="actions"
              ></Subheader>
              <div class="mt-7">
                <v-data-table
                  :headers="headers"
                  :items="templates"
                  :sort-by="sortBy"
                  :sort-desc="sortDesc"
                  no-data-text="Keine Vorlagen vorhanden"
                  item-key="meta.id"
                  class="elevation-2"
                  :items-per-page="15"
                  :footer-props="footerProps"
                  @click:row="routeDetails"
                >
                  <template v-slot:[`item.template.title`]="{ item }">
                    <div class="d-flex flex-column">
                      {{ item.template.title }}
                      <small class="grey--text">
                        <span>{{ item.template.description }} </span>
                      </small>
                    </div>
                  </template>
                </v-data-table>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <router-view />
  </div>
</template>

<script>
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import { SCHEDULING } from "@/store/modules.js";
import { GET_EVENT_TEMPLATES } from "@/store/action-types.js";
import { SCHEDULING_CONFIG_EVENT_TEMPLATES_create } from "@/data/permission-types.js";

export default {
  name: "scheduling-config-tab-templates",
  components: {
    TippCard,
    Subheader,
  },
  data() {
    return {
      sortDesc: false,
      sortBy: "template.sortKey",
      footerProps: {
        "items-per-page-options": [15, 25, 50, -1],
      },
      headers: [
        {
          text: "Bezeichnung",
          value: "template.title",
          align: "start",
        },
        {
          text: "Sortierschlüssel",
          value: "template.sortKey",
        },
        // { text: "", value: "data-table-expand", width: "1%" },
      ],
      actions: [
        {
          title: "Erstellen",
          icon: "mdi-playlist-plus",
          permission: `${SCHEDULING_CONFIG_EVENT_TEMPLATES_create}`,
          actionStyle: "textBtn",
          function: this.vorlageErstellen,
        },
      ],
    };
  },
  computed: {
    templates() {
      return this.$store.state.scheduling.eventTemplates;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${SCHEDULING}${GET_EVENT_TEMPLATES}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    vorlageErstellen() {
      this.$router.push({
        name: "scheduling-config-tab-templates-template-new",
      });
    },
    routeDetails(item) {
      this.$router.push({
        name: "scheduling-config-tab-templates-template-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item.meta.id,
        },
      });
    },
  },
};
</script>
