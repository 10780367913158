<template>
  <div>
    <v-card
      outlined
      link
      height="42px"
      class="d-block align-center text-truncate"
    >
      <v-list-item class="px-2 py-0 my-0">
        <!-- <v-avatar
                            rounded
                            :color="
                              $vuetify.theme.dark
                                ? 'grey darken-3'
                                : 'grey lighten-3'
                            "
                            class="mr-2"
                          >
                            <v-img contain :src="member.photoUrl"></v-img>
                          </v-avatar> -->
        <v-list-item-content class="mt-n1 py-0">
          <v-list-item-title class="subtitle-2 font-weight-medium">{{
            user.user.displayName
          }}</v-list-item-title>
          <v-list-item-subtitle>
            {{ user.user.rank.shortTitle }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "matrix-person-card",
  components: {},
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  methods: {},
};
</script>
