import {
  MY_ORGANIZATION_DASHBOARD_read,
  SELF_SERVICE_read,
  MY_ORGANIZATION_UNITS_read,
  MY_ORGANIZATION_ORGANIZATIONAL_CHART_read,
  MY_ORGANIZATION_NEWS_read,
  MY_ORGANIZATION_SURVEYS_read,
  MY_ORGANIZATION_ASSIGNMENTS_read,
  MY_ORGANIZATION_KNOWLEDGE_BASE_read,
  // MY_ORGANIZATION_WACHBUCH_read,
  // MY_ORGANIZATION_TEAMS_read,
  // MY_ORGANIZATION_CONFIG_read,
  SCHEDULING_EVENTS_read,
  SCHEDULING_ARCHIVE_read,
  SCHEDULING_CONFIG_read,
  SCHEDULING_CALENDAR_read,
  SCHEDULING_TEAMPLANNER_read,
  DUTY_MANAGEMENT_REPORTS_read,
  DUTY_MANAGEMENT_ATTENDANCE_RECORDS_read,
  DUTY_MANAGEMENT_ARCHIVE_read,
  DUTY_MANAGEMENT_CONFIG_read,
  ADMINISTRATION_CONFIG_read,
  AVAILABILITY_LEAVES_read,
  AVAILABILITY_UNITS_read,
  AVAILABILITY_REQUESTS_read,
  AVAILABILITY_ARCHIVE_read,
  AVAILABILITY_CONFIG_read,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_read,
  MEMBER_MANAGEMENT_DEADLINES_read,
  MEMBER_MANAGEMENT_PERFORMANCE_TARGETS_read,
  MEMBER_MANAGEMENT_ASSIGNMENTS_read,
  MEMBER_MANAGEMENT_ONOFFBOARDING_read,
  // MEMBER_MANAGEMENT_SKILLS_read,
  // MEMBER_MANAGEMENT_RANKS_read,
  // MEMBER_MANAGEMENT_HONORS_read,
  ACCOUNTING_TIME_SHEETS_read,
  ACCOUNTING_PAYSLIPS_read,
  ACCOUNTING_PAYROLL_read,
  ACCOUNTING_ARCHIVE_read,
  ACCOUNTING_CONFIG_read,
  MEMBER_MANAGEMENT_CONFIG_read,
  ADMINISTRATION_BILLING_read,
  ADMINISTRATION_USERS_read,
  ADMINISTRATION_SECURITY_read,
  ADMINISTRATION_PRIVACY_read,
  ADMINISTRATION_EXTENSIONS_read,
  ADMINISTRATION_AUTOMATION_read,
  ADMINISTRATION_AUDIT_read,
  SUPPORT_read,
} from "@/data/permission-types.js";

export const modules = [
  {
    name: "Dashboard",
    id: "myOrganization.dashboard",
    shortDescription: "Monitoring und Schnellzugriffe",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
    icon: "mdi-monitor-dashboard",
    route: { name: "my-organization-dashboard" },
    permissions: `${MY_ORGANIZATION_DASHBOARD_read}`,
    color: "red",
    isNew: false,
    isInBeta: false,
    isComingSoon: false,
    isRequired: true,
    dependencies: [],
  },
  {
    name: "Self-Service",
    id: "selfService",
    shortDescription: "Persönliche Schnellzugriffe und Einträge",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
    icon: "mdi-touch-text-outline",
    route: { name: "self-service-portal" },
    permissions: `${SELF_SERVICE_read}`,
    color: "red",
    isNew: true,
    isInBeta: true,
    isComingSoon: false,
    isRequired: true,
    dependencies: [],
  },
  {
    name: "Meine Organisation",
    id: "myOrganization",
    shortDescription: "Zentrale der Organisation",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
    icon: "mdi-home",
    color: "teal",
    isOpen: false,
    isNew: false,
    isInBeta: false,
    isComingSoon: false,
    isRequired: false,
    dependencies: [],
    children: [
      // {
      //   name: "Dashboard",
      //   route: { name: "my-organization-dashboard" },
      //   permissions: `${MY_ORGANIZATION_DASHBOARD_read}`,
      //   id: "Meine Organisation.Dashboard",
      // },
      {
        name: "Neuigkeiten",
        route: { name: "my-organization-news" },
        permissions: `${MY_ORGANIZATION_NEWS_read}`,
        id: "myOrganization.news",
      },
      {
        name: "Umfragen",
        route: { name: "my-organization-surveys" },
        permissions: `${MY_ORGANIZATION_SURVEYS_read}`,
        id: "myOrganization.surveys",
      },
      {
        name: "Einsatzmittel",
        route: { name: "my-organization-units" },
        permissions: `${MY_ORGANIZATION_UNITS_read}`,
        id: "myOrganization.units",
      },
      // {
      //   name: "Wachbuch",
      //   route: { name: "my-organization-guardbook" },
      //   permissions: `${MY_ORGANIZATION_WACHBUCH_read}`,
      //   id: "Meine Organisation.Wachbuch",
      // },
      {
        name: "Wissensdatenbank",
        route: { name: "my-organization-knowledge-base" },
        permissions: `${MY_ORGANIZATION_KNOWLEDGE_BASE_read}`,
        id: "myOrganization.knowledgeBase",
      },
      {
        name: "Personalübersicht",
        route: { name: "my-organization-organization-chart" },
        permissions: `${MY_ORGANIZATION_ORGANIZATIONAL_CHART_read}`,
        id: "myOrganization.organizationalChart",
      },
      {
        name: "Aufgabengebiete",
        route: { name: "my-organization-assignments" },
        permissions: `${MY_ORGANIZATION_ASSIGNMENTS_read}`,
        id: "myOrganization.assignments",
      },
      // {
      //   name: "Teams",
      //   route: { name: "my-organization-teams" },
      //   permissions: `${MY_ORGANIZATION_TEAMS_read}`,
      //   id: "Meine Organisation.Teams",
      // },
      // {
      //   name: "Alarmgruppen",
      //   route: "/meineOrganisation/alarmgruppen",
      //   permissions: "",
      //   id: "Meine Organisation.Alarmgruppen",
      // },
      // {
      //   name: "Konfiguration",
      //   route: { name: "my-organization-config-tab-dashboard" },
      //   permissions: `${MY_ORGANIZATION_CONFIG_read}`,
      //   id: "Meine Organisation.Konfiguration",
      // },
    ],
  },
  // {
  //   name: "Alarmierung",
  //   id: "alarming",
  //   shortDescription: "",
  //   description:
  //     "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
  //   icon: "mdi-alarm-bell",
  //   color: "light-green",
  //   isOpen: false,
  //   isNew: false,
  //   isInBeta: false,
  //   isComingSoon: true,
  //   isRequired: false,
  //   dependencies: [],
  //   children: [
  //     {
  //       name: "Alarme",
  //       route: { name: "alarming-" },
  //       // permissions: `${DUTY_MANAGEMENT_CONFIG_read}`,
  //       id: "alarming.incidents",
  //     },
  //     {
  //       name: "Einsatzmittel",
  //       route: { name: "availability-units-tab-units" },
  //       permissions: `${AVAILABILITY_UNITS_read}`,
  //       id: "alarming.units",
  //     },
  //     {
  //       name: "Alarmgruppen",
  //       route: { name: "alarming-shift-groups" },
  //       // permissions: `${DUTY_MANAGEMENT_CONFIG_read}`,
  //       id: "alarming.alarms",
  //     },
  //     {
  //       name: "Schichtzeitpläne",
  //       route: { name: "alarming-shift-groups" },
  //       // permissions: `${DUTY_MANAGEMENT_CONFIG_read}`,
  //       id: "alarming.alarms",
  //     },
  //     {
  //       name: "Archiv",
  //       route: { name: "alarming-archive" },
  //       // permissions: `${DUTY_MANAGEMENT_CONFIG_read}`,
  //       id: "alarming.archive",
  //     },
  //     {
  //       name: "Konfiguration",
  //       route: { name: "alarming-config" },
  //       // permissions: `${DUTY_MANAGEMENT_CONFIG_read}`,
  //       id: "alarming.config",
  //     },
  //   ],
  // },
  {
    name: "Terminplanung",
    id: "scheduling",
    shortDescription: "Planung und Verwaltung von Terminen und Veranstaltungen",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
    icon: "mdi-calendar",
    color: "purple",
    isOpen: false,
    isNew: false,
    isInBeta: false,
    isComingSoon: false,
    isRequired: false,
    dependencies: [],
    children: [
      {
        name: "Veranstaltungen",
        route: { name: "scheduling-events" },
        permissions: `${SCHEDULING_EVENTS_read}`,
        id: "scheduling.events",
      },
      {
        name: "Abfragen",
        route: { name: "scheduling-requests" },
        permissions: `${AVAILABILITY_REQUESTS_read}`,
        id: "availability.requests",
      },
      {
        name: "Kalender",
        route: { name: "scheduling-calendar" },
        permissions: `${SCHEDULING_CALENDAR_read}`,
        id: "scheduling.calendar",
      },
      {
        name: "Teamplaner",
        route: { name: "scheduling-teamplanner" },
        permissions: `${SCHEDULING_TEAMPLANNER_read}`,
        id: "scheduling.teamplanner",
      },
      {
        name: "Archiv",
        route: { name: "scheduling-archive-tab-events" },
        permissions: `${SCHEDULING_ARCHIVE_read}`,
        id: "scheduling.archive",
      },
      {
        name: "Konfiguration",
        route: { name: "scheduling-config-tab-general" },
        permissions: `${SCHEDULING_CONFIG_read}`,
        id: "scheduling.config",
      },
    ],
  },
  {
    name: "Diensterfassung",
    id: "dutyManagement",
    shortDescription: "",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
    icon: "mdi-briefcase-edit",
    color: "light-green",
    isOpen: false,
    isNew: false,
    isInBeta: false,
    isComingSoon: false,
    isRequired: false,
    dependencies: [],
    children: [
      {
        name: "Dienstberichte",
        route: { name: "duty-management-reports" },
        permissions: `${DUTY_MANAGEMENT_REPORTS_read}`,
        id: "dutyManagement.reports",
      },
      {
        name: "Anwesenheit",
        route: { name: "duty-management-attendance-records" },
        permissions: `${DUTY_MANAGEMENT_ATTENDANCE_RECORDS_read}`,
        id: "dutyManagement.attendanceRecords",
      },
      {
        name: "Archiv",
        route: { name: "duty-management-archive" },
        permissions: `${DUTY_MANAGEMENT_ARCHIVE_read}`,
        id: "dutyManagement.archive",
      },
      {
        name: "Konfiguration",
        route: { name: "duty-management-config-tab-duty-types" },
        permissions: `${DUTY_MANAGEMENT_CONFIG_read}`,
        id: "dutyManagement.config",
      },
    ],
  },
  {
    name: "Verfügbarkeit",
    id: "availability",
    shortDescription:
      "Vorausschauendes Planen und Abfragen von Verfügbarkeiten",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
    icon: "mdi-checkbox-multiple-marked-circle",
    color: "lime",
    isOpen: false,
    isNew: false,
    isInBeta: false,
    isComingSoon: false,
    isRequired: false,
    dependencies: [],
    children: [
      // { name: 'Monitoring', route:'/verfuegbarkeit/monitoring', permissions: '', id: '' },
      {
        name: "Abwesenheiten",
        route: { name: "availability-leaves" },
        permissions: `${AVAILABILITY_LEAVES_read}`,
        id: "availability.leaves",
      },
      // {
      //   name: "Status",
      //   route: "/verfuegbarkeit/status",
      //   permissions: "",
      //   id: "",
      // },
      {
        name: "Einsatzmittel",
        route: { name: "availability-units-tab-units" },
        permissions: `${AVAILABILITY_UNITS_read}`,
        id: "availability.units",
      },
      {
        name: "Vorausplanung",
        route: { name: "availability-forecasts-tab-overview" },
        permissions: "",
        id: "availability.forecasts",
      },
      {
        name: "Archiv",
        route: { name: "availability-archive-tab-leaves" },
        permissions: `${AVAILABILITY_ARCHIVE_read}`,
        id: "availability.archive",
      },
      {
        name: "Konfiguration",
        route: { name: "availability-config-tab-general" },
        permissions: `${AVAILABILITY_CONFIG_read}`,
        id: "availability.config",
      },
    ],
  },
  {
    name: "Mitgliederverwaltung",
    id: "memberManagement",
    shortDescription: "Verwaltung von Personal- und Mitgliederdaten",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
    icon: "mdi-account-group",
    color: "amber",
    isOpen: false,
    isNew: false,
    isInBeta: false,
    isComingSoon: false,
    isRequired: false,
    dependencies: [],
    children: [
      {
        name: "Personaldaten",
        route: { name: "member-management-personnel-records" },
        permissions: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_read}`,
        id: "memberManagement.personnelRecords",
      },
      {
        name: "On-/Offboarding",
        route: { name: "member-management-on-offboarding" },
        permissions: `${MEMBER_MANAGEMENT_ONOFFBOARDING_read}`,
        id: "memberManagement.onoffboarding",
      },
      {
        name: "Fristenüberwachung",
        route: { name: "member-management-deadline-monitoring-tab-overview" },
        permissions: `${MEMBER_MANAGEMENT_DEADLINES_read}`,
        id: "memberManagement.deadlineMonitoring",
      },
      {
        name: "Aufgabenverteilung",
        route: { name: "member-management-assignments" },
        permissions: `${MEMBER_MANAGEMENT_ASSIGNMENTS_read}`,
        id: "memberManagement.assignments",
      },
      {
        name: "Zielvorgaben",
        route: { name: "member-management-performance-targets" },
        permissions: `${MEMBER_MANAGEMENT_PERFORMANCE_TARGETS_read}`,
        id: "memberManagement.performanceTargets",
      },
      {
        name: "Konfiguration",
        route: { name: "member-management-config-skills" },
        permissions: `${MEMBER_MANAGEMENT_CONFIG_read}`,
        id: "memberManagement.config",
      },
    ],
  },
  {
    name: "Prozesse",
    id: "",
    shortDescription: "",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
    icon: "mdi-source-repository",
    color: "indigo",
    isOpen: false,
    isNew: false,
    isInBeta: false,
    isComingSoon: true,
    isRequired: false,
    dependencies: [],
    children: [
      { name: "Prozesse", route: "", permissions: "", id: "" },
      { name: "Dokumentenautomation", route: "", permissions: "", id: "" },
      { name: "Archiv", route: "", permissions: "", id: "" },
      { name: "Konfiguration", route: "", permissions: "", id: "" },
    ],
  },
  {
    name: "Inventarverwaltung",
    id: "inventory",
    shortDescription: "",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
    icon: "mdi-warehouse",
    color: "blue",
    isOpen: false,
    isNew: false,
    isInBeta: false,
    isComingSoon: true,
    isRequired: false,
    dependencies: [],
    children: [
      { name: "Inventar", route: "", permissions: "", id: "inventory." },
      { name: "Lagerübersicht", route: "", permissions: "", id: "inventory." },
      { name: "Prüfungen", route: "", permissions: "", id: "inventory." },
      {
        name: "Konfiguration",
        route: "",
        permissions: "",
        id: "inventory.config",
      },
    ],
  },
  {
    name: "Stundenabrechnung",
    id: "accounting",
    shortDescription: "",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
    icon: "mdi-account-clock",
    color: "purple",
    isOpen: false,
    isNew: false,
    isInBeta: false,
    isComingSoon: false,
    isRequired: false,
    dependencies: [],
    children: [
      {
        name: "Stundenmeldungen",
        route: { name: "accounting-time-sheets" },
        permissions: `${ACCOUNTING_TIME_SHEETS_read}`,
        id: "accounting.timeSheets",
      },
      {
        name: "Abrechnungszettel",
        route: { name: "accounting-payslips" },
        permissions: `${ACCOUNTING_PAYSLIPS_read}`,
        id: "accounting.payslips",
      },
      {
        name: "Abrechnungsstapel",
        route: { name: "accounting-payroll" },
        permissions: `${ACCOUNTING_PAYROLL_read}`,
        id: "accounting.payroll",
      },
      {
        name: "Archiv",
        route: { name: "accounting-archive-tab-time-sheets" },
        permissions: `${ACCOUNTING_ARCHIVE_read}`,
        id: "accounting.archive",
      },
      {
        name: "Konfiguration",
        route: { name: "accounting-config-tab-general" },
        permissions: `${ACCOUNTING_CONFIG_read}`,
        id: "accounting.config",
      },
    ],
  },
  {
    name: "Finanzen",
    id: "finance",
    shortDescription: "",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
    icon: "mdi-finance",
    color: "green",
    isOpen: false,
    isNew: false,
    isInBeta: false,
    isComingSoon: true,
    isRequired: false,
    dependencies: [],
    children: [
      {
        name: "Buchführung",
        route: { name: "finance-bookkeeping" },
        // permissions: "",
        id: "finance.bookkeeping",
      },
      {
        name: "Auslagen",
        route: { name: "finance-expenses" },
        // permissions: "",
        id: "finance.expenses",
      },
      {
        name: "Abrechnung",
        route: { name: "finance-accounting" },
        // permissions: "",
        id: "finance.accounting",
      },
      {
        name: "Berichte",
        route: { name: "finance-reports" },
        // permissions: "",
        id: "finance.reports",
      },
      {
        name: "Konfiguration",
        route: { name: "finance-config" },
        // permissions: "",
        id: "finance.config",
      },
    ],
  },
  {
    name: "Statistik",
    id: "",
    shortDescription: "",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
    icon: "mdi-chart-box",
    color: "deep-orange",
    isOpen: false,
    isNew: false,
    isInBeta: true,
    isComingSoon: false,
    isRequired: false,
    dependencies: [],
    children: [
      {
        name: "BI & Datenvisualisierung",
        route: { name: "" },
        permissions: "",
        id: "",
      },
      {
        name: "Dienste & Veranstaltungen",
        route: { name: "" },
        permissions: "",
        id: "",
      },
      {
        name: "Rückmeldungen",
        route: { name: "" },
        permissions: "",
        id: "",
      },
      {
        name: "Anwesenheit",
        route: { name: "" },
        permissions: "",
        id: "",
      },
      {
        name: "Verfügbarkeit",
        route: { name: "" },
        permissions: "",
        id: "",
      },
      {
        name: "Einsatzmittel",
        route: { name: "" },
        permissions: "",
        id: "",
      },
      {
        name: "Funktionen",
        route: { name: "" },
        permissions: "",
        id: "",
      },
    ],
  },

  {
    name: "Administration",
    id: "administration",
    shortDescription: "Konfiguration der Anwendung",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
    icon: "mdi-cog",
    color: "blue-grey",
    isOpen: false,
    isNew: false,
    isInBeta: false,
    isComingSoon: false,
    isRequired: true,
    dependencies: [],
    children: [
      {
        name: "Systemkonfiguration",
        route: { name: "admin-config-tab-general" },
        permissions: `${ADMINISTRATION_CONFIG_read}`,
        id: "administration.config",
      },
      {
        name: "Abrechnung",
        route: { name: "admin-billing" },
        permissions: `${ADMINISTRATION_BILLING_read}`,
        id: "administration.billing",
      },
      {
        name: "Benutzer",
        route: { name: "admin-users-tab-accounts" },
        permissions: `${ADMINISTRATION_USERS_read}`,
        id: "administration.users",
      },
      {
        name: "Sicherheit",
        route: { name: "admin-security-tab-general" },
        permissions: `${ADMINISTRATION_SECURITY_read}`,
        id: "administration.security",
      },
      {
        name: "Datenschutz",
        route: { name: "admin-privacy-tab-general" },
        permissions: `${ADMINISTRATION_PRIVACY_read}`,
        id: "administration.privacy",
      },
      {
        name: "Erweiterungen",
        route: { name: "admin-extensions" },
        permissions: `${ADMINISTRATION_EXTENSIONS_read}`,
        id: "administration.extensions",
      },
      {
        name: "Automation",
        route: { name: "admin-automation" },
        permissions: `${ADMINISTRATION_AUTOMATION_read}`,
        id: "administration.automation",
      },
      {
        name: "Audit",
        route: { name: "admin-audit" },
        permissions: `${ADMINISTRATION_AUDIT_read}`,
        id: "administration.audit",
      },
    ],
  },
  {
    name: "Support",
    id: "support",
    shortDescription: "Schnelle Hilfe und Support",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
    icon: "mdi-lifebuoy",
    route: { name: "support" },
    permissions: `${SUPPORT_read}`,
    color: "brown",
    isNew: false,
    isInBeta: false,
    isComingSoon: false,
    isRequired: false,
    dependencies: [],
  },
];
