<template>
  <div>
    <NavigationBar
      titel="Details"
      showBackBtn
      backRoute="scheduling-requests"
      :actions="actions"
      :tabs="tabs"
      :activeTab="activeTab"
    ></NavigationBar>
    <CustomDialog
      v-if="requestDeletionDialog.model"
      @close="toggleRequestDeletionDialog()"
      title="Abfrage löschen"
      text="Bist Du sicher, dass Du diese Abfrage löschen möchtest? Um eine Abfrage abzusagen, aktualisiere den Status der Abfrage."
      :loading="requestDeletionDialog.loading"
      :btnAction="deleteRequestAction"
      :errorMessage="requestDeletionDialog.errorMessage"
      btnColor="error"
      width="s"
    ></CustomDialog>
    <CustomDialog
      v-if="resetResponsesDialog.model"
      @close="toggleResetResponsesDialog()"
      title="Rückmeldungen zurücksetzen"
      text="Bist Du sicher, dass Du alle bereits abgegebenen Rückmeldungen zurücksetzen möchtest? Sie werden gelöscht und können nicht wiederhergestellt werden. Automatisierte Rückmeldungen sind davon nicht betroffen."
      :loading="resetResponsesDialog.loading"
      :btnAction="resetResponsesAction"
      :errorMessage="resetResponsesDialog.errorMessage"
      btnColor="error"
      width="s"
    ></CustomDialog>
    <CustomDialog
      v-if="lockResponsesDialog.model"
      @close="toggleLockResponsesDialog()"
      :title="lockResponseDialogTitle"
      :text="lockResponseDialogText"
      :loading="lockResponsesDialog.loading"
      :btnAction="lockResponsesAction"
      :errorMessage="lockResponsesDialog.errorMessage"
      btnColor="error"
      width="s"
    ></CustomDialog>
    <CustomDialog
      v-if="statusDialog.model"
      @close="toggleRequestStatusDialog()"
      title="Abfragesstatus aktualisieren"
      width="s"
    >
      <template v-slot:content>
        <br />
        <v-form v-model="formIsValid" @submit.prevent="updateRequestStatus()">
          <v-select
            v-model="requestData.status"
            :items="statusValues"
            item-text="status"
            :rules="[rules.required]"
            label="Status auswählen"
            outlined
          >
            <template v-slot:item="data">
              <v-list-item two-line>
                <v-list-item-action>
                  <v-icon :color="data.item.color">
                    {{ data.item.icon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ data.item.status }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{
                    data.item.description
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
            <template v-slot:selection="data">
              <v-list-item @click="toggle">
                <v-list-item-action>
                  <v-icon :color="data.item.color">
                    {{ data.item.icon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ data.item.status }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-select>
          <v-select
            v-if="requestData.status === 'canceled'"
            v-model="requestData.statusAnalytics.id"
            :items="reasonItems"
            label="Begründung für Status auswählen"
            :disabled="requestData.status !== 'canceled'"
            :rules="[rules.required]"
            outlined
            hint="Wird für statistische Auswertung erfasst"
            persistent-hint
          >
          </v-select>
          <v-text-field
            v-if="requestData.status === 'canceled'"
            v-model="requestData.statusAnalytics.reason"
            :disabled="requestData.status !== 'canceled'"
            label="Ergänzende Information zur Begründung (Optional)"
            hint="Wird für statistische Auswertung erfasst"
            persistent-hint
            outlined
          ></v-text-field>
          <v-textarea
            v-model="requestData.internalNote"
            counter
            clearable
            rows="3"
            no-resize
            auto-grow
            label="Interne Notiz"
            outlined
          ></v-textarea>
        </v-form>
      </template>
      <template v-slot:actions>
        <v-btn text @click="toggleRequestStatusDialog()">Abbrechen</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          color="success"
          :disabled="!formIsValid"
          @click="updateRequestStatus()"
        >
          <v-icon left>mdi-content-save</v-icon>Speichern</v-btn
        ></template
      >
    </CustomDialog>
    <v-snackbar
      v-model="helpers.snackbar.isVisible"
      class="pb-4"
      bottom
      app
      timeout="3000"
      >{{ helpers.snackbar.message }}
    </v-snackbar>
    <router-view :request="request" :requestType="requestData.type" />
  </div>
</template>

<script>
import { db, auth, functions, FieldValue } from "@/firebase";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import {
  SCHEDULING_EVENTS_update,
  SCHEDULING_EVENTS_delete,
  SCHEDULING_EVENTS_RESPONSES_write,
} from "@/data/permission-types.js";
import { SCHEDULING } from "@/store/modules.js";
import {
  DELETE_EVENT,
  // RESET_RESPONSES,
  UPDATE_REQUEST_META,
} from "@/store/action-types.js";

export default {
  name: "availability-request-details",
  components: {
    NavigationBar,
    CustomDialog,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
      personsData: [],
      requestData: {
        title: "",
        description: "",
        type: {
          id: "",
          title: "",
          color: "",
          description: "",
        },
        location: {
          name: "",
        },
        timeSlots: {
          start: { timestamp: null, timestampToDate: null },
          end: { timestamp: null, timestampToDate: null },
          data: [],
        },
        config: {
          organizer: {
            isAppointed: false,
            hasAdditionalPermissions: false,
            description: "",
            personIds: [],
            personData: [],
          },
          attendance: {
            isMandatory: false,
            isLimited: false,

            allowedAttendeesIds: [],
            allowedPersons: [],
            allowedUserGroups: [],
            allowedDivisions: [],
          },
          responses: {
            areBinding: false,
            areLocked: false,
            deadline: {
              isCustom: false,
              timestamp: null,
            },
          },
        },
        count: {
          totalResponses: 0,
          totalConfirmations: 0,
          totalCancellations: 0,
          totalIfNecessary: 0,
          totalGuests: 0,
        },
        status: "no data", // 'draft', 'planned', 'live', 'finished', 'canceled',
        statusAnalytics: {
          id: null,
          reason: null,
        },
        internalNote: "",
        meta: {
          id: "",
          createdAt: "",
          createdBy: "",
          lastUpdatedBy: "",
          lastUpdatedAt: "",
        },
      },

      reasonItems: [
        { text: "Zu wenig Teilnehmende", value: "c1" },
        { text: "Genug Teilnehmende, fehlende Qualifikationen", value: "c2" },
        { text: "Terminkollisionen", value: "c3" },
        { text: "Schlechte Wetterbedingungen", value: "c4" },
        { text: "Fehlende Technik/Geräte/Fahrzeuge", value: "c5" },
        {
          text: "Ruhebedingt (bspw. aufgrund vorheriger Abfrage)",
          value: "c6",
        },
        { text: "Sonstiges", value: "c0" },
      ],

      activeTab: `general`,
      tabs: [
        { id: 1, name: "Allgemein", route: `general` },
        { id: 2, name: "Rückmeldungen", route: `responses` },
      ],
      requestDeletionDialog: {
        model: false,
        loading: false,
        errorMessage: "",
      },
      resetResponsesDialog: {
        model: false,
        loading: false,
        errorMessage: "",
      },
      lockResponsesDialog: {
        model: false,
        loading: false,
        errorMessage: "",
      },
      statusDialog: {
        model: false,
      },
      formIsValid: false,
      helpers: {
        isLoading: false,
        snackbar: {
          isVisible: false,
          message: "",
        },
      },
    };
  },
  computed: {
    request() {
      return this.requestData;
    },
    actions() {
      let isRequestOrganizerWithPermission = false;

      if (
        this.request.config.organizer.isAppointed &&
        this.request.config.organizer.hasAdditionalPermissions &&
        this.request.config.organizer.personIds.includes(auth.currentUser.uid)
      ) {
        isRequestOrganizerWithPermission = true;
      } else {
        isRequestOrganizerWithPermission = false;
      }

      return [
        {
          title: "Bearbeiten",
          permission: `${SCHEDULING_EVENTS_update}`,
          customPermission: isRequestOrganizerWithPermission,
          icon: "mdi-pencil",
          actionStyle: "textBtn",
          function: this.editItem,
        },

        {
          title: "Aktionen",
          icon: "mdi-dots-horizontal-circle",
          actionStyle: "textBtnMultiple",
          list: [
            {
              title: "Status aktualisieren",
              permission: `${SCHEDULING_EVENTS_update}`,
              customPermission: isRequestOrganizerWithPermission,
              icon: "mdi-list-status",
              actionStyle: "textBtn",
              function: this.toggleRequestStatusDialog,
            },
            {
              title: "Löschen",
              permission: `${SCHEDULING_EVENTS_delete}`,
              icon: "mdi-delete",
              actionStyle: "textBtn",
              function: this.toggleRequestDeletionDialog,
            },
            { divider: true },
            {
              title: "Rückmeldungen sperren/öffnen",
              permission: `${SCHEDULING_EVENTS_RESPONSES_write}`,
              customPermission: isRequestOrganizerWithPermission,
              icon: "mdi-lock",
              disabled: false,
              function: this.toggleLockResponsesDialog,
            },
            {
              title: "Rückmeldungen zurücksetzen",
              permission: `${SCHEDULING_EVENTS_RESPONSES_write}`,
              customPermission: isRequestOrganizerWithPermission,
              icon: "mdi-restore",
              disabled: false,
              function: this.toggleResetResponsesDialog,
            },
            {
              title: "Einladung per E-Mail senden",
              permission: `${SCHEDULING_EVENTS_update}`,
              icon: "mdi-email",
              disabled: true,
              function: this.createItem,
            },
          ],
        },
        { divider: true },
        { actionStyle: "clipboardBtn" },
      ];
    },
    statusValues() {
      var values = [
        {
          icon: "mdi-calendar-check",
          value: "planned",
          status: "Geplant",
          description:
            "Die Abfrage erscheint in der Liste der bevorstehenden Abfragen.",
          color: "info",
        },
        {
          icon: "mdi-calendar-alert",
          value: "postponed",
          status: "Verschoben",
          description: "Die Abfrage wird zunächst im Archiv abgelegt.",
          color: "warning",
        },
        {
          icon: "mdi-calendar-remove",
          value: "canceled",
          status: "Abgesagt",
          description: "Die Abfrage wird im Archiv abgelegt.",
          color: "error",
        },
      ];
      if (this.checkIfSupport) {
        values.push({
          icon: "mdi-calendar-check",
          value: "finished",
          status: "Abgeschlossen",
          description: "Die Abfrage wird  im Archiv abgelegt.",
          color: "success",
        });
        return values;
      } else {
        return values;
      }
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    lockResponseDialogTitle() {
      if (this.requestData.config.responses.areLocked) {
        return "Rückmeldungen öffnen";
      } else {
        return "Rückmeldungen sperren";
      }
    },
    lockResponseDialogText() {
      if (this.requestData.config.responses.areLocked) {
        return "Bist Du sicher, dass Du alle bereits abgegebenen Rückmeldungen öffnen möchtest? Änderungen können wieder vorgenommen und neue Rückmeldungen abgegeben werden.";
      } else {
        return "Bist Du sicher, dass Du alle bereits abgegebenen Rückmeldungen sperren möchtest? Änderungen können nicht mehr vorgenommen und neue Rückmeldungen nicht mehr abgegeben werden.";
      }
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("requests")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.requestData = doc.data();
            this.requestData.timeSlots.start.timestampToDate = doc
              .data()
              .timeSlots.start.timestamp.toDate();
            this.requestData.timeSlots.end.timestampToDate = doc
              .data()
              .timeSlots.end.timestamp.toDate();
            this.requestData.userIsInvited = this.checkIfUserIsInvited(
              auth.currentUser.uid,
              this.$store.state.organization.membership.group.id,
              doc.data().config.attendance
            );

            // for each item in .timeSlots add 'style' : 'background: inherit !important'
            this.requestData.timeSlots.data.forEach((item) => {
              item.style = "background: inherit !important";
              item.start.timestampToReadableDate = this.timestampConverter(
                item.start.timestamp
              );
              item.end.timestampToReadableDate = this.timestampConverter(
                item.end.timestamp
              );
            });
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    checkIfUserIsInvited(uid, userGroupId, attendance) {
      if (attendance.isLimited) {
        return (
          attendance.allowedAttendeesIds.includes(uid) ||
          attendance.allowedAttendeesIds.includes(userGroupId) ||
          attendance.allowedAttendeesIds.includes("all")
        );
      } else {
        return true;
      }
    },
    editItem() {
      this.$router.push({
        name: "scheduling-requests-edit",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,
        },
      });
    },
    resetResponsesAction() {
      this.resetResponsesDialog.loading = true;
      // this.$store
      //   .dispatch(`${SCHEDULING}${RESET_RESPONSES}`, {
      //     organizationId: this.$route.params.organizationId,
      //     itemId: this.$route.params.itemId,
      //   })
      const callFunction = functions.httpsCallable(
        "availability-resetRequestResponses"
      );
      return callFunction({
        organizationId: this.$route.params.organizationId,
        requestId: this.$route.params.itemId,
      })
        .then((result) => {
          if (result.data.success) {
            // this.showSuccess = true;
            this.resetResponsesDialog.model = false;
            this.resetResponsesDialog.loading = false;
            this.helpers.snackbar.isVisible = true;
            this.helpers.snackbar.message = "Rückmeldungen zurückgesetzt.";
          } else {
            this.resetResponsesDialog.errorMessage = result.data.error.message;
            this.resetResponsesDialog.loading = false;
          }
        })
        .catch((error) => {
          // handle error
          // console.error(err.errorMsg);
          console.error(error);
          // this.errorMsg = err.message;
          this.resetResponsesDialog.errorMessage = error.message;
          this.resetResponsesDialog.loading = false;
        });
    },
    lockResponsesAction() {
      this.lockResponsesDialog.loading = true;
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("requests")
        .doc(this.$route.params.itemId)
        .update({
          "config.responses.areLocked":
            !this.requestData.config.responses.areLocked,
          "meta.lastUpdatedAt": FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": auth.currentUser.uid,
        })
        .then(() => {
          this.toggleLockResponsesDialog();
          this.requestData.config.responses.areLocked =
            !this.requestData.config.responses.areLocked;
          if (this.requestData.config.responses.areLocked) {
            this.helpers.snackbar.message = "Rückmeldungen gesperrt";
          } else {
            this.helpers.snackbar.message = "Rückmeldungen geöffnet";
          }
          this.lockResponsesDialog.loading = false;
          this.helpers.snackbar.isVisible = true;
        })
        .catch((error) => console.log(error));
    },
    deleteRequestAction() {
      // alert("Fehler: Kontaktieren Sie den Support.");
      this.requestDeletionDialog.loading = true;
      this.$store
        .dispatch(`${SCHEDULING}${DELETE_EVENT}`, {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,
        })
        .then((result) => {
          if (result.data.success) {
            // this.showSuccess = true;
            this.requestDeletionDialog.model = false;
            this.requestDeletionDialog.loading = false;
            // this.snackbar = true;
            this.$router.push({
              name: "scheduling-requests",
              params: { organizationId: this.$route.params.organizationId },
            });
          } else {
            // this.errorMsg = "Fehler";
            this.requestDeletionDialog.loading = false;
          }
        })
        .catch((err) => {
          // handle error
          // console.error(err.errorMsg);
          console.error(err);
          this.requestDeletionDialog.errorMessage = err.message;
          this.requestDeletionDialog.loading = false;
        });
    },
    updateRequestStatus() {
      if (this.formIsValid) {
        this.helpers.isLoading = true;
        if (this.requestData.status !== "canceled") {
          this.requestData.statusAnalytics.id = null;
          this.requestData.statusAnalytics.reason === null;
        }
        this.$store
          .dispatch(`${SCHEDULING}${UPDATE_REQUEST_META}`, {
            organizationId: this.$route.params.organizationId,
            itemId: this.$route.params.itemId,

            status: this.requestData.status,
            statusAnalytics: {
              id: this.requestData.statusAnalytics.id,
              reason: this.requestData.statusAnalytics.reason,
            },
            internalNote: this.requestData.internalNote,
          })
          .then(() => {
            this.toggleRequestStatusDialog();
            this.helpers.isLoading = false;
            this.helpers.snackbar.message = "Neuer Abfragestatus gespeichert.";
            this.helpers.snackbar.isVisible = true;
          })
          .catch((error) => {
            this.helpers.snackbar.message = error;
            this.helpers.snackbar.isVisible = true;
          });
      } else {
        alert("Prüfe Deine Eingabe und versuchen es erneut.");
      }
    },
    toggleRequestDeletionDialog() {
      this.requestDeletionDialog.model = !this.requestDeletionDialog.model;
    },
    toggleResetResponsesDialog() {
      this.resetResponsesDialog.model = !this.resetResponsesDialog.model;
      this.resetResponsesDialog.errorMessage = "";
    },
    toggleLockResponsesDialog() {
      this.lockResponsesDialog.model = !this.lockResponsesDialog.model;
    },
    toggleRequestStatusDialog() {
      this.statusDialog.model = !this.statusDialog.model;
    },
    timestampConverter(timestamp) {
      const options = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };

      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
  },
};
</script>

<style></style>
