export const importableDivisions = [
  {
    title: "Einsatzabteilung",
    shortTitle: "EA",
    isActive: true,
    sortKey: 1,
    isImported: true,
    importVersion: 1,
    importId: "ea",
  },
  {
    title: "Rückwärtiger Dienst",
    shortTitle: "RD",
    isActive: true,
    sortKey: 2,
    isImported: true,
    importVersion: 1,
    importId: "rd",
  },
  {
    title: "Jugendfeuerwehr",
    shortTitle: "JF",
    isActive: true,
    sortKey: 3,
    isImported: true,
    importVersion: 1,
    importId: "jf",
  },
  {
    title: "Alters- und Ehrenabteilung",
    shortTitle: "AuE",
    isActive: true,
    sortKey: 4,
    isImported: true,
    importVersion: 1,
    importId: "aue",
  },
  {
    title: "Umsetzende/Neubewerbende/Anwärtende",
    shortTitle: "UNA",
    isActive: true,
    sortKey: 5,
    isImported: true,
    importVersion: 1,
    importId: "una",
  },
];
