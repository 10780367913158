<template>
  <div>
    <NavigationBar
      titel="Konfiguration"
      :tabs="tabs"
      :activeTab="activeTab"
    ></NavigationBar>
    <router-view />
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";

export default {
  name: "member-management-config",
  components: {
    NavigationBar,
  },
  data() {
    return {
      activeTab: `config/custom-fields`,
      tabs: [
        { id: 1, name: "Qualifikationen", route: `skills` },
        { id: 2, name: "Lehrgänge", route: `courses` },
        { id: 2, name: "Untersuchungen", route: `physicals`, disabled: true },
        { id: 4, name: "Dienstgrade", route: `ranks` },
        { id: 5, name: "Dienststellungen", route: `duty-positions` },
        { id: 6, name: "Ehrungen", route: `honors`, disabled: true },
        { id: 7, name: "Abteilungen", route: `divisions` },
        {
          id: 9,
          name: "Benutzerdefinierte Felder",
          disabled: true,
          route: `custom-fields`,
        },
      ],
    };
  },
};
</script>
