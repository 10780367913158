<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="invites"
      item-key="meta.id"
      multi-sort
      sort-by="meta.createdAt"
      class="elevation-2"
      no-data-text="Keine Einladungen vorhanden"
      max-height="800px auto"
      @click:row="viewInvite"
    >
      <template v-slot:[`item.email`]="{ item }">
        <pre>{{ item.email }}</pre>
      </template>
      <template v-slot:[`item.invite.status`]="{ item }">
        <invite-status-chip
          :inviteStatus="item.invite.status"
          small
        ></invite-status-chip>
      </template>
      <template v-slot:[`item.invite.type`]="{ item }">
        <v-icon>{{ iconFromInviteType(item.invite.type) }}</v-icon>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon color="red darken-1" @click="viewInvite(item)">
          mdi-chevron-right
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import InviteStatusChip from "@/components/admin/users/accounts/InviteStatusChip.vue";
export default {
  name: "invites-table",
  props: {
    invites: {
      type: Array,
      required: true,
    },
  },
  components: { InviteStatusChip },
  data() {
    return {
      headers: [
        { text: "E-Mail-Adresse", value: "email" },
        { text: "Benutzergruppe", value: "group.title" },
        { text: "Status", value: "invite.status" },
        { text: "Typ", value: "invite.type" },
        { text: "Einladungszeitpunkt", value: "meta.createdAt" },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },
  created() {},
  computed: {},
  methods: {
    iconFromInviteType(item) {
      switch (item) {
        case "mail":
          return "mdi-email";
        case "link":
          return "mdi-link";
        default:
          return "";
      }
    },
    viewInvite(item) {
      this.$router.push({
        name: "admin-users-tab-accounts-invite-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item.meta.id,
        },
      });
    },
  },
};
</script>
