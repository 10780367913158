<template>
  <div>
    <section>
      <TippCard hintID="Mitgliederverwaltung_BenFelder_1_V1" text="">
      </TippCard>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-form-textarea"
                title="Benutzerdefinierte Felder"
                :actions="actions"
              ></Subheader>
              <div class="mt-7">
                <v-data-table
                  :headers="customFieldHeaders"
                  :items="customFields"
                  group-by="groupByValue"
                  sort-by="sortKey"
                  :sort-desc="false"
                  class="elevation-2"
                  no-data-text="Keine benutzerdefinierten Felder vorhanden"
                  @click:row="openCustomField"
                >
                  <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
                    <th :colspan="skillHeaders.length" class="group-header-th">
                      <v-icon @click="toggle" class="mr-2"
                        >{{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
                      </v-icon>
                      <span>
                        {{ stripPrefix(items[0].groupByValue) }}
                      </span>
                    </th>
                  </template>
                </v-data-table>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <!-- <section>
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="12">
            <v-alert
              type="warning"
              border="left"
              colored-border
              icon="mdi-alert"
              elevation="2"
              class="mt-6"
            >
              <div class="title">Hinweis</div>
              <div class="mt-2">Diese Funktion ist noch nicht verfügbar.</div>
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </section> -->
  </div>
</template>

<script>
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import { MEMBER_MANAGEMENT_CONFIG_CUSTOM_FIELDS_create } from "@/data/permission-types.js";
export default {
  name: "member-management-config-custom-fields",
  components: {
    TippCard,
    Subheader,
  },
  data() {
    return {
      actions: [
        {
          title: "Erstellen",
          icon: "mdi-plus",
          permission: `${MEMBER_MANAGEMENT_CONFIG_CUSTOM_FIELDS_create}`,
          actionStyle: "textBtn",
          function: this.createItem,
        },
      ],

      customFieldHeaders: [
        {
          text: "ID",
          value: "id",
          sortable: false,
        },
        {
          text: "Bezeichnung",
          value: "label",
          sortable: false,
        },
        {
          text: "Typ",
          value: "type",
        },
        {
          text: "Kategorie",
          value: "category",
        },
        { text: "Bezeichnung", value: "title", sortable: false },
      ],
    };
  },
  methods: {
    createItem() {
      this.$router.push({
        name: "member-management-config-custom-fields-new",
        params: {
          organizationId: this.$route.params.organizationId,
        },
      });
    },
  },
};
</script>
