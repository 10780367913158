<template>
  <div>
    <section>
      <TippCard
        hintID="Admin_Konfiguration_Tab_Qualifikationen_1_V1"
        text="Qualifikationen sind die notwendigen Kenntnisse und Fähigkeiten, über die ein Mitglied verfügen mus, um die Anforderungen einer Funktion zu erfüllen. In der Praxis werden Qualifikationen meist im Rahmen von Lehrgängen, Schulungen und Fortbildungen erworben. Auf diese Weise kann ermittelt werden, welche Einsatzmittel der Organisation zur Verfügung stehen."
      >
      </TippCard>
    </section>
    <v-snackbar v-model="helpers.snackbar.model" timeout="5000">{{
      helpers.snackbar.text
    }}</v-snackbar>
    <section>
      <template>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="6">
              <Subheader
                icon="mdi-folder-multiple"
                title="Qualifikationsgruppen"
                :actions="skillGroupActions"
              ></Subheader>
              <div class="mt-7">
                <v-data-table
                  :headers="skillGroupHeaders"
                  :items="skillGroups"
                  sort-by="sortKey"
                  :sort-desc="false"
                  class="elevation-2"
                  no-data-text="Keine Qualifikationsgruppen vorhanden"
                  @click:row="openSkillGroup"
                >
                  <template v-slot:[`item.icon`]="{ item }">
                    <data-avatar
                      :icon="item.icon"
                      :color="item.color"
                      small
                    ></data-avatar>
                  </template>
                </v-data-table>
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <Subheader icon="mdi-cogs" title="Einstellungen"></Subheader>
              <div class="mt-7">
                <v-card>
                  <v-card-text>
                    <v-container>
                      <div class="subtitle-1">
                        Diese Qualifikationsgruppe wird zur besonderen
                        Hervorhebung verwendet. Insbesondere erscheinen die
                        Grafiken prominent im Personenprofil und Organigramm.
                      </div>
                      <v-form
                        v-model="helpers.skillConfig.formIsValid"
                        @submit.prevent="updateSkillConfig"
                      >
                        <v-row>
                          <v-col cols="12">
                            <v-select
                              v-model="
                                skillConfig.badgeDisplay.primarySkillGroupId
                              "
                              :items="skillGroups"
                              item-text="title"
                              item-value="meta.id"
                              required
                              :rules="[rules.required]"
                              outlined
                              label="Primäre Qualifikationsgruppe auswählen"
                              class="mt-4"
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-form>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          depressed
                          color="success"
                          :loading="helpers.skillConfig.loading"
                          :disabled="!helpers.skillConfig.formIsValid"
                          @click="updateSkillConfig()"
                        >
                          <v-icon left> mdi-content-save </v-icon>
                          Speichern
                        </v-btn>
                      </v-card-actions>
                    </v-container>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
            <v-col cols="12">
              <Subheader
                icon="mdi-label"
                title="Qualifikationen"
                :actions="skillActions"
              ></Subheader>
              <div class="mt-7">
                <v-data-table
                  :headers="skillHeaders"
                  :items="skills"
                  group-by="groupByValue"
                  sort-by="sortKey"
                  :sort-desc="false"
                  class="elevation-2"
                  no-data-text="Keine Qualifikationen vorhanden"
                  @click:row="openSkill"
                  :items-per-page="15"
                  :footer-props="footerProps"
                >
                  <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
                    <th :colspan="skillHeaders.length" class="group-header-th">
                      <v-icon @click="toggle" class="mr-2"
                        >{{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
                      </v-icon>
                      <span>
                        {{ stripPrefix(items[0].groupByValue) }}
                      </span>
                    </th>
                  </template>
                  <template v-slot:[`item.badgeUrl`]="{ item }">
                    <img
                      v-if="item.badgeUrl.size200x200"
                      :src="item.badgeUrl.size200x200"
                      contain
                      height="60px"
                      class="pa-2 justify-center align-center"
                    />
                    <v-chip v-else label small
                      ><v-icon small>mdi-help</v-icon></v-chip
                    >
                  </template>
                </v-data-table>
              </div>
            </v-col>
            <!-- <v-col cols="12">
              <Subheader
                icon="mdi-label"
                title="Qualifikationsübersicht"
              ></Subheader>
              <div class="mt-7">
                <v-data-table
                  sort-by="user.displayName"
                  :sort-desc="false"
                  class="elevation-2"
                >
                </v-data-table>
              </div>
            </v-col> -->
          </v-row>
        </v-container>
      </template>
    </section>
    <custom-dialog
      v-if="helpers.dataImportSkillGroups.dialogModel"
      @close="toggleSkillGroupsImportDialog()"
      title="Qualifikationsgruppen importieren"
      text="Importierte Qualifikationsgruppen stehen nach dem Import sofort zur Verfügung."
      width="m"
      :errorMessage="helpers.dataImportSkillGroups.errorMessage"
      :btnAction="startDataImportSkillGroups"
      :btnDisabled="
        helpers.dataImportSkillGroups.selectedImportTemplates.length === 0
      "
      :loading="helpers.dataImportSkillGroups.loading"
      btnText="Auswahl importieren"
    >
      <template v-slot:content>
        <v-form
          v-model="helpers.dataImportSkillGroups.formIsValid"
          @submit.prevent="startDataImportSkillGroups()"
        >
          <v-card outlined class="mt-2">
            <v-data-table
              v-model="helpers.dataImportSkillGroups.selectedImportTemplates"
              :items="helpers.dataImportSkillGroups.importTemplates"
              :headers="helpers.dataImportSkillGroups.tableHeaders"
              :search="helpers.dataImportSkillGroups.search"
              item-key="title"
              show-select
              :sort-by="helpers.dataImportSkillGroups.sortSelector"
              :sort-desc="false"
              checkbox-color="primary"
              disable-pagination
              hide-default-footer
              mobile-breakpoint="0"
              fixed-header
              height="35vh"
              class="mt-1"
              no-results-text="Keine Ergebnisse für Suchbegriff"
            >
              <template v-slot:top>
                <v-card flat>
                  <v-card-subtitle>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="helpers.dataImportSkillGroups.search"
                          label="Nach Qualifikationsgruppen suchen"
                          prepend-inner-icon="mdi-magnify"
                          dense
                          hide-details="auto"
                          outlined
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <span class="mr-4">Sortieren nach</span>
                        <v-chip-group
                          v-model="helpers.dataImportSkillGroups.sortSelector"
                          mandatory
                          active-class="primary--text"
                        >
                          <v-chip
                            label
                            value="sortKey"
                            class="transparent-background font-weight-medium mr-2 mb-3"
                            ><v-icon left small>mdi-sort-numeric-variant</v-icon
                            >Sortierschlüssel
                          </v-chip>
                          <v-chip
                            label
                            value="title"
                            class="transparent-background font-weight-medium mb-3"
                            ><v-icon left small
                              >mdi-sort-alphabetical-variant</v-icon
                            >Alphabetisch</v-chip
                          >
                        </v-chip-group>
                      </v-col>
                    </v-row>
                  </v-card-subtitle>
                </v-card>
                <v-divider></v-divider>
              </template>
              <template v-slot:[`item.title`]="{ item }">
                <div class="d-flex flex-column">
                  <span
                    class="d-block font-weight-semibold text--primary text-truncate"
                    >{{ item.title }}</span
                  >
                  <!-- <small class="grey--text">{{ item.description }}</small> -->
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-form>
      </template>
    </custom-dialog>
    <custom-dialog
      v-if="helpers.dataImportSkills.dialogModel"
      @close="toggleSkillsImportDialog()"
      title="Qualifikationen importieren"
      text="Importierte Qualifikationen stehen nach dem Import sofort zur Verfügung."
      width="m"
      :errorMessage="helpers.dataImportSkills.errorMessage"
      :btnAction="startDataImportSkills"
      :btnDisabled="
        helpers.dataImportSkills.selectedImportTemplates.length === 0
      "
      :loading="helpers.dataImportSkills.loading"
      btnText="Auswahl importieren"
    >
      <template v-slot:content>
        <v-form
          v-model="helpers.dataImportSkills.formIsValid"
          @submit.prevent="startDataImportSkills()"
        >
          <v-select
            v-model="helpers.dataImportSkills.selectedSkillGroup"
            :items="skillGroups"
            item-text="title"
            outlined
            return-object
            label="Qualifikationsgruppe für Import auswählen"
            hint="Die ausgewählte Qualifikationsgruppe wird für alle zu importierenden Qualifikationen verwendet."
            persistent-hint
            dense
            class="my-2"
            :rules="[rules.required]"
          ></v-select>

          <v-card outlined class="mt-2">
            <v-data-table
              v-model="helpers.dataImportSkills.selectedImportTemplates"
              :items="helpers.dataImportSkills.importTemplates"
              :headers="helpers.dataImportSkills.tableHeaders"
              :search="helpers.dataImportSkills.search"
              item-key="title"
              show-select
              :sort-by="helpers.dataImportSkills.sortSelector"
              :sort-desc="false"
              checkbox-color="primary"
              disable-pagination
              hide-default-footer
              mobile-breakpoint="0"
              fixed-header
              height="35vh"
              class="mt-1"
              no-results-text="Keine Ergebnisse für Suchbegriff"
            >
              <template v-slot:top>
                <v-card flat>
                  <v-card-subtitle>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="helpers.dataImportSkills.search"
                          label="Nach Qualifikationen suchen"
                          prepend-inner-icon="mdi-magnify"
                          dense
                          hide-details="auto"
                          outlined
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <span class="mr-4">Sortieren nach</span>
                        <v-chip-group
                          v-model="helpers.dataImportSkills.sortSelector"
                          mandatory
                          active-class="primary--text"
                        >
                          <v-chip
                            label
                            value="sortKey"
                            class="transparent-background font-weight-medium mr-2 mb-3"
                            ><v-icon left small>mdi-sort-numeric-variant</v-icon
                            >Sortierschlüssel
                          </v-chip>
                          <v-chip
                            label
                            value="title"
                            class="transparent-background font-weight-medium mb-3"
                            ><v-icon left small
                              >mdi-sort-alphabetical-variant</v-icon
                            >Alphabetisch</v-chip
                          >
                        </v-chip-group>
                      </v-col>
                    </v-row>
                  </v-card-subtitle>
                </v-card>
                <v-divider></v-divider>
              </template>
              <template v-slot:[`item.title`]="{ item }">
                <div class="d-flex flex-column">
                  <span
                    class="d-block font-weight-semibold text--primary text-truncate"
                    >{{ item.title }}</span
                  >
                  <!-- <small class="grey--text">{{ item.description }}</small> -->
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-form>
      </template>
    </custom-dialog>
  </div>
</template>

<script>
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import {
  GET_SKILL_GROUPS,
  GET_SKILLS,
  CREATE_SKILLS,
  CREATE_SKILL_GROUPS,
  UPDATE_MEMBER_MANAGEMENT_CONFIG,
} from "@/store/action-types.js";
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import DataAvatar from "@/components/_systemwide/DataAvatar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import { importableSkillGroups } from "@/data/import/importable-skillGroups.js";
import { importableSkills } from "@/data/import/importable-skills.js";
import { MEMBER_MANAGEMENT_CONFIG_SKILLS_create } from "@/data/permission-types.js";

export default {
  name: "member-management-config-skills",
  components: {
    TippCard,
    DataAvatar,
    CustomDialog,
    Subheader,
  },
  data() {
    return {
      footerProps: {
        "items-per-page-options": [10, 15, 25, -1],
      },

      skillGroupActions: [
        {
          title: "Erstellen",
          icon: "mdi-plus-circle",
          permission: `${MEMBER_MANAGEMENT_CONFIG_SKILLS_create}`,
          actionStyle: "textBtn",
          function: () => {
            this.$router.push({
              name: "member-management-config-skills-skill-group-new",
            });
          },
        },
        {
          title: "Importieren",
          icon: "mdi-import",
          permission: `${MEMBER_MANAGEMENT_CONFIG_SKILLS_create}`,
          actionStyle: "textBtn",
          function: this.toggleSkillGroupsImportDialog,
        },
      ],
      skillActions: [
        {
          title: "Erstellen",
          icon: "mdi-plus-circle",
          permission: `${MEMBER_MANAGEMENT_CONFIG_SKILLS_create}`,
          actionStyle: "textBtn",
          function: () => {
            this.$router.push({
              name: "member-management-config-skills-skill-new",
            });
          },
        },
        {
          title: "Importieren",
          icon: "mdi-import",
          permission: `${MEMBER_MANAGEMENT_CONFIG_SKILLS_create}`,
          actionStyle: "textBtn",
          function: this.toggleSkillsImportDialog,
        },
      ],

      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },

      skillGroupHeaders: [
        {
          text: "",
          value: "icon",
          sortable: false,
          width: "1%",
        },
        {
          text: "Bezeichnung",
          value: "title",
        },
        {
          text: "Sortierschlüssel",
          value: "sortKey",
        },
      ],
      skillHeaders: [
        { text: "Grafik", value: "badgeUrl", sortable: false },
        {
          text: "Kurzbezeichnung",
          value: "shortTitle",
          sortable: false,
        },
        { text: "Bezeichnung", value: "title" },
        { text: "Sortierschlüssel", value: "sortKey" },
        { text: "Fristenablauf", value: "deadline.type" },
        { text: "Gültigkeit in Tagen", value: "deadline.defaultExpiryInDays" },
      ],

      helpers: {
        skillConfig: {
          loading: false,
          formIsValid: false,
          errorMessage: "",
        },
        dataImportSkillGroups: {
          dialogModel: false,
          loading: false,
          formIsValid: false,
          importTemplates: importableSkillGroups,
          sortSelector: "sortKey",
          errorMessage: "",
          search: "",
          selectedImportTemplates: [],
          tableHeaders: [
            {
              text: "Titel",
              value: "title",
              align: "start",
            },
          ],
        },
        dataImportSkills: {
          dialogModel: false,
          loading: false,
          formIsValid: false,
          importTemplates: importableSkills,
          sortSelector: "priority",
          errorMessage: "",
          search: "",
          selectedImportTemplates: [],
          selectedSkillGroup: null,
          tableHeaders: [
            {
              text: "Titel",
              value: "title",
              align: "start",
            },
          ],
        },
        snackbar: { model: false, text: "" },
      },
    };
  },
  computed: {
    skillConfig() {
      return this.$store.state.organization.activeOrganization.config
        .memberManagement;
    },
    skills() {
      const data = this.$store.state.memberManagement.skills;
      const result = data.map((item) => {
        const container = {
          ...item,
          badgeUrl: {
            size200x200: item.badgeUrl.size200x200, //TODO
            size400x400: item.badgeUrl.size400x400, //TODO
          },
          groupByValue:
            item.group.sortKey.toString().padStart(4, "0") +
            "_" +
            item.group.title,
          // analysis: {
          //   countTotalAssigned: item.personsWithSkill.uids.length,
          //   // status: "Defizit",
          // },
        };
        return container;
      });
      return result;
    },
    skillGroups() {
      return this.$store.state.memberManagement.skillGroups;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_SKILL_GROUPS}`, {
        organizationId: this.$route.params.organizationId,
      });
      this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_SKILLS}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    openSkillGroup(item) {
      this.$router.push({
        name: "member-management-config-skills-skill-group-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item.meta.id,
        },
      });
    },
    openSkill(item) {
      this.$router.push({
        name: "member-management-config-skills-skill-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item.meta.id,
        },
      });
    },
    stripPrefix(item) {
      return item.substring(item.indexOf("_") + 1);
    },
    toggleSkillsImportDialog() {
      this.helpers.dataImportSkills.dialogModel =
        !this.helpers.dataImportSkills.dialogModel;
    },
    toggleSkillGroupsImportDialog() {
      this.helpers.dataImportSkillGroups.dialogModel =
        !this.helpers.dataImportSkillGroups.dialogModel;
    },
    updateSkillConfig() {
      this.helpers.skillConfig.loading = true;
      if (this.helpers.skillConfig.formIsValid) {
        this.$store
          .dispatch(`${MEMBER_MANAGEMENT}${UPDATE_MEMBER_MANAGEMENT_CONFIG}`, {
            organizationId: this.$route.params.organizationId,
            type: "onlySkillsConfig",

            badgeDisplay: {
              primarySkillGroupId:
                this.skillConfig.badgeDisplay.primarySkillGroupId,
            },
          })
          .then(() => {
            this.helpers.skillConfig.loading = false;
            this.helpers.snackbar.model = true;
            this.helpers.snackbar.text = "Erfolgreich aktualisiert.";
          })
          .catch((error) => {
            console.log(error);
            this.helpers.skillConfig.errorMessage = error.message;
            this.helpers.skillConfig.loading = false;
          });
      } else {
        alert("Prüfe Deine Eingabe und versuche es erneut.");
      }
    },
    startDataImportSkills() {
      if (this.helpers.dataImportSkills.selectedImportTemplates.length > 0) {
        this.helpers.dataImportSkills.loading = true;

        // add group to all skillsToImport
        const skillsToImport =
          this.helpers.dataImportSkills.selectedImportTemplates.map((item) => {
            return {
              ...item,
              group: {
                title: this.helpers.dataImportSkills.selectedSkillGroup.title,
                color: this.helpers.dataImportSkills.selectedSkillGroup.color,
                sortKey:
                  this.helpers.dataImportSkills.selectedSkillGroup.sortKey,
                isHiddenInProfile:
                  this.helpers.dataImportSkills.selectedSkillGroup
                    .isHiddenInProfile,
                skillsAreEqual:
                  this.helpers.dataImportSkills.selectedSkillGroup
                    .skillsAreEqual,
                icon: this.helpers.dataImportSkills.selectedSkillGroup.icon,
                id: this.helpers.dataImportSkills.selectedSkillGroup.meta.id,
              },
            };
          });

        this.$store
          .dispatch(`${MEMBER_MANAGEMENT}${CREATE_SKILLS}`, {
            organizationId: this.$route.params.organizationId,

            skills: skillsToImport,
          })
          .then(() => {
            return this.initialize();
          })
          .then(() => {
            this.toggleSkillsImportDialog();
            this.helpers.dataImportSkills.loading = false;
            this.helpers.dataImportSkills.selectedImportTemplates = [];
            this.helpers.dataImportSkills.search = "";
            this.helpers.snackbar.model = true;
            this.helpers.snackbar.text = "Import erfolgreich";
          })
          .catch((error) => {
            this.helpers.dataImportSkills.loading = false;
            console.log(error);
            this.helpers.dataImportSkills.errorMessage = error.message;
          });
      } else {
        alert(
          "Wähle mindestens eine Veranstaltungsart aus, um den Import duchrzuführen."
        );
      }
    },
    startDataImportSkillGroups() {
      if (
        this.helpers.dataImportSkillGroups.selectedImportTemplates.length > 0
      ) {
        this.helpers.dataImportSkillGroups.loading = true;
        this.$store
          .dispatch(`${MEMBER_MANAGEMENT}${CREATE_SKILL_GROUPS}`, {
            organizationId: this.$route.params.organizationId,

            skillGroups:
              this.helpers.dataImportSkillGroups.selectedImportTemplates,
          })
          .then(() => {
            return this.initialize();
          })
          .then(() => {
            this.toggleSkillGroupsImportDialog();
            this.helpers.dataImportSkillGroups.loading = false;
            this.helpers.dataImportSkillGroups.selectedImportTemplates = [];
            this.helpers.dataImportSkillGroups.search = "";
            this.helpers.snackbar.model = true;
            this.helpers.snackbar.text = "Import erfolgreich";
          })
          .catch((error) => {
            this.helpers.dataImportSkillGroups.loading = false;
            console.log(error);
            this.helpers.dataImportSkillGroups.errorMessage = error.message;
          });
      } else {
        alert(
          "Wähle mindestens eine Veranstaltungsart aus, um den Import duchrzuführen."
        );
      }
    },
  },
};
</script>

<style>
.group-header-th {
  height: 32px !important;
}
</style>
