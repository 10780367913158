<template>
  <div>
    <NavigationBar titel="Konfiguration" :tabs="tabs"></NavigationBar>
    <router-view />
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
export default {
  name: "accounting-config",
  components: {
    NavigationBar,
  },
  data() {
    return {
      activeTab: `config/general`,
      tabs: [
        { id: 1, name: "Allgemein", route: `general` },
        { id: 2, name: "Abrechnungsarten", route: `payroll-types` },
        {
          id: 3,
          name: "Genehmigungsprozess",
          route: `approval-process`,
        },
        { id: 4, name: "Blocker", route: `restrictions`, disabled: true },
      ],
    };
  },
};
</script>
