<template>
  <div>
    <v-card outlined>
      <v-card-text class="text-left px-2 py-1">
        <v-icon small left class="mr-1">mdi-check-circle</v-icon
        ><small>{{ count.totalPresent }}</small>
        <br />
        <v-icon small left class="mr-1">mdi-close-circle</v-icon
        ><small>{{ count.totalExcused }}</small>
        <br />
        <v-icon small left class="mr-1">mdi-close-octagon</v-icon
        ><small>{{ count.totalUnexcused }}</small>
        <br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "attendance-records-responses-summary-card",
  components: {},
  props: {
    count: {
      type: Object,
      required: true,
    },
  },
  methods: {},
};
</script>
