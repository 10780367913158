<template>
  <div>
    <v-card class="">
      <v-row no-gutters dense>
        <v-col cols="auto">
          <img
            v-if="member.user.photoUrl.size125x125"
            contain
            :src="member.user.photoUrl.size125x125"
            width="80"
            class="mr-2 align-center"
          />
          <v-icon v-else size="40" class="align-center ma-5"
            >mdi-account-circle</v-icon
          >
          <!-- <v-img
            contain
            width="70"
            class="ma-2"
            src="https://storage.googleapis.com/mira-one.appspot.com/organizations/hyeCwR4qRmw4LIifDSwn/skills/A4KzXvV7rghFWWJEcDsZ/skill_400x400.png?GoogleAccessId=mira-one%40appspot.gserviceaccount.com&Expires=4102444800&Signature=DdQROB0f6%2FnCXv8X79A09mZ9cxUt0nAaWa4N5zzo0N6AL1dqareWuHoaXlpy5zesXDwZDNOVEpY0VrJAietyDXFACYosSpcBgD2Zv5Tqom5cFsSuKuLPt7eOYb970OrzIjF53zwff0z6VknOuFKJBJTFFOFjYcKwXoboU1LGtf%2B8hEkLE7sM1AOcyMr%2BiYr0mkHyKJ3fIs6n3yjnHm6I3n5jLfK5lcRB3TqR%2BZPniT5OYa5hql%2BjvkBw2UjggEbcEWsL1EE2fS5cO4liM7mArySogNv5UwBte3Pdo%2BhAWJ5Gp%2Flfbgj3n9mT6wXUbjiQgO5DRwkWQC2dJAJjaO1IEw%3D%3D"
        /> -->
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="auto">
          <v-card-title
            class="py-2 subtitle-1 font-weight-medium"
            style="word-break: break-word"
          >
            {{ member.user.displayName }}
            <!-- <v-btn icon color="primary" small class="ml-1"
              ><v-icon small>mdi-open-in-app</v-icon></v-btn
            > -->
          </v-card-title>
          <v-card-subtitle
            v-if="member.user.dutyPosition.title || member.user.rank.title"
            class="py-3"
          >
            <v-chip outlined label v-if="member.user.rank.title" class="mr-2">
              <img
                v-if="member.user.rank.badgeUrl.size125x125"
                :src="member.user.rank.badgeUrl.size125x125"
                contain
                height="20px"
                class="mr-2"
              />
              <span class="font-weight-medium">{{
                member.user.rank.shortTitle
              }}</span>
            </v-chip>
            <v-chip outlined label v-if="member.user.dutyPosition.title">
              <img
                v-if="member.user.dutyPosition.badgeUrl.size125x125"
                :src="member.user.dutyPosition.badgeUrl.size125x125"
                contain
                height="20px"
                class="mr-2"
              />
              <span class="font-weight-medium">{{
                member.user.dutyPosition.shortTitle
              }}</span>
            </v-chip>
          </v-card-subtitle>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-card-text class="pb-1">
        <div class="text-caption font-weight-medium mt-n2 mb-1">
          Qualifikationen
        </div>
        <v-row class="match-height" dense>
          <v-col>
            <div v-if="!member.relevantSkills.data.length" class="mb-1">
              Keine Qualifikationen zugewiesen
            </div>

            <data-chip
              v-for="(skill, index) in member.relevantSkills.data"
              :key="index"
              :color="skill.group.color"
              :iconLeft="skill.group.icon"
              :text="skill.shortTitle"
              class="mr-2 mb-2"
              small
            >
            </data-chip>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider v-if="checkAssignmentsAvailability"></v-divider>
      <v-card-text v-if="checkAssignmentsAvailability" class="pb-1">
        <div class="text-caption font-weight-medium mt-n2 mb-1">
          Aufgabenbereiche
        </div>
        <div v-if="!member.assignments.ids.length" class="mb-2">
          Keine Aufgabenbereiche zugewiesen
        </div>
        <v-chip
          v-else
          v-for="(assignment, index) in member.assignments.data"
          :key="index"
          small
          label
          :color="`${getMaterialColor(assignment.color)} lighten-5`"
          class="font-weight-medium text--darken-4 mr-2 mb-2"
          :class="getTextStyle(assignment.color)"
          ><v-icon small class="mr-2" :color="assignment.color"
            >mdi-{{ assignment.icon }}</v-icon
          >{{ assignment.title }}</v-chip
        >
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "personnel-record-detail-card",
  props: {
    member: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showAssignments: true,
      showSkills: true,
    };
  },
  computed: {
    checkAssignmentsAvailability() {
      return this.$store.getters["organization/checkExtensionAvailabilityById"](
        "memberManagement.assignments"
      );
    },
    checkSkillsAvailability() {
      return this.$store.getters["organization/checkExtensionAvailabilityById"](
        "memberManagement.skills"
      );
    },
    memberManagementConfig() {
      return this.$store.state.organization.activeOrganization.config
        .memberManagement;
    },
    primaryBadgeUrl() {
      const primarySkillGroupId =
        this.memberManagementConfig.badgeDisplay.primarySkillGroupId || "";
      if (primarySkillGroupId) {
        const skillsOfPrimaryGroup = this.member.relevantSkills.data.filter(
          (skill) => skill.group.id === primarySkillGroupId
        );

        if (skillsOfPrimaryGroup.length === 0) {
          return "";
        }

        const highestPrimarySkill = skillsOfPrimaryGroup.reduce(
          (prev, current) => (prev.sortKey > current.sortKey ? prev : current),
          skillsOfPrimaryGroup[0]
        );

        return highestPrimarySkill.badgeUrl.size200x200;
      } else {
        return "";
      }
    },
  },
  methods: {
    getTextStyle(color) {
      return this.getMaterialColor(color) + "--text";
    },
    getMaterialColor(hexColor) {
      switch (hexColor) {
        case "#F44336FF":
          return "red";
        case "#E91E63FF":
          return "pink";
        case "#9C27B0FF":
          return "purple";
        case "#673AB7FF":
          return "deep-purple";
        case "#3F51B5FF":
          return "indigo";
        case "#2196F3FF":
          return "blue";
        case "#03A9F4FF":
          return "light-blue";
        case "#00BCD4FF":
          return "cyan";
        case "#009688FF":
          return "teal";
        case "#4CAF50FF":
          return "green";
        case "#8BC34AFF":
          return "light-green";
        case "#CDDC39FF":
          return "lime";
        case "#FFEB3BFF":
          return "yellow";
        case "#FFC107FF":
          return "amber";
        case "#FF9800FF":
          return "orange";
        case "#FF5722FF":
          return "deep-orange";
        case "#795548FF":
          return "brown";
        case "#607D8BFF":
          return "blue-grey";
        case "#9E9E9EFF":
          return "grey";
        default:
          return "grey";
      }
    },
  },
};
</script>
