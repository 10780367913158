<template>
  <div>
    <Subheader icon="mdi-cog" title="Einstellungen"></Subheader>
    <div class="mt-7">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-checkbox
                  v-model="config.attendance.isLimited"
                  label="Teilnahme an Veranstaltung beschränken"
                  persistent-hint
                  hint="Standardmäßig können alle Mitglieder der Organisation an der Veranstaltung teilnehmen und eine Rückmeldung abgeben. Die Abgabe von Rückmeldungen und Teilnahme an der Veranstaltung kann die Teilnahme jedoch auf einzelne Personen/Teams/Aufgabenbereiche beschränkt werden."
                ></v-checkbox>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                v-if="config.attendance.isLimited"
              >
                <v-text-field
                  v-model.trim="config.attendance.description"
                  :disabled="!config.attendance.isLimited"
                  outlined
                  label="Text oder Beschreibung"
                  hide-details="auto"
                  clearable
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                v-if="config.attendance.isLimited"
              >
                <v-autocomplete
                  v-model="config.attendance.allowedDivisions"
                  :items="divisions"
                  chips
                  small-chips
                  :disabled="!config.attendance.isLimited"
                  deletable-chips
                  outlined
                  return-object
                  label="Abteilungen einladen"
                  item-text="title"
                  item-value="meta.id"
                  multiple
                  clearable
                  hide-details="auto"
                  append-inner-icon="mdi-infinity"
                >
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                v-if="config.attendance.isLimited"
              >
                <v-autocomplete
                  v-model="config.attendance.allowedUserGroups"
                  :items="userGroups"
                  chips
                  small-chips
                  :disabled="!config.attendance.isLimited"
                  deletable-chips
                  outlined
                  return-object
                  label="Benutzergruppen einladen"
                  item-text="title"
                  item-value="meta.id"
                  multiple
                  clearable
                  hide-details="auto"
                  append-inner-icon="mdi-infinity"
                >
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                v-if="config.attendance.isLimited"
              >
                <v-autocomplete
                  v-model="config.attendance.allowedPersons"
                  :items="membershipsListFiltered"
                  chips
                  :disabled="!config.attendance.isLimited"
                  small-chips
                  outlined
                  deletable-chips
                  return-object
                  label="Personen einladen"
                  item-text="displayName"
                  item-value="uid"
                  multiple
                  clearable
                  hide-details="auto"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-checkbox
                  v-model="config.organizer.isAppointed"
                  label="Organisator(en) festlegen"
                  persistent-hint
                  hint="Organisatoren werden in den Veranstaltungsdetails angezeigt und werden bei Abwesenheiten benachrichtigt."
                ></v-checkbox>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="5"
                v-if="config.organizer.isAppointed"
              >
                <v-text-field
                  v-model.trim="config.organizer.description"
                  :disabled="!config.organizer.isAppointed"
                  outlined
                  label="Text oder Beschreibung"
                  hide-details="auto"
                  clearable
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="7"
                v-if="config.organizer.isAppointed"
              >
                <v-autocomplete
                  v-model="config.organizer.personData"
                  :items="membershipsListFiltered"
                  :disabled="!config.organizer.isAppointed"
                  chips
                  small-chips
                  outlined
                  return-object
                  deletable-chips
                  label="Personen als Organisatoren hinzufügen"
                  item-text="displayName"
                  item-value="uid"
                  multiple
                  clearable
                  hide-details="auto"
                >
                </v-autocomplete>
              </v-col>
              <v-col
                v-if="config.organizer.isAppointed"
                cols="12"
                sm="12"
                md="12"
              >
                <v-checkbox
                  v-model="config.organizer.hasAdditionalPermissions"
                  label="Erweiterte Berechtigungen für Organisatoren"
                  persistent-hint
                  hint="Organisatoren können diese Veranstaltung verwalten unabhängig von ihren sonstigen Berechtigungen."
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-checkbox
                  v-model="config.responses.deadline.isCustom"
                  label="Benutzerdefinierte Rückmeldefrist verwenden"
                  persistent-hint
                  hint="Die Rückmeldefrist wird entsprechend Deiner globalen Konfiguration gesetzt, sofern Du diese hier nicht für diese Veranstaltung anpasst. Bei Aktivieren der benutzdefinierten Anmeldefrist wird die globale Konfiguration nicht mehr auf diese Veranstaltung angewandt!"
                ></v-checkbox>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                v-if="config.responses.deadline.isCustom && !isInTemplateMode"
              >
                <v-text-field
                  v-model="config.responses.deadline.date"
                  type="date"
                  :disabled="!config.responses.deadline.isCustom"
                  :rules="
                    config.responses.deadline.isCustom && !isInTemplateMode
                      ? [rules.required]
                      : ''
                  "
                  label="Datum (Ablauf der Frist)"
                  hint="DD.MM.YYYY"
                  outlined
                  prepend-inner-icon="mdi-calendar-end"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="3"
                v-if="config.responses.deadline.isCustom && !isInTemplateMode"
              >
                <v-text-field
                  v-model="config.responses.deadline.time"
                  label="Zeit (Ablauf der Frist)"
                  hint="HH:MM"
                  :disabled="!config.responses.deadline.isCustom"
                  :rules="
                    config.responses.deadline.isCustom && !isInTemplateMode
                      ? [rules.required]
                      : ''
                  "
                  outlined
                  type="time"
                  prepend-inner-icon="mdi-clock-end"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                v-if="config.responses.deadline.isCustom && isInTemplateMode"
              >
                <v-text-field
                  v-model.number="config.responses.deadline.inMinutes"
                  outlined
                  type="number"
                  :rules="
                    config.responses.deadline.isCustom && isInTemplateMode
                      ? [rules.required, rules.min]
                      : ''
                  "
                  label="Rückmeldefrist in Minuten vor Veranstaltung"
                  hint="Die Rückmeldefrist wird für jede Veranstaltung individuell berechnet. Beispiel: Bei Eingabe von 60 Minuten ist die Abgabe von Rückmeldungen bis 60 Minuten vor Veranstaltugnsbeginn möglich."
                  persistent-hint
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                v-if="config.responses.deadline.isCustom && isInTemplateMode"
              >
                <v-btn
                  depressed
                  @click="minus(60)"
                  :disabled="config.responses.deadline.inMinutes < 60"
                  class="mt-3 mx-1"
                >
                  <v-icon>mdi-rewind-60</v-icon>
                </v-btn>
                <v-btn
                  depressed
                  @click="minus(30)"
                  :disabled="config.responses.deadline.inMinutes < 30"
                  class="mt-3 mx-1"
                >
                  <v-icon>mdi-rewind-30</v-icon>
                </v-btn>
                <v-btn depressed @click="plus(30)" class="mt-3 mx-1">
                  <v-icon>mdi-fast-forward-30</v-icon>
                </v-btn>
                <v-btn depressed @click="plus(60)" class="mt-3 mx-1">
                  <v-icon>mdi-fast-forward-60</v-icon>
                </v-btn>
              </v-col>
              <!-- <event-deadline-config
                  v-if="config.responses.deadline.isCustom"
                  :fristInMin.sync="config.responses.deadline.inMinutes"
                  fieldLabel="Rückmeldefrist in Minuten vor Veranstaltung"
                ></event-deadline-config> -->
              <v-col cols="12" sm="12" md="12">
                <v-checkbox
                  v-model="config.attendance.isMandatory"
                  label="Anwesenheitspflicht für diese Veranstaltung"
                  persistent-hint
                  hint="Besteht Anwesenheitspflicht, wird bei Abgabe der Rückmeldung gesondert darauf hingewiesen."
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-checkbox
                  label="Maximale Teilnehmeranzahl festlegen"
                  disabled
                  persistent-hint
                  hint="Nach Erreichen der festgelegten Anzahl können weitere Rückmeldungen nur unter Vorbehalt abgegeben werden. Alle weitere Rückmeldungen sind nach Zeitpunkt der Meldung entsprechend gekennzeichnet und auf die maximale Teilnehmeranzahl wird bei Abgabe der Rückmeldung hingewiesen."
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-checkbox
                  v-model="config.responses.areBinding"
                  label="Rückmeldungen sind verbindlich"
                  persistent-hint
                  hint="Abgegebene Rückmeldungen zur Veranstaltung können nach Abgabe nur noch durch berechtigte Nutzer bearbeitet werden."
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-checkbox
                  v-model="config.responses.areLocked"
                  label="Rückmeldungen sperren"
                  persistent-hint
                  hint="Das Bearbeiten und die Abgabe von neuen Rückmeldungen ist nicht mehr möglich. Vorhandene Rückmeldungen bleiben bestehen. Die Rückmeldefunktion wird eingefroren."
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-checkbox
                  label="Benachrichtigung per E-Mail"
                  persistent-hint
                  disabled
                  hint="Abhängig von den persönlichen Benachrichtigungseinstellungen der einzelnen Benutzer wird eine Benachrichtigung per E-Mail versendet."
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import Subheader from "@/components/_systemwide/Subheader.vue";
// import EventDeadlineConfig from "@/components/scheduling/EventDeadlineConfig.vue";
import { ADMIN, MEMBER_MANAGEMENT } from "@/store/modules.js";
import {
  GET_USER_LIST,
  GET_USER_GROUPS,
  GET_DIVISIONS,
} from "@/store/action-types.js";

export default {
  name: "event-manage-settings",
  components: {
    Subheader,
    //EventDeadlineConfig,
  },
  props: {
    isInTemplateMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    config: {},
  },
  model: {
    prop: "config",
    event: "configChange",
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
        min: (value) =>
          /^[+]?\d+([.]\d+)?$/.test(value) || "Muss positive Zahl sein.",
      },
      membershipList: [],
    };
  },
  computed: {
    configLocal: {
      get: function () {
        return this.config;
      },
      set: function (value) {
        this.$emit("configChange", value);
      },
    },
    memberships() {
      return this.$store.state.admin.membershipsPublic;
    },
    userGroups() {
      const userGroups = this.$store.state.admin.userGroups.map(
        (userGroup) => ({
          title: userGroup.title,
          id: userGroup.meta.id,
        })
      );
      return userGroups;
    },
    divisions() {
      const divisions = this.$store.state.memberManagement.divisions.map(
        (division) => ({
          title: division.title,
          id: division.meta.id,
        })
      );
      return divisions;
    },
    membershipsListFiltered() {
      const input = this.memberships;
      const groupItems = (arr) => {
        return arr.reduce((a, c) => {
          if (typeof a[c.group.title] === "undefined") a[c.group.title] = [];
          a[c.group.title] = [...a[c.group.title], c];
          return a;
        }, {});
      };
      const step1 = groupItems(input);
      const insertHeaders = (obj) => {
        return Object.entries(obj).reduce((a, [key, vals], i, d) => {
          let r = [
            {
              header: key,
            },
            ...vals,
          ];
          // this is needed to have the exact same array
          // that you presented as desirable output:
          // no divider at the last place
          if (i < d.length - 1) {
            r = [
              ...r,
              {
                divider: true,
              },
            ];
          }
          a = [...a, ...r];
          return a;
        }, []);
      };
      const step2 = insertHeaders(step1);
      const step3 = step2
        .filter(
          // FIXME: temporary fix, to be watched
          (item) =>
            item.header || (item.user && item.user.displayName && item.user.uid)
        )
        .map((item) => {
          if (item.header) {
            // Skip headers and dividers
            return item;
          } else if (item.user && item.user.displayName && item.user.uid) {
            // Extract displayName and uid from membership object
            return {
              displayName: item.user.displayName || "",
              uid: item.user.uid,
            };
          } else {
            return {
              displayName: "",
              uid: "",
            };
          }
        });

      return step3;
    },
    membersFiltered() {
      var input = this.memberships;
      var filtered = input.filter((item) => {
        return item.group.id == this.$route.params.itemId;
      });
      return filtered;
    },
    personAttendanceIds() {
      return this.config.attendance.allowedPersons.map((item) => item.uid);
    },
    userGroupAttendanceIds() {
      return this.config.attendance.allowedUserGroups.map((item) => item.id);
    },
    allAttendanceIds() {
      return this.personAttendanceIds.concat(this.userGroupAttendanceIds);
    },
  },
  watch: {
    "config.organizer.personData": {
      handler(newData) {
        this.config.organizer.personIds = newData.map((item) => item.uid);
      },
    },
    // "config.attendance.isLimited": {
    //   handler(newData) {
    //     if (
    //       newData === false &&
    //       !this.config.attendance.allowedUserGroups.includes("all")
    //     ) {
    //       this.config.attendance.allowedUserGroups = [
    //         {
    //           title: "Alle",
    //           id: "all",
    //         },
    //       ];
    //       this.config.attendance.allowedPersons = [];
    //     } else if (
    //       newData === true &&
    //       !this.config.attendance.allowedAttendeesIds.length > 0
    //     ) {
    //       this.config.attendance.allowedUserGroups = [];
    //       this.config.attendance.allowedPersons = [];
    //     }

    //     this.config.attendance.allowedAttendeesIds = this.allAttendanceIds;
    //   },
    // },
    // "config.attendance.allowedUserGroups": {
    //   handler() {
    //     this.config.attendance.allowedAttendeesIds = this.allAttendanceIds;
    //   },
    // },
    // "config.attendance.allowedPersons": {
    //   handler() {
    //     this.config.attendance.allowedAttendeesIds = this.allAttendanceIds;
    //   },
    // },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${ADMIN}${GET_USER_LIST}`, {
        organizationId: this.$route.params.organizationId,
        queryIsFiltered: true,
        query: {
          type: "active",
        },
      });
      this.$store.dispatch(`${ADMIN}${GET_USER_GROUPS}`, {
        organizationId: this.$route.params.organizationId,
        queryIsFiltered: true,
        query: {
          type: "active",
        },
      });
      this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_DIVISIONS}`, {
        organizationId: this.$route.params.organizationId,
        queryIsFiltered: true,
        query: {
          type: "active",
        },
      });
    },
    // if (newData.length < 0) {
    //   this.config.organizer.isAppointed = false;
    // }
    minus(value) {
      this.config.responses.deadline.inMinutes =
        this.config.responses.deadline.inMinutes - value;
    },
    plus(value) {
      this.config.responses.deadline.inMinutes =
        this.config.responses.deadline.inMinutes + value;
    },
  },
};
</script>

<style></style>
