<template>
  <div>
    <NavigationBar
      titel="Dienstart anlegen"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <v-form
        @submit.prevent="createItem"
        ref="form"
        v-model="helpers.formIsValid"
      >
        <duty-type-editor v-model="dutyType" mode="new"></duty-type-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import { DUTY_MANAGEMENT } from "@/store/modules.js";
import { CREATE_REPORT_TYPE } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import DutyTypeEditor from "@/components/duty-management/DutyTypeEditor.vue";

export default {
  name: "duty-management-config-tab-duty-types-duty-type-new",
  components: {
    NavigationBar,
    DutyTypeEditor,
  },
  data() {
    return {
      actions: [
        {
          title: "Erstellen",
          icon: "mdi-plus-circle",
          actionStyle: "textBtnSubmit",
          function: this.createItem,
        },
      ],
      dutyType: {
        title: "",
        shortTitle: "",
        color: "#F44336",
        icon: "",
        sortKey: Number,
        prefill: {
          title: "",
          location: {
            name: "",
          },
          payrollType: {
            id: "",
            title: "",
          },
        },
        options: {
          requiredFields: [],
          customFields: [],
        },
      },
      helpers: {
        formIsValid: false,
      },
    };
  },
  computed: {
    newSortKey() {
      return this.$store.state.dutyManagement.dutyTypes.length + 1;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.dutyType.sortKey = this.newSortKey;
    },
    validate() {
      this.$refs.form.validate();
    },
    createItem() {
      this.validate();
      if (this.helpers.formIsValid) {
        this.$store
          .dispatch(`${DUTY_MANAGEMENT}${CREATE_REPORT_TYPE}`, {
            organizationId: this.$route.params.organizationId,

            title: this.dutyType.title,
            shortTitle: this.dutyType.shortTitle,
            description: this.dutyType.description,
            color: this.dutyType.color,
            icon: this.dutyType.icon,
            prefill: this.dutyType.prefill,
            options: this.dutyType.options,

            sortKey: this.dutyType.sortKey,
          })
          .then(() => {
            this.$router.push({
              name: "duty-management-config-tab-duty-types",
              params: {
                organizationId: this.$route.params.organizationId,
              },
            });
          });
      } else {
        alert("Prüfe Deine Eingabe und versuche es erneut.");
      }
    },
  },
};
</script>

<style></style>
