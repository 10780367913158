<template>
  <div>
    <NavigationBar
      titel="Vorlage bearbeiten"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-form
              v-model="helpers.formIsValid"
              ref="form"
              @submit.prevent="createItem"
            >
              <v-col cols="12">
                <Subheader icon="mdi-book" title="Vorlage"></Subheader>
                <div class="mt-6">
                  <v-card>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="9">
                            <v-text-field
                              v-model.trim="template.template.title"
                              :counter="35"
                              label="Bezeichnung"
                              required
                              :rules="[rules.required]"
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="3">
                            <v-text-field
                              v-model.number="template.template.sortKey"
                              label="Sortierschlüssel"
                              prepend-inner-icon="mdi-sort-numeric-ascending"
                              type="number"
                              hint="Vorlagen werden aufsteigend nach ihrem Sortierschlüssel sortiert."
                              persistent-hint
                              required
                              :rules="[rules.required]"
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <v-textarea
                              v-model.trim="template.template.description"
                              counter
                              clearable
                              rows="4"
                              no-resize
                              auto-grow
                              label="Beschreibung"
                              outlined
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
              <v-col cols="12">
                <event-manage-details
                  isInTemplateMode
                  v-model="template.data"
                ></event-manage-details>
              </v-col>
              <v-col cols="12">
                <event-manage-settings
                  isInTemplateMode
                  v-model="template.data.config"
                ></event-manage-settings>
              </v-col>
            </v-form>
          </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { SCHEDULING } from "@/store/modules.js";
import { UPDATE_EVENT_TEMPLATE } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import EventManageDetails from "@/components/scheduling/EventManageDetails.vue";
import EventManageSettings from "@/components/scheduling/EventManageSettings.vue";

export default {
  name: "scheduling-config-tab-templates-template-edit",
  components: {
    NavigationBar,
    Subheader,
    EventManageDetails,
    EventManageSettings,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.updateItem,
        },
      ],

      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },

      helpers: {
        formIsValid: false,
      },

      template: {
        template: {
          title: null,
          description: null,
          sortKey: null,
        },
        data: {
          title: "",
          description: "",
          type: {
            id: null,
            title: null,
            shortTitle: null,
            color: null,
            description: null,
            dutyTypeId: null,
          },
          location: {
            name: "",
          },
          requiredPPE: [],
          tasks: [],
          units: [],
          duration: {
            start: {
              timestamp: null,
              time: null,
              isOpen: false,
            },
            end: {
              timestamp: null,
              time: null,
              isOpen: false,
            },
          },

          details: [],

          config: {
            organizer: {
              isAppointed: false,
              hasAdditionalPermissions: false,
              description: null,
              personIds: [],
              personData: [],
            },
            attendance: {
              isMandatory: false,
              isLimited: false,
              description: null,
              minAttendees: null,
              maxAttendees: null,
              personIds: [],
              allowedPersons: [],
              userGroupIds: [],
              allowedUserGroups: [],
              allowedDivisions: [],
            },
            responses: {
              areBinding: false,
              areLocked: false,
              deadline: {
                isCustom: false,
                inMinutes: null,
              },
            },
          },
        },
      },
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("eventTemplates")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.template = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    validate() {
      this.$refs.form.validate();
    },
    checkAttendeesData() {
      const filterOutAllString = (array) =>
        array ? array.filter((item) => item !== "all") : [];
      const filterOutAllObject = (array) =>
        array ? array.filter((item) => item.id != "all") : [];

      const attendance = this.template.data.config.attendance;
      if (!attendance) {
        console.log("attendance data is missing");
        return;
      }

      const allowedPersonsIds = attendance.allowedPersons
        ? attendance.allowedPersons.map((item) => item.uid)
        : [];
      const allowedUserGroupsIds = attendance.allowedUserGroups
        ? attendance.allowedUserGroups
            .map((item) => item.id)
            .filter((item) => item != "all")
        : [];
      const allowedDivisionsIds = attendance.allowedDivisions
        ? attendance.allowedDivisions
            .map((item) => item.id)
            .filter((item) => item != "all")
        : [];

      const attendanceIsLimited = attendance.isLimited;
      const noAttendanceDescription = !attendance.description;
      const noAttendanceLimitationsSelected =
        allowedPersonsIds.length == 0 &&
        allowedUserGroupsIds.length == 0 &&
        allowedDivisionsIds.length == 0;

      // if no persons or user groups are selected, make sure all is included as default
      if (noAttendanceLimitationsSelected) {
        attendance.allowedAttendeesIds = filterOutAllString(
          attendance.allowedAttendeesIds
        );
        attendance.allowedDivisions = [{ title: "Alle", id: "all" }];
        attendance.allowedUserGroups = [];
      } else {
        // at least one person, user group or division is selected
        attendance.allowedAttendeesIds = filterOutAllString(
          attendance.allowedAttendeesIds
        );
        attendance.allowedUserGroups = filterOutAllObject(
          attendance.allowedUserGroups
        );
        attendance.allowedDivisions = filterOutAllObject(
          attendance.allowedDivisions
        );
      }

      if (!attendanceIsLimited) {
        attendance.allowedDivisions = [{ title: "Alle", id: "all" }];
        attendance.allowedUserGroups = [];
        attendance.allowedPersons = [];
      }

      if (
        attendanceIsLimited &&
        noAttendanceDescription &&
        noAttendanceLimitationsSelected
      ) {
        attendance.isLimited = false;
      }

      const personAttendanceIds = attendance.allowedPersons
        ? attendance.allowedPersons.map((item) => item.uid)
        : [];
      const userGroupAttendanceIds = attendance.allowedUserGroups
        ? attendance.allowedUserGroups.map((item) => item.id)
        : [];
      const divisionAttendanceIds = attendance.allowedDivisions
        ? attendance.allowedDivisions.map((item) => item.id)
        : [];

      attendance.allowedAttendeesIds = personAttendanceIds
        .concat(userGroupAttendanceIds)
        .concat(divisionAttendanceIds);
    },
    updateItem() {
      this.checkAttendeesData();

      this.validate();
      if (this.helpers.formIsValid) {
        this.$store.dispatch(`${SCHEDULING}${UPDATE_EVENT_TEMPLATE}`, {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,

          template: {
            title: this.template.template.title,
            description: this.template.template.description,
            sortKey: this.template.template.sortKey,
          },

          data: {
            title: this.template.data.title,
            description: this.template.data.description,
            type: {
              id: this.template.data.type.id,
              title: this.template.data.type.title,
              shortTitle: this.template.data.type.shortTitle,
              color: this.template.data.type.color,
              description: this.template.data.type.description,
              dutyTypeId: this.template.data.type.dutyTypeId,
            },
            location: {
              name: this.template.data.location.name,
            },
            requiredPPE: this.template.data.requiredPPE,
            tasks: this.template.data.tasks,
            units: this.template.data.units,
            duration: {
              start: {
                time: this.template.data.duration.start.time,
                isOpen: this.template.data.duration.start.isOpen,
              },
              end: {
                time: this.template.data.duration.end.time,
                isOpen: this.template.data.duration.end.isOpen,
              },
            },

            details: [],

            config: {
              organizer: {
                isAppointed: this.template.data.config.organizer.isAppointed,
                hasAdditionalPermissions:
                  this.template.data.config.organizer.hasAdditionalPermissions,
                description: this.template.data.config.organizer.description,
                personIds: this.template.data.config.organizer.personIds,
                personData: this.template.data.config.organizer.personData,
              },
              attendance: {
                isMandatory: this.template.data.config.attendance.isMandatory,
                isLimited: this.template.data.config.attendance.isLimited,
                description: this.template.data.config.attendance.description,
                minAttendees: this.template.data.config.attendance.minAttendees,
                maxAttendees: this.template.data.config.attendance.maxAttendees,
                allowedAttendeesIds:
                  this.template.data.config.attendance.allowedAttendeesIds,
                allowedPersons:
                  this.template.data.config.attendance.allowedPersons,
                allowedUserGroups:
                  this.template.data.config.attendance.allowedUserGroups,
                allowedDivisions:
                  this.template.data.config.attendance.allowedDivisions,
              },
              responses: {
                areBinding: this.template.data.config.responses.areBinding,
                areLocked: this.template.data.config.responses.areLocked,
                deadline: {
                  isCustom:
                    this.template.data.config.responses.deadline.isCustom,
                  inMinutes:
                    this.template.data.config.responses.deadline.inMinutes,
                },
              },
            },
          },
        });
      } else {
        alert("Prüfe Deine Eingabe und versuche es erneut.");
      }
    },
  },
};
</script>

<style></style>
