<template>
  <div>
    <NavigationBar
      titel="Details"
      :actions="actions"
      showBackBtn
    ></NavigationBar>
    <section>
      <v-container fluid>
        <v-row> </v-row>
      </v-container>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
// import { db } from "@/firebase";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
export default {
  name: "member-management-performance-targets-details",
  components: {
    NavigationBar,
    SupportTools,
  },
  data() {
    return {
      actions: [
        {
          title: "Bearbeiten",
          // permission: `${SCHEDULING_CONFIG_EVENT_TYPES_create}`,
          icon: "mdi-pencil",
          actionStyle: "textBtn",
          function: () => {
            this.$router.push({
              name: "member-management-performance-targets-edit",
              params: {
                organizationId: this.$route.params.organizationId,
                itemId: this.$route.params.itemId,
              },
            });
          },
        },
        {
          title: "Löschen",
          icon: "mdi-delete",
          actionStyle: "textBtn",
          disabled: true,
        },
        { divider: true },
        { actionStyle: "clipboardBtn" },
      ],

      performanceTarget: null,
    };
  },
  computed: {
    checkIfSupport() {
      return this.$store.getters["user/checkIfSupport"];
    },
    code() {
      return [
        // { title: "eventType", data: this.eventType }
      ];
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      // db.collection("organizations")
      //   .doc(this.$route.params.organizationId)
      //   .collection("eventTypes")
      //   .doc(this.$route.params.itemId)
      //   .get()
      //   .then((doc) => {
      //     if (doc.exists) {
      //       this.eventType = doc.data();
      //     } else {
      //       // doc.data() will be undefined in this case
      //       console.log("No such document!");
      //     }
      //   })
      //   .catch(function (error) {
      //     console.log("Error getting document:", error);
      //     return false;
      //   });
    },
  },
};
</script>
