// Available actions via Vuex
// user.js
export const SIGN_UP = "SIGN_UP";
export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";

export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_MEMBERSHIPS = "GET_MEMBERSHIPS";
export const USER_SETUP = "USER_SETUP";
export const UPDATE_PRIVACY_SETTINGS = "UPDATE_PRIVACY_SETTINGS";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const UPDATE_USER_LANGUAGE = "UPDATE_USER_LANGUAGE";
export const UPDATE_NOTIFICATION_SETTINGS = "UPDATE_NOTIFICATION_SETTINGS";

// organisation.js
export const RESET_ORGANISATION = "RESET_ORGANIZATION";
export const GET_ORGANIZATION = "GET_ORGANIZATION";
export const UPDATE_ORGANISATION_INFO = "UPDATE_ORGANIZATION_INFO";
export const UPDATE_ORGANISATION_STANDORT = "UPDATE_ORGANIZATION_LOCATION";
export const UPDATE_ORGANIZATION_LINKS = "UPDATE_ORGANIZATION_LINKS";
export const GET_MEMBERSHIP = "GET_MEMBERSHIP";
export const UPDATE_MEMBERSHIP_ROLE = "UPDATE_MEMBERSHIP_ROLE";

// module/...
// module/myOrganization.js
export const RESET_MY_ORGANIZATION = "RESET_MY_ORGANIZATION";
// Dashboard
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
// News
export const GET_NEWS = "GET_NEWS";
export const GET_NEWS_INBOUND_EMAILS = "GET_NEWS_INBOUND_EMAILS";
export const CREATE_NEWS = "CREATE_NEWS";
export const READ_NEWS = "READ_NEWS";
export const UPDATE_NEWS = "UPDATE_NEWS";
export const DELETE_NEWS = "DELETE_NEWS";
// Surveys
export const GET_SURVEYS = "GET_SURVEYS";
export const CREATE_SURVEY = "CREATE_SURVEY";
export const READ_SURVEY = "READ_SURVEY";
export const UPDATE_SURVEY = "UPDATE_SURVEY";
export const DELETE_SURVEY = "DELETE_SURVEY";
// Assignments

// Organizational Chart

// Guardbook
export const GET_GUARDBOOK_ENTRIES = "GET_GUARDBOOK_ENTRIES";
export const CREATE_GUARDBOOK_ENTRY = "CREATE_GUARDBOOK_ENTRY";
export const READ_GUARDBOOK_ENTRY = "READ_GUARDBOOK_ENTRY";
export const UPDATE_GUARDBOOK_ENTRY = "UPDATE_GUARDBOOK_ENTRY";
export const DELETE_GUARDBOOK_ENTRY = "DELETE_GUARDBOOK_ENTRY";

export const GET_GUARDBOOK_CATEGORIES = "GET_GUARDBOOK_CATEGORIES";
export const CREATE_GUARDBOOK_CATEGORY = "CREATE_GUARDBOOK_CATEGORY";
export const READ_GUARDBOOK_CATEGORY = "READ_KATEGORIE";
export const UPDATE_GUARDBOOK_CATEGORY = "UPDATE_GUARDBOOK_CATEGORY";
export const DELETE_GUARDBOOK_CATEGORY = "DELETE_GUARDBOOK_CATEGORY";

// module/scheduling.js
export const RESET_SCHEDULING = "RESET_SCHEDULING";
export const GET_EVENTS = "GET_EVENTS";
export const GET_ARCHIVE = "GET_ARCHIVE";
export const GET_EVENTS_STATUS = "GET_EVENTS_STATUS";
export const CREATE_EVENT = "CREATE_EVENT";
export const CREATE_EVENTS = "CREATE_EVENTS";
export const READ_VERANSTALTUNG = "READ_VERANSTALTUNG";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const UPDATE_EVENT_RESPONSE = "UPDATE_EVENT_RESPONSE";
export const UPDATE_EVENT_RESPONSES = "UPDATE_EVENT_RESPONSES";
export const UPDATE_EVENT_META = "UPDATE_EVENT_META";
export const DELETE_EVENT = "DELETE_EVENT";
export const RESET_RESPONSES = "RESET_RESPONSES";

export const CREATE_EVENT_CANCELLATION_NOTICE =
  "CREATE_EVENT_CANCELLATION_NOTICE";

export const GET_CALENDAR_FEEDS = "GET_CALENDAR_FEEDS";

export const GET_EVENT_TYPES = "GET_EVENT_TYPES";
export const CREATE_EVENT_TYPE = "CREATE_EVENT_TYPE";
export const CREATE_EVENT_TYPES = "CREATE_EVENT_TYPES";
export const READ_EVENT_TYPE = "READ_EVENT_TYPE";
export const UPDATE_EVENT_TYPE = "UPDATE_EVENT_TYPE";
export const DELETE_EVENT_TYPE = "DELETE_EVENT_TYPE";

export const GET_EVENT_TEMPLATES = "GET_EVENT_TEMPLATES";
export const CREATE_EVENT_TEMPLATE = "CREATE_EVENT_TEMPLATE";
export const READ_VORLAGE = "READ_VORLAGE";
export const UPDATE_EVENT_TEMPLATE = "UPDATE_EVENT_TEMPLATE";
export const DELETE_EVENT_TEMPLATE = "DELETE_EVENT_TEMPLATE";

export const UPDATE_SCHEDULING_CONFIG = "UPDATE_SCHEDULING_CONFIG";

// module/duty-management.js
export const GET_REPORTS = "GET_REPORTS";
export const GET_ARCHIVED_REPORTS = "GET_ARCHIVED_REPORTS";
export const CREATE_REPORT = "CREATE_REPORT";
export const READ_REPORT = "READ_REPORT";
export const UPDATE_REPORT = "UPDATE_REPORT";
export const DELETE_REPORT = "DELETE_REPORT";

export const GET_REPORT_TYPES = "GET_REPORT_TYPES";
export const CREATE_REPORT_TYPE = "CREATE_REPORT_TYPE";
export const READ_REPORT_TYPE = "READ_REPORT_TYPE";
export const UPDATE_REPORT_TYPE = "UPDATE_REPORT_TYPE";
export const DELETE_REPORT_TYPE = "DELETE_REPORT_TYPE";
// module/availability.js
export const GET_LEAVES = "GET_LEAVES";
export const GET_ARCHIVED_LEAVES = "GET_ARCHIVED_LEAVES";
export const CREATE_LEAVE = "CREATE_LEAVE";
export const READ_LEAVE = "READ_LEAVE";
export const UPDATE_LEAVE = "UPDATE_LEAVE";
export const DELETE_LEAVE = "DELETE_LEAVE";

export const GET_REQUESTS = "GET_REQUESTS";
export const GET_ARCHIVED_REQUESTS = "GET_ARCHIVED_REQUESTS";
export const GET_REQUESTS_STATUS = "GET_REQUEST_STATUS";
export const CREATE_REQUEST = "CREATE_REQUEST";
export const CREATE_REQUESTS = "CREATE_REQUESTS";
export const READ_REQUEST = "READ_REQUEST";
export const UPDATE_REQUEST = "UPDATE_REQUEST";
export const DELETE_REQUEST = "DELETE_REQUEST";

export const GET_REQUEST_TYPES = "GET_REQUEST_TYPES";
export const CREATE_REQUEST_TYPE = "CREATE_REQUEST_TYPE";
export const CREATE_REQUEST_TYPES = "CREATE_REQUEST_TYPES";
export const READ_REQUEST_TYPE = "READ_REQUEST_TYPE";
export const UPDATE_REQUEST_TYPE = "UPDATE_REQUEST_TYPE";
export const DELETE_REQUEST_TYPE = "DELETE_REQUEST_TYPE";

export const GET_UNITS = "GET_UNITS";
export const CREATE_UNIT = "CREATE_UNIT";
export const READ_UNIT = "READ_UNIT";
export const UPDATE_UNIT = "UPDATE_UNIT";
export const DELETE_UNIT = "DELETE_UNIT";

export const GET_POSITIONS = "GET_POSITIONS";
export const CREATE_POSITION = "CREATE_POSITION";
export const CREATE_POSITIONS = "CREATE_POSITIONS";
export const READ_POSITION = "READ_POSITION";
export const UPDATE_POSITION = "UPDATE_POSITION";
export const DELETE_POSITION = "DELETE_POSITION";

export const UPDATE_AVAILABILITY_CONFIG = "UPDATE_AVAILABILITY_CONFIG";

// module/member-management.js
export const UPDATE_MEMBER_MANAGEMENT_CONFIG =
  "UPDATE_MEMBER_MANAGEMENT_CONFIG";

export const GET_PERSONNEL_RECORDS = "GET_PERSONNEL_RECORDS";
export const UPDATE_PERSONNEL_RECORD = "UPDATE_PERSONNEL_RECORD";

export const GET_ASSIGNMENTS = "GET_ASSIGNMENTS";
export const CREATE_ASSIGNMENT = "CREATE_ASSIGNMENT";
export const READ_ASSIGNMENT = "READ_ASSIGNMENT";
export const UPDATE_ASSIGNMENT = "UPDATE_ASSIGNMENT";
export const UPDATE_ASSIGNMENTS = "UPDATE_ASSIGNMENTS";
export const DELETE_ASSIGNMENT = "DELETE_ASSIGNMENT";

export const GET_DEADLINES = "GET_DEADLINES";
export const CREATE_DEADLINE = "CREATE_DEADLINE";
export const CREATE_DEADLINES = "CREATE_DEADLINES";
export const READ_DEADLINE = "READ_DEADLINE";
export const UPDATE_DEADLINE = "UPDATE_DEADLINE";
export const DELETE_DEADLINE = "DELETE_DEADLINE";

export const GET_PERFORMANCE_TARGETS = "GET_PERFORMANCE_TARGETS";
export const GET_PERFORMANCE_TARGET_RESULTS = "GET_PERFORMANCE_TARGET_RESULTS";
export const CREATE_PERFORMANCE_TARGET = "CREATE_PERFORMANCE_TARGET";
export const CREATE_PERFORMANCE_TARGETS = "CREATE_PERFORMANCE_TARGETS";
export const READ_PERFORMANCE_TARGET = "READ_PERFORMANCE_TARGET";
export const UPDATE_PERFORMANCE_TARGET = "UPDATE_PERFORMANCE_TARGET";
export const DELETE_PERFORMANCE_TARGET = "DELETE_PERFORMANCE_TARGET";

export const GET_SKILLS = "GET_SKILLS";
export const CREATE_SKILL = "CREATE_SKILL";
export const CREATE_SKILLS = "CREATE_SKILLS";
export const READ_SKILL = "READ_SKILL";
export const UPDATE_SKILL = "UPDATE_SKILL";
export const DELETE_SKILL = "DELETE_SKILL";

export const GET_SKILL_GROUPS = "GET_SKILL_GROUPS";
export const CREATE_SKILL_GROUP = "CREATE_SKILL_GROUP";
export const CREATE_SKILL_GROUPS = "CREATE_SKILL_GROUPS";
export const READ_SKILL_GROUP = "READ_SKILL_GROUP";
export const UPDATE_SKILL_GROUP = "UPDATE_SKILL_GROUP";
export const DELETE_SKILL_GROUP = "DELETE_SKILL_GROUP";

export const GET_COURSES = "GET_COURSES";
export const CREATE_COURSE = "CREATE_COURSE";
export const CREATE_COURSES = "CREATE_COURSES";
export const READ_COURSE = "READ_COURSE";
export const UPDATE_COURSE = "UPDATE_COURSE";
export const DELETE_COURSE = "DELETE_COURSE";

export const GET_COURSE_GROUPS = "GET_COURSE_GROUPS";
export const CREATE_COURSE_GROUP = "CREATE_COURSE_GROUP";
export const CREATE_COURSE_GROUPS = "CREATE_COURSE_GROUPS";
export const READ_COURSE_GROUP = "READ_COURSE_GROUP";
export const UPDATE_COURSE_GROUP = "UPDATE_COURSE_GROUP";
export const DELETE_COURSE_GROUP = "DELETE_COURSE_GROUP";

export const GET_RANKS = "GET_RANKS";
export const CREATE_RANK = "CREATE_RANK";
export const CREATE_RANKS = "CREATE_RANKS";
export const READ_RANK = "READ_RANK";
export const UPDATE_RANK = "UPDATE_RANK";
export const DELETE_RANK = "DELETE_RANK";

export const GET_DUTY_POSITIONS = "GET_DUTY_POSITIONS";
export const CREATE_DUTY_POSITION = "CREATE_DUTY_POSITION";
export const CREATE_DUTY_POSITIONS = "CREATE_DUTY_POSITIONS";
export const READ_DUTY_POSITION = "READ_DUTY_POSITION";
export const UPDATE_DUTY_POSITION = "UPDATE_DUTY_POSITION";
export const DELETE_DUTY_POSITION = "DELETE_DUTY_POSITION";

export const GET_HONORS = "GET_HONORS";
export const CREATE_HONOR = "CREATE_HONOR";
export const CREATE_HONORS = "CREATE_HONORS";
export const READ_HONOR = "READ_HONOR";
export const UPDATE_HONOR = "UPDATE_HONOR";
export const DELETE_HONOR = "DELETE_HONOR";

export const GET_DIVISIONS = "GET_DIVISIONS";
export const CREATE_DIVISION = "CREATE_DIVISION";
export const CREATE_DIVISIONS = "CREATE_DIVISIONS";
export const READ_DIVISION = "READ_DIVISION";
export const UPDATE_DIVISION = "UPDATE_DIVISION";
export const DELETE_DIVISION = "DELETE_DIVISION";
export const UPDATE_DEFAULT_DIVISION = "UPDATE_DEFAULT_DIVISION";

// module/accounting.js
export const GET_TIME_SHEETS = "GET_TIME_SHEETS";
export const GET_PAYROLL_TYPES = "GET_PAYROLL_TYPES";
export const GET_PAYROLL_GROUPS = "GET_PAYROLL_GROUPS";
export const GET_BATCHES = "GET_BATCHES";
export const GET_PAYSLIPS = "GET_PAYSLIPS";
export const GET_ARCHIVED_TIME_SHEETS = "GET_ARCHIVED_TIME_SHEETS";
export const GET_ARCHIVED_PAYSLIPS = "GET_ARCHIVED_PAYSLIPS";
export const GET_ARCHIVED_BATCHES = "GET_ARCHIVED_BATCHES";

export const UPDATE_ACCOUNTING_CONFIG = "UPDATE_ACCOUNTING_CONFIG";

// module/finance.js

// module/admin/..
export const RESET_ADMIN = "RESET_ADMIN";

export const GET_USER_LIST = "GET_USER_LIST";
export const GET_MEMBERSHIPS_PRIVATE = "GET_MEMBERSHIPS_PRIVATE";
// export const CREATE_USER = "CREATE_USER"
export const READ_USER = "READ_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";

export const GET_INVITES = "GET_INVITES";
export const INVITE_USER = "INVITE_USER";
export const UPDATE_INVITE = "UPDATE_INVITE";
export const UPDATE_INVITE_STATUS = "UPDATE_INVITE_STATUS";
export const DELETE_INVITE = "DELETE_INVITE";

export const GET_USER_GROUPS = "GET_USER_GROUPS";
export const CREATE_USER_GROUP = "CREATE_USER_GROUP";
export const READ_USER_GROUP = "READ_USER_GROUP";
export const UPDATE_USER_GROUP = "UPDATE_USER_GROUP";
export const DELETE_USER_GROUP = "DELETE_USER_GROUP";

export const GET_ROLES = "GET_ROLES";
export const CREATE_ROLE = "CREATE_ROLE";
export const CREATE_ROLES = "CREATE_ROLES";
export const READ_ROLE = "READ_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const DELETE_ROLE = "DELETE_ROLE";

export const GET_STANDARD_USER_GROUP = "GET_STANDARD_USER_GROUP";
export const UPDATE_STANDARD_USER_GROUP = "UPDATE_STANDARD_USER_GROUP";

export const GET_LOGS = "GET_LOGS";

export const GET_PRIVACY_POLICY_VERSIONS = "GET_PRIVACY_POLICY_VERSIONS";
export const CREATE_PRIVACY_POLICY_VERSION = "CREATE_PRIVACY_POLICY_VERSION";
