var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.responses.length > 0)?_c('div',[(_vm.responses.length == 1 && _vm.responses[0].attendanceStatus != '-2')?_c('v-card',{staticClass:"d-flex align-center",class:[
      _vm.$vuetify.theme.dark ? 'border-dark' : 'border-light',
      _vm.getStatusColor(
        _vm.responses[0].attendanceStatus,
        _vm.$vuetify.theme.dark ? 'dark' : 'light',
        'background'
      ),
    ],attrs:{"flat":"","height":"42px","light":""},on:{"click":_vm.toggleResponseDetailDialog}},[_c('v-card-title',{staticClass:"px-2"},[_c('v-icon',{attrs:{"color":_vm.getStatusColor(
            _vm.responses[0].attendanceStatus,
            _vm.$vuetify.theme.dark ? 'dark' : 'light',
            'text'
          )}},[_vm._v(_vm._s(_vm.getStatusIcon(_vm.responses[0].attendanceStatus)))])],1),_c('v-card-text',{staticClass:"text-caption px-1 py-1",staticStyle:{"line-height":"1.2"}},[(_vm.responses[0].isWaiver)?_c('strong',[_vm._v("Verzicht")]):_vm._e(),_c('strong',[_vm._v(_vm._s(_vm.responsesUnitPositionDisplay.unitTitle))]),_c('br'),_vm._v(" "+_vm._s(_vm.responsesUnitPositionDisplay.positionTitle)+" ")])],1):(_vm.responses.length > 1)?_c('v-card',{staticClass:"d-flex align-center",class:[
      _vm.$vuetify.theme.dark ? 'border-dark' : 'border-light',
      _vm.getStatusColor(
        _vm.responses[0].attendanceStatus,
        _vm.$vuetify.theme.dark ? 'dark' : 'light',
        'background'
      ),
    ],attrs:{"flat":"","height":"42px","light":""},on:{"click":_vm.toggleResponseDetailDialog}},[_c('v-card-title',{staticClass:"px-2"},[_c('v-icon',{attrs:{"color":_vm.getStatusColor(
            _vm.responses[0].attendanceStatus,
            _vm.$vuetify.theme.dark ? 'dark' : 'light',
            'text'
          )}},[_vm._v(_vm._s(_vm.getStatusIcon(_vm.responses[0].attendanceStatus)))])],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-card-text',{staticClass:"text-caption px-1 py-1",staticStyle:{"line-height":"1.2"}},[_c('strong',[_vm._v(_vm._s(_vm.responses.length)+" Einträge")]),_c('br')])],1):_vm._e(),(_vm.showResponseDetailDialog)?_c('CustomDialog',{attrs:{"title":"Anwesenheitseinträge","fullscreenOnMobile":"","width":"s","showCloseBtn":"","hideCloseBtn":"","hideActionBtn":""},on:{"close":function($event){return _vm.toggleResponseDetailDialog()}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"py-2 subtitle-1 font-weight-medium",staticStyle:{"word-break":"break-word"}},[_vm._v(" "+_vm._s(_vm.responses[0].user.displayName)+" "),_c('v-spacer')],1),_c('v-divider'),_c('v-list',{staticClass:"py-0"},_vm._l((_vm.responses),function(attendanceEntry,index){return _c('div',{key:index},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-regular subtitle-2"},[_c('strong',[_vm._v("Eintrag "+_vm._s(index + 1)+":")])])],1),_c('v-list-item-action',[_c('attendance-status-chip',{attrs:{"status":attendanceEntry.attendanceStatus,"small":""}})],1)],1),_c('v-list-item',[_c('v-list-item-content',{staticClass:"pt-0"},[_c('v-list-item-subtitle',[_c('p',{staticClass:"mb-0",staticStyle:{"line-height":"1.6"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-calendar-clock")]),_vm._v(_vm._s(_vm.timestampConverter( attendanceEntry.duration.start.timestamp ))+" bis "+_vm._s(_vm.tryToDisplayOnlyEndTime( attendanceEntry.duration.start.timestamp, attendanceEntry.duration.end.timestamp, attendanceEntry.duration.end.timestamp ))+" "),(
                        attendanceEntry.attendanceStatus == '1' &&
                        attendanceEntry.payrollType.title
                      )?_c('span',[_c('br'),_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-account-credit-card")]),_vm._v(_vm._s(attendanceEntry.payrollType.title)+" ")],1):_vm._e(),_c('br'),(
                        attendanceEntry.attendanceStatus == '1' &&
                        attendanceEntry.assignedPosition
                      )?_c('span',[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-fire-truck")]),_vm._v(_vm._s(attendanceEntry.assignedPosition.unit.title)+" – "+_vm._s(attendanceEntry.assignedPosition.position.shortTitle)+" "),_c('br')],1):_vm._e(),(attendanceEntry.note)?_c('span',[_c('br'),_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-card-text")]),_vm._v(_vm._s(attendanceEntry.note)+" ")],1):_vm._e()],1)])],1)],1),(_vm.responses.length > 1)?_c('v-divider'):_vm._e()],1)}),0)],1)]},proxy:true}],null,false,537284003)}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }