<template>
  <div>
    <NavigationBar
      titel="Details"
      :actions="actions"
      showBackBtn
    ></NavigationBar>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader icon="mdi-book" title="Informationen"></Subheader>
              <div class="mt-7">
                <v-card>
                  <v-card-title class="subheading">
                    {{ data.template.title }}
                  </v-card-title>
                  <v-card-subtitle v-if="data.template.description">
                    {{ data.template.description }}
                  </v-card-subtitle>
                  <v-card-text>
                    <span>
                      <v-icon small>mdi-sort-numeric-ascending</v-icon>
                      {{ data.template.sortKey }}
                    </span>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-text>
                    <h3 class="pb-2">
                      {{ data.data.title }}
                    </h3>
                    <div class="py-2" v-if="data.data.type.id">
                      <v-icon small :color="data.data.type.color"
                        >mdi-circle</v-icon
                      >
                      <span class="ml-5">{{ data.data.type.title }}</span>
                    </div>
                    <div class="py-2">
                      <v-icon dense>mdi-calendar-clock</v-icon>
                      <span class="ml-5"
                        >{{ data.data.duration.start.time }} Uhr bis
                        {{ data.data.duration.end.time }} Uhr</span
                      ><span
                        class="ml-3 error--text"
                        v-if="
                          data.data.duration.start.isOpen &&
                          !data.data.duration.end.isOpen
                        "
                        >Startzeit offen</span
                      ><span
                        class="ml-3 error--text"
                        v-if="
                          !data.data.duration.start.isOpen &&
                          data.data.duration.end.isOpen
                        "
                        >Endzeit offen</span
                      ><span
                        class="ml-3 error--text"
                        v-if="
                          data.data.duration.start.isOpen &&
                          data.data.duration.end.isOpen
                        "
                        >Start- und Endzeit offen</span
                      >
                    </div>
                    <div class="py-2" v-if="data.data.location.name">
                      <v-icon dense>mdi-map-marker</v-icon>
                      <span class="ml-5">{{ data.data.location.name }}</span>
                    </div>
                    <div class="py-2" v-if="data.data.description">
                      <v-icon dense>mdi-calendar-text</v-icon>
                      <span class="ml-5">{{ data.data.description }}</span>
                    </div>
                    <div
                      class="py-2"
                      v-if="data.data.config.organizer.isAppointed"
                    >
                      <v-icon dense>mdi-account-hard-hat</v-icon>
                      <span class="ml-5"
                        >Organisation:
                        {{ data.data.config.organizer.description }}</span
                      >
                    </div>
                    <div
                      class="py-2"
                      v-if="data.data.config.attendance.isLimited"
                    >
                      <v-icon dense>mdi-account-multiple-check</v-icon>
                      <span class="ml-5"
                        >Teilnahmebeschränkung:
                        {{ data.data.config.attendance.description }}</span
                      ><v-chip
                        v-for="item in data.data.config.attendance
                          .allowedDivisions"
                        :key="item.id"
                        label
                        small
                        class="ml-2 my-1"
                        ><v-icon small left>mdi-home-group</v-icon
                        >{{ item.title }}</v-chip
                      >
                      <v-chip
                        v-for="item in data.data.config.attendance
                          .allowedUserGroups"
                        :key="item.id"
                        label
                        small
                        class="ml-2 my-1"
                        ><v-icon small left>mdi-folder-account</v-icon
                        >{{ item.title }}</v-chip
                      ><v-chip
                        v-for="item in data.data.config.attendance
                          .allowedPersons"
                        :key="item.uid"
                        label
                        small
                        class="ml-2 my-1"
                        ><v-icon small left>mdi-account-circle</v-icon
                        >{{ item.displayName }}</v-chip
                      >
                    </div>
                    <div
                      class="py-2"
                      v-if="
                        data.data.config.responses.deadline.isCustom ||
                        data.data.config.attendance.isMandatory ||
                        data.data.config.responses.areBinding ||
                        data.data.config.responses.areLocked
                      "
                    >
                      <v-chip
                        label
                        v-if="data.data.config.responses.deadline.isCustom"
                        class="mr-2 my-1"
                      >
                        Rückmeldefrist
                        {{ data.data.config.responses.deadline.inMinutes }}
                        Minuten vor Beginn</v-chip
                      >
                      <v-chip
                        label
                        v-if="data.data.config.attendance.isMandatory"
                        class="mr-2 my-1"
                        ><v-icon small left>mdi-alert-circle</v-icon
                        >Anwesenheitspflicht</v-chip
                      >
                      <v-chip
                        label
                        v-if="data.data.config.responses.areBinding"
                        class="mr-2 my-1"
                        ><v-icon small left>mdi-check-decagram</v-icon
                        >Rückmeldungen verbindlich</v-chip
                      >
                      <v-chip
                        label
                        v-if="data.data.config.responses.areLocked"
                        class="mr-2 my-1"
                        ><v-icon small left>mdi-lock</v-icon>Rückmeldungen
                        gesperrt</v-chip
                      >
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { SCHEDULING } from "@/store/modules.js";
import { DELETE_EVENT_TEMPLATE } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import {
  SCHEDULING_CONFIG_EVENT_TEMPLATES_update,
  SCHEDULING_CONFIG_EVENT_TEMPLATES_delete,
} from "@/data/permission-types.js";

export default {
  name: "scheduling-config-tab-templates-template-details",
  components: {
    NavigationBar,
    Subheader,
    SupportTools,
  },
  data() {
    return {
      actions: [
        {
          title: "Bearbeiten",
          permission: `${SCHEDULING_CONFIG_EVENT_TEMPLATES_update}`,
          icon: "mdi-pencil",
          actionStyle: "textBtn",
          function: this.editItem,
        },
        {
          title: "Löschen",
          permission: `${SCHEDULING_CONFIG_EVENT_TEMPLATES_delete}`,
          icon: "mdi-delete",
          actionStyle: "textBtn",
          function: this.deleteItem,
        },
        { divider: true },
        { actionStyle: "clipboardBtn" },
      ],
      sortDesc: true,
      sortBy: "title",

      data: null,
      show1: true,
    };
  },
  computed: {
    checkIfSupport() {
      return this.$store.getters["user/checkIfSupport"];
    },
    code() {
      return [{ title: "template", data: this.data }];
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("eventTemplates")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.data = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    editItem() {
      this.$router.push({
        name: "scheduling-config-tab-templates-template-edit",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,
        },
      });
    },
    deleteItem() {
      var res = confirm(
        "Bist Du sicher, dass Du diese Vorlage löschen möchtest?"
      );
      if (res == true) {
        this.$store.dispatch(`${SCHEDULING}${DELETE_EVENT_TEMPLATE}`, {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,
        });
      }
    },
  },
};
</script>

<style></style>
