import * as fb from "@/firebase";
// import { auth } from "../../firebase";
// import router from "../router/index";
// import { db } from "@/firebase";
import {
  GET_TIME_SHEETS,
  GET_PAYSLIPS,
  GET_PAYROLL_TYPES,
  GET_PAYROLL_GROUPS,
  GET_BATCHES,
  GET_ARCHIVED_TIME_SHEETS,
  // GET_ARCHIVED_PAYSLIPS,
  // GET_ARCHIVED_BATCHES,
  UPDATE_ACCOUNTING_CONFIG,
} from "../action-types";
import {
  SET_TIME_SHEETS,
  SET_BILLING_TYPES,
  SET_PAYROLL_GROUPS,
  SET_PAYSLIPS,
  SET_BATCHES,
  SET_ARCHIVED_TIME_SHEETS,
  // SET_ARCHIVED_PAYSLIPS,
  // SET_ARCHIVED_BATCHES,
  SET_LOADING,
} from "../mutation-types";
export default {
  namespaced: true,

  state: {
    timeSheets: [],
    payrollTypes: [],
    payrollGroups: [],
    batches: [],
    payslips: [],
    loading: false,
    archivedTimeSheets: [],
    archivedPayslips: [],
    archivedBatches: [],
  },

  mutations: {
    [SET_TIME_SHEETS](state, payload) {
      state.timeSheets = payload;
    },
    [SET_BILLING_TYPES](state, payload) {
      state.payrollTypes = payload;
    },
    [SET_PAYROLL_GROUPS](state, payload) {
      state.payrollGroups = payload;
    },
    [SET_BATCHES](state, payload) {
      state.batches = payload;
    },
    [SET_PAYSLIPS](state, payload) {
      state.payslips = payload;
    },
    [SET_LOADING](state, payload) {
      state.loading = payload;
    },
    [SET_ARCHIVED_TIME_SHEETS](state, payload) {
      state.archivedTimeSheets = payload;
    },
    // [SET_ARCHIVED_PAYSLIPS](state, payload) {
    //   state.archivedPayslips = payload;
    // }
    // [SET_ARCHIVED_BATCHES](state, payload) {
    //   state.archivedBatches = payload;
    // }
    // [MUTATION_NAME](state, payload) {
    //   state.var = payload;
    // },
  },

  actions: {
    async [GET_TIME_SHEETS]({ commit }, payload) {
      try {
        const data = [];
        const options = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        };
        let limit = 700;
        if (payload.paginationLimit !== undefined) {
          limit = payload.paginationLimit;
        }
        const baseReference = fb.organizationCollection
          .doc(payload.organizationId)
          .collection("timeSheets")
          .where("status", "in", [
            "toBeProcessed",
            "missingPayrollType",
            "inProgress",
            "toBeReviewed",
            "approved",
          ]);

        let reference = baseReference; // base case queries all entries
        if (payload.query.queryIsFiltered) {
          if (payload.query.filterByUid) {
            reference = reference.where("user.uid", "==", payload.uid);
          }
          if (payload.query.onlyOwn) {
            reference = reference.where(
              "user.uid",
              "==",
              fb.auth.currentUser.uid
            );
          }
        }

        const querySnapshot = await reference.limit(limit).get();

        querySnapshot.forEach((doc) => {
          const docData = doc.data();
          const formattedData = {
            ...docData,
            workedDuration: {
              start: {
                timestamp: docData.workedDuration.start.timestamp,
                formattedTimestamp: docData.workedDuration.start.timestamp
                  .toDate()
                  .toLocaleString("de-DE", options),
              },
              end: {
                timestamp: docData.workedDuration.end.timestamp,
                formattedTimestamp: docData.workedDuration.end.timestamp
                  .toDate()
                  .toLocaleString("de-DE", options),
              },
              inMinutes: docData.workedDuration.inMinutes,
              inHours: docData.workedDuration.inHours,
            },
            billableDuration: {
              start: {
                timestamp: docData.billableDuration.start.timestamp,
                formattedTimestamp: docData.billableDuration.start.timestamp
                  .toDate()
                  .toLocaleString("de-DE", options),
              },
              end: {
                timestamp: docData.billableDuration.end.timestamp,
                formattedTimestamp: docData.billableDuration.end.timestamp
                  .toDate()
                  .toLocaleString("de-DE", options),
              },
              inMinutes: docData.billableDuration.inMinutes,
              inHours: docData.billableDuration.inHours,
            },
          };
          data.push(formattedData);
        });

        commit(SET_TIME_SHEETS, data);
        return querySnapshot;
      } catch (error) {
        console.log("Error getting document:", error);
        throw error;
      }
    },

    async [GET_PAYSLIPS]({ commit }, payload) {
      try {
        const data = [];
        const options = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        };
        let limit = 300;
        if (payload.paginationLimit !== undefined) {
          limit = payload.paginationLimit;
        }
        const baseReference = fb.organizationCollection
          .doc(payload.organizationId)
          .collection("payslips");
        // .where("status", "in", [
        //   "toBeProcessed",
        //   "inProgress",
        //   "toBeReviewed",
        //   "approved",
        //   "rejected",
        // ]);

        let reference = baseReference; // base case queries all entries
        if (payload.query.queryIsFiltered) {
          if (payload.query.filterByUid) {
            reference = reference.where("user.uid", "==", payload.uid);
          }
          if (payload.query.onlyOwn) {
            reference = reference.where(
              "user.uid",
              "==",
              fb.auth.currentUser.uid
            );
          }
        }

        const querySnapshot = await reference.limit(limit).get();

        querySnapshot.forEach((doc) => {
          const docData = doc.data();
          const formattedData = {
            ...docData,
            period: {
              start: {
                timestamp: docData.period.start.timestamp,
                formattedTimestamp: docData.period.start.timestamp
                  .toDate()
                  .toLocaleString("de-DE", options),
              },
              end: {
                timestamp: docData.period.end.timestamp,
                formattedTimestamp: docData.period.end.timestamp
                  .toDate()
                  .toLocaleString("de-DE", options),
              },
            },
          };
          data.push(formattedData);
        });

        commit(SET_PAYSLIPS, data);
        return querySnapshot;
      } catch (error) {
        console.log("Error getting document:", error);
        throw error;
      }
    },

    async [GET_PAYROLL_TYPES]({ commit }, payload) {
      try {
        const data = [];
        const querySnapshot = await fb.organizationCollection
          .doc(payload.organizationId)
          .collection("payrollTypes")
          .get();

        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
        data.sort((a, b) => parseFloat(a.sortKey) - parseFloat(b.sortKey));
        commit(SET_BILLING_TYPES, data);
        return querySnapshot;
      } catch (error) {
        console.log("Error getting document:", error);
        throw error;
      }
    },

    async [GET_PAYROLL_GROUPS]({ commit }, payload) {
      try {
        const data = [];
        const querySnapshot = await fb.organizationCollection
          .doc(payload.organizationId)
          .collection("payrollGroups")
          .get();

        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
        data.sort((a, b) => parseFloat(a.sortKey) - parseFloat(b.sortKey));
        commit(SET_PAYROLL_GROUPS, data);
        return querySnapshot;
      } catch (error) {
        console.log("Error getting document:", error);
        throw error;
      }
    },

    async [GET_BATCHES]({ commit }, payload) {
      try {
        const data = [];
        const options = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        };
        const querySnapshot = await fb.organizationCollection
          .doc(payload.organizationId)
          .collection("payrollBatches")
          .get();

        querySnapshot.forEach((doc) => {
          const docData = doc.data();
          const formattedData = {
            ...docData,
            period: {
              start: {
                timestamp: docData.period.start.timestamp,
                formattedTimestamp: docData.period.start.timestamp
                  .toDate()
                  .toLocaleString("de-DE", options),
              },
              end: {
                timestamp: docData.period.end.timestamp,
                formattedTimestamp: docData.period.end.timestamp
                  .toDate()
                  .toLocaleString("de-DE", options),
              },
              // inMinutes: docData.period.inMinutes,
              // inHours: docData.period.inHours,
            },
          };
          data.push(formattedData);
        });
        commit(SET_BATCHES, data);
        return querySnapshot;
      } catch (error) {
        console.log("Error getting document:", error);
        throw error;
      }
    },

    [GET_ARCHIVED_TIME_SHEETS]({ commit }, payload) {
      const timeSheets = [];
      const options = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      let limit = 300;
      if (payload.paginationLimit !== undefined) {
        limit = payload.paginationLimit;
      }

      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("timeSheets")
        .where(
          "workedDuration.start.timestamp",
          ">=",
          fb.Timestamp.fromDate(new Date(payload.query.range.start))
        )
        .where(
          "workedDuration.start.timestamp",
          "<=",
          fb.Timestamp.fromDate(new Date(payload.query.range.end))
        )
        .orderBy("workedDuration.start.timestamp", "asc")
        .limit(limit)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const docData = doc.data();
            const formattedData = {
              ...docData,
              workedDuration: {
                start: {
                  timestamp: docData.workedDuration.start.timestamp,
                  formattedTimestamp: docData.workedDuration.start.timestamp
                    .toDate()
                    .toLocaleString("de-DE", options),
                },
                end: {
                  timestamp: docData.workedDuration.end.timestamp,
                  formattedTimestamp: docData.workedDuration.end.timestamp
                    .toDate()
                    .toLocaleString("de-DE", options),
                },
                inMinutes: docData.workedDuration.inMinutes,
                inHours: docData.workedDuration.inHours,
              },
              billableDuration: {
                start: {
                  timestamp: docData.billableDuration.start.timestamp,
                  formattedTimestamp: docData.billableDuration.start.timestamp
                    .toDate()
                    .toLocaleString("de-DE", options),
                },
                end: {
                  timestamp: docData.billableDuration.end.timestamp,
                  formattedTimestamp: docData.billableDuration.end.timestamp
                    .toDate()
                    .toLocaleString("de-DE", options),
                },
                inMinutes: docData.billableDuration.inMinutes,
                inHours: docData.billableDuration.inHours,
              },
            };
            timeSheets.push(formattedData);
          });
          commit(SET_ARCHIVED_TIME_SHEETS, timeSheets);
        })
        .catch((error) =>
          console.error("Error fetching archived time sheets:", error)
        );
    },

    async [UPDATE_ACCOUNTING_CONFIG]({ commit }, payload) {
      let updateData = {};
      if (payload.type === "onlyTemplateConfig") {
        updateData = {
          "config.accounting.timeSheets.titleTemplates":
            payload.timeSheets.titleTemplates,
        };
      }
      try {
        await fb.organizationCollection.doc(payload.organizationId).update({
          ...updateData,
          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        });
        commit(SET_LOADING, false);
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    // [ACTION_NAME]({ commit }, payload) {
    //   ... SOME MAGIC HAPPENS HERE ...
    //   commit(MUTATION_NAME, withSomeData);
    // },
  },

  getters: {},
};
