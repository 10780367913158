<template>
  <div>
    <section>
      <TippCard
        hintID="Admin_Benutzer_Tab_Benutzerkonten_1_V1"
        text="Benutzer bilden die Mitglieder/Teilnehmer deiner Organisation ab. Sie verwalten ihr persönliches Profil selbstständig. Berechtigungen, Qualifikationen etc. können an dieser Stelle jedoch bearbeitet werden. Neue Benutzer können per E-Mail eingeladen werden. Optional können bereits Berechtigungen, Qualifikationen etc. für den Nutzer konfiguriert werden. benutzer lassen sich aus der Organisation auch wieder entfernen."
      >
      </TippCard>
    </section>
    <section>
      <template>
        <v-container fluid>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-account-multiple"
                title="Benutzerkonten"
              ></Subheader>
              <div class="mt-7">
                <active-accounts-table
                  :activeAccounts="accounts"
                ></active-accounts-table>
              </div>
            </v-col>
            <v-col cols="12" v-if="supportAccounts.length > 0">
              <Subheader
                icon="mdi-account-wrench"
                title="Support- und Servicekonten"
              ></Subheader>
              <div class="mt-7">
                <support-accounts-table
                  :supportAccounts="supportAccounts"
                ></support-accounts-table>
              </div>
            </v-col>
            <v-col cols="12" v-if="archivedAccounts.length > 0">
              <Subheader
                icon="mdi-archive"
                title="Archivierte Benutzer"
              ></Subheader>
              <div class="mt-7">
                <archived-accounts-table
                  :archivedAccounts="archivedAccounts"
                ></archived-accounts-table>
              </div>
            </v-col>
            <v-col cols="12">
              <Subheader
                icon="mdi-account-clock"
                title="Eingeladene Benutzer"
                :actions="invitesActions"
              ></Subheader>
              <div class="mt-7">
                <invites-table :invites="invites"></invites-table>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import ActiveAccountsTable from "@/components/admin/users/accounts/ActiveAccountsTable.vue";
import ArchivedAccountsTable from "@/components/admin/users/accounts/ArchivedAccountsTable.vue";
import SupportAccountsTable from "@/components/admin/users/accounts/SupportAccountsTable.vue";
import InvitesTable from "@/components/admin/users/accounts/InvitesTable.vue";
import { ADMIN } from "@/store/modules.js";
import { GET_USER_LIST, GET_INVITES } from "@/store/action-types.js";
import { ADMINISTRATION_USERS_INVITES_create } from "@/data/permission-types.js";

export default {
  name: "admin-users-tab-accounts",
  components: {
    TippCard,
    Subheader,
    ActiveAccountsTable,
    ArchivedAccountsTable,
    SupportAccountsTable,
    InvitesTable,
  },
  data() {
    return {
      search: "",

      invitesActions: [
        {
          title: "Benutzer einladen",
          icon: "mdi-account-plus",
          permission: `${ADMINISTRATION_USERS_INVITES_create}`,
          actionStyle: "textBtn",
          function: this.inviteUsers,
        },
      ],
    };
  },
  computed: {
    accounts() {
      const filtered = this.$store.state.admin.membershipsPublic.filter(
        function (obj) {
          return (
            obj.access.type == "member" && obj.access.status == "activated"
          );
        }
      );
      return filtered;
    },
    archivedAccounts() {
      const filtered = this.$store.state.admin.membershipsPublic.filter(
        function (obj) {
          return obj.access.status == "archived";
        }
      );

      var output = [];
      if (filtered.length > 0) {
        output = filtered.map((obj) => ({
          ...obj,
          // Set archivation date
        }));
      }
      return output;
    },
    supportAccounts() {
      const filtered = this.$store.state.admin.membershipsPublic.filter(
        function (obj) {
          return (
            obj.access.type == "support" || obj.access.type == "it-service"
          );
        }
      );
      return filtered;
    },
    invites() {
      return this.$store.state.admin.invites;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${ADMIN}${GET_USER_LIST}`, {
        organizationId: this.$route.params.organizationId,
        queryIsFiltered: false,
      });
      this.$store.dispatch(`${ADMIN}${GET_INVITES}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    inviteUsers() {
      this.$router.push({ name: "admin-users-tab-accounts-invite-new" });
    },
  },
};
</script>
