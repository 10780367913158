<template>
  <div>
    <section>
      <v-container class="fill-height py-8" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <div class="v-responsive" style="max-width: 700px">
              <div class="v-responsive__content">
                <div class="text-overline secondary--text mb-3">Mira One</div>
                <h2 class="text-h3 mb-6">Konto erstellen</h2>
              </div>
            </div>
            <v-card max-width="600px" class="pa-4">
              <v-card-text>
                <v-form v-model="formIsValid" @submit.prevent>
                  <v-text-field
                    id="email2"
                    v-model.trim="signupForm.email"
                    label="E-Mail-Adresse"
                    outlined
                    type="email"
                    autocomplete="email"
                    :rules="[rules.required, rules.emailFormat]"
                    placeholder="name@email.de"
                  ></v-text-field>
                  <v-text-field
                    id="password2"
                    v-model.trim="signupForm.password"
                    label="Passwort"
                    outlined
                    type="password"
                    autocomplete="current-password"
                    :rules="[
                      rules.required,
                      rules.passwordFormat,
                      rules.passwordLength,
                    ]"
                    placeholder="••••••••••••••••"
                  ></v-text-field>
                  <v-checkbox
                    required
                    :rules="[rules.requiredApproval]"
                    v-model="checkbox"
                    class="pb-4"
                  >
                    <template v-slot:label>
                      <div class="ml-2 body-2">
                        Ich bestätige, dass ich die
                        <a
                          target="_blank"
                          href="https://docs.mira-one.com/agb"
                          @click.stop
                          >Allgemeinen Geschäftsbedingungen</a
                        >
                        und
                        <a
                          target="_blank"
                          href="https://docs.mira-one.com/datenschutz"
                          @click.stop
                          >Datenschutzerklärung</a
                        >
                        gelesen und akzeptiert habe und in die Verarbeitung
                        personenbezogener Daten eingewilligt habe.
                      </div>
                    </template>
                  </v-checkbox>
                </v-form>
                <!-- color="#2a4f66" -->
                <v-btn
                  color="red darken-1"
                  :disabled="!formIsValid"
                  :loading="loading"
                  block
                  x-large
                  depressed
                  @click="signup()"
                  class="white--text"
                >
                  Konto erstellen
                </v-btn>
                <div class="text-center mt-3 text-subtitle-1">
                  <router-link
                    :to="{ name: 'auth-login' }"
                    class="text-decoration-none"
                    style="color: grey"
                    >Du hast bereits ein Konto?</router-link
                  >
                </div>
              </v-card-text>
              <v-card-text v-if="errorMsg !== ''">
                <v-alert dense text type="error"> {{ errorMsg }} </v-alert>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { USER } from "@/store/modules.js";
import { SIGN_UP } from "@/store/action-types.js";
import { handleAuthError } from "@/util/firebase/FirebaseErrorHandling.js";
export default {
  name: "auth-register",
  components: {},
  data() {
    return {
      signupForm: {
        name: "",
        email: "",
        password: "",
      },
      formIsValid: false,
      loading: false,
      checkbox: false,
      errorMsg: "",
      rules: {
        emailFormat: (value) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          ) || "Ungültige E-Mail-Adresse",
        passwordFormat: (value) =>
          /^((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]))/.test(value) ||
          "Passwort muss mind. einen Buchstaben, eine Ziffer und ein Sonderzeichen enthalten und mind. 8 Zeichen lang sein",
        passwordLength: (value) =>
          (value && value.length >= 8) ||
          "Passwort muss mindestens 8 Zeichen lang sein",
        required: (value) => !!value || "Erforderlich",
        requiredApproval: (value) =>
          !!value ||
          "Du musst die Nutzungsbedingungen und Datenschutzbestimmungen lesen und diesen zustimmen, um fortzufahren.",
      },
    };
  },

  methods: {
    signup() {
      if (this.formIsValid) {
        this.loading = true;
        // Dispatch to Vuex to handle user signup
        this.$store
          .dispatch(`${USER}${SIGN_UP}`, {
            name: this.signupForm.name,
            email: this.signupForm.email,
            password: this.signupForm.password,
          })
          .catch((error) => {
            this.errorMsg = handleAuthError(error.code);
            console.log(error.code, error.message);
            this.loading = false;
          });
      } else {
        alert("Fehler: Prüfe Deine Eingabe.");
      }
    },
  },
};
</script>
