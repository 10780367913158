<template>
  <div>
    <NavigationBar
      titel="Aufgabenverteilung"
      :actions="actions"
    ></NavigationBar>
    <TippCard
      hintID="Mitgliederverwaltung_Aufgabenverteilung_1_V1"
      text="Um Aufgaben und Verantwortung innerhalb der Organisation gleichmäßig zu verteilen, können Aufgabenbereiche angelegt und zugewiesen werden. Dazu können eine Tätigkeitsbeschreibung und Kontaktdaten angegeben werden. Eine Optimalbesetzung, eine Festlegung der Priorität und ein Aufwandsschlüssel helfen Aufgabenbereiche im Ressourcenplaner gut zu verteilen."
    >
    </TippCard>
    <section>
      <template>
        <v-container fluid>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader icon="mdi-briefcase" title="Aufgabenbereiche">
              </Subheader>
              <div class="mt-7">
                <!-- v-model="leavesStateFilter" -->
                <v-data-table
                  :headers="headers"
                  :items="assignments"
                  item-key="meta.id"
                  sort-by="priorityScore"
                  :sort-desc="false"
                  class="elevation-2"
                  max-height="800px auto"
                  no-data-text="Keine Aufgabenbereiche vorhanden"
                  @click:row="openDetails"
                >
                  <template v-slot:[`item.icon`]="{ item }">
                    <data-avatar
                      :icon="item.icon"
                      :color="item.color"
                      small
                    ></data-avatar>
                  </template>
                  <template v-slot:[`item.title`]="{ item }">
                    {{ item.title
                    }}<v-icon v-if="item.isHidden" small class="ml-1"
                      >mdi-eye-off</v-icon
                    >
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <assignment-status-chip
                      :count="item.assignees.length"
                      :minThreshold="item.analysis.minAssignees"
                      :maxThreshold="item.analysis.maxAssignees"
                    ></assignment-status-chip>
                  </template>
                  <!-- <template v-slot:[`item.title`]="{ item }">
                    <div class="d-flex flex-column">
                      <span
                        class="d-block font-weight-semibold text--primary text-truncate"
                        >{{ item.title }}
                      </span>
                      <small>Text</small>
                    </div>
                  </template> -->
                </v-data-table>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import DataAvatar from "@/components/_systemwide/DataAvatar.vue";
import AssignmentStatusChip from "@/components/member-management/assignments/AssignmentStatusChip.vue";
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import { GET_ASSIGNMENTS } from "@/store/action-types.js";
import { MEMBER_MANAGEMENT_ASSIGNMENTS_create } from "@/data/permission-types.js";

export default {
  name: "member-management-assignments",
  components: {
    NavigationBar,
    Subheader,
    SupportTools,
    TippCard,
    DataAvatar,
    AssignmentStatusChip,
  },
  data() {
    return {
      actions: [
        {
          title: "Erstellen",
          permission: `${MEMBER_MANAGEMENT_ASSIGNMENTS_create}`,
          icon: "mdi-plus-circle",
          actionStyle: "textBtn",
          function: this.createItem,
        },
        {
          title: "Ressourcenplaner",
          permission: `${MEMBER_MANAGEMENT_ASSIGNMENTS_create}`,
          icon: "mdi-chart-timeline-variant-shimmer",
          actionStyle: "textBtn",
          function: this.openPlanner,
        },
      ],
      headers: [
        { value: "icon", divider: false },
        { text: "Titel", value: "title", divider: true },
        { text: "Priorität", value: "priorityScore", divider: true },
        { text: "Aufwand", value: "workloadScore", divider: true },
        {
          text: "Soll-Besetzung (Min.)",
          value: "analysis.minAssignees",
          divider: true,
        },
        {
          text: "Soll-Besetzung (Max.)",
          value: "analysis.maxAssignees",
          divider: true,
        },
        {
          text: "Ist-Besetzung",
          value: "analysis.currentAssignees",
          divider: true,
        },
        { text: "Status", value: "status", divider: true },
      ],
    };
  },
  computed: {
    assignments() {
      return this.$store.state.memberManagement.assignments;
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [{ title: "assignments", data: this.assignments }];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_ASSIGNMENTS}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    createItem() {
      this.$router.push({
        name: "member-management-assignments-assignment-new",
        params: {
          organizationId: this.$route.params.organizationId,
        },
      });
    },
    openDetails(item) {
      this.$router.push({
        name: "member-management-assignments-assignment-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item.meta.id,
        },
      });
    },
    openPlanner(id) {
      this.$router.push({
        name: "member-management-assignments-planner",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: id,
        },
      });
    },
  },
};
</script>
