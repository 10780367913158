var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',[_c('TippCard',{attrs:{"hintID":"Admin_Sicherheit_Zuordnung_1_V1","text":"Einem Benutzer können mehrere Rollen zugeordnet werden. Überschneiden sich Berechtigungen erhält der Benutzer den Zugriff, wenn zumindest eine Rolle diesen gewährt."}})],1),_c('section',[[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('Subheader',{attrs:{"icon":"mdi-security","title":"Zuordnungsmatrix"}}),_c('div',{staticClass:"mt-7"},[_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.computedHeaders,"items":_vm.membershipsPrivate,"fixed-header":"","height":"600px","group-by":"groupBy","dense":"","items-per-page":50,"footer-props":_vm.footerProps},scopedSlots:_vm._u([{key:`group.header`,fn:function({ items, isOpen, toggle }){return [_c('th',{attrs:{"colspan":_vm.computedHeaders.length}},[_c('v-icon',{staticClass:"mr-2",on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? "mdi-chevron-up" : "mdi-chevron-down")+" ")]),_vm._v(" "+_vm._s(_vm.stripPrefix(items[0].groupBy))+" ")],1)]}},{key:`item.user.displayName`,fn:function({ item }){return [_c('router-link',{staticClass:"text-decoration-none primary--text",attrs:{"to":{
                        name: 'admin-users-tab-accounts-account-details',
                        params: {
                          organizationId: _vm.$route.params.organizationId,
                          itemId: item.user.uid,
                        },
                      }}},[_vm._v(_vm._s(item.user.displayName))])]}},{key:`item.checkbox`,fn:function({ item, header }){return [_c('v-simple-checkbox',{attrs:{"disabled":"","ripple":false},model:{value:(item[header.id]),callback:function ($$v) {_vm.$set(item, header.id, $$v)},expression:"item[header.id]"}})]}}],null,true)})],1)],1)],1)],1)],1)]],2),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }