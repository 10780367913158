export const importableHonors = [
  {
    title:
      "Stufe 1: Silbernes Feuerwehr- und Katastrophenschutz-Ehrenzeichen am Bande für 10-jährige ehrenamtliche Tätigkeit",
    shortTitle: "Stufe 1 (10J)",
    isActive: true,
    sortKey: 1,
    state: "Berlin",
    badgeUrl: {
      size70x70: null,
      size125x125: null,
    },
    isImported: true,
    importVersion: 1,
    importId: "bln.st1",
  },
  {
    title:
      "Stufe 2: Goldenes Feuerwehr- und Katastrophenschutz-Ehrenzeichen am Bande für 25-jährige ehrenamtliche Tätigkeit",
    shortTitle: "Stufe 2 (25J)",
    isActive: true,
    sortKey: 2,
    state: "Berlin",
    badgeUrl: {
      size70x70: null,
      size125x125: null,
    },
    isImported: true,
    importVersion: 1,
    importId: "bln.st2",
  },
  {
    title:
      "Stufe 3: Goldenes Feuerwehr- und Katastrophenschutz-Ehrenzeichen am Bande mit der Zahl 40 in gekreuzten Eichenlaub für 40-jährige ehrenamtliche Tätigkeit",
    shortTitle: "Stufe 3 (40J)",
    isActive: true,
    sortKey: 3,
    state: "Berlin",
    badgeUrl: {
      size70x70: null,
      size125x125: null,
    },
    isImported: true,
    importVersion: 1,
    importId: "bln.st3",
  },
  {
    title:
      "Stufe 4: Goldenes Feuerwehr- und Katastrophenschutz-Ehrenzeichen am Bande mit der Zahl 50 in gekreuzten Eichenlaub für 50-jährige ehrenamtliche Tätigkeit",
    shortTitle: "Stufe 4 (50J)",
    isActive: true,
    sortKey: 4,
    state: "Berlin",
    badgeUrl: {
      size70x70: null,
      size125x125: null,
    },
    isImported: true,
    importVersion: 1,
    importId: "bln.st4",
  },
  {
    title:
      "Stufe 5: Goldenes Feuerwehr- und Katastrophenschutz-Ehrenzeichen am Bande mit der Zahl 60 in gekreuzten Eichenlaub für 60-jährige ehrenamtliche Tätigkeit",
    shortTitle: "Stufe 5 (60J)",
    isActive: true,
    sortKey: 5,
    state: "Berlin",
    badgeUrl: {
      size70x70: null,
      size125x125: null,
    },
    isImported: true,
    importVersion: 1,
    importId: "bln.st5",
  },
  {
    title:
      "Goldenes Feuerwehr- und Katastrophenschutz-Ehrenzeichen als Steckkreuz",
    shortTitle: "Sonderstufe",
    isActive: true,
    sortKey: 6,
    state: "Berlin",
    badgeUrl: {
      size70x70: null,
      size125x125: null,
    },
    isImported: true,
    importVersion: 1,
    importId: "bln.st6",
  },
];
