<template>
  <div>
    <v-skeleton-loader
      v-if="loading"
      elevation="2"
      type="article, divider, list-item-avatar, list-item-avatar, list-item-avatar"
    ></v-skeleton-loader>
    <v-card
      v-else
      :min-width="minWidth"
      :max-width="maxWidth"
      :outlined="outlined"
    >
      <v-card-title>
        <span>{{ event.title }}</span>
        <v-spacer></v-spacer>
        <v-chip
          v-if="event.status !== 'planned'"
          :color="statusColor(event.status)"
          :class="statusTextClass(event.status)"
          >{{ statusText(event.status) }}</v-chip
        >
        <v-btn
          v-if="showCloseButton"
          :color="$vuetify.theme.dark ? '' : 'secondary'"
          icon
          @click="$emit('closeEvent')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle>
        <data-chip small :color="event.type.color" :text="event.type.title">
        </data-chip>
      </v-card-subtitle>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-clock-start</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle>
          <span class="mr-4">
            {{ timestampConverter(event.duration.start.timestamp) }}
            Uhr
          </span>
          <br class="hidden-md-and-up uppercase" />
          <v-chip
            v-if="event.duration.start.isOpen"
            :x-small="mobile"
            :small="!mobile"
            class="font-weight-medium red--text text--darken-4"
            color="red lighten-4"
            >Startzeit noch offen</v-chip
          >
        </v-list-item-subtitle>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-clock-end</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle>
          <span class="mr-4"
            >{{ timestampConverter(event.duration.end.timestamp) }} Uhr</span
          >
          <br class="hidden-md-and-up uppercase" />
          <v-chip
            v-if="event.duration.end.isOpen"
            :x-small="mobile"
            :small="!mobile"
            class="font-weight-medium red--text text--darken-4"
            color="red lighten-4"
            >Endzeit noch offen</v-chip
          >
        </v-list-item-subtitle>
      </v-list-item>
      <v-list-item v-if="event.location.name">
        <v-list-item-icon>
          <v-icon>mdi-map-marker</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle>{{ event.location.name }}</v-list-item-subtitle>
      </v-list-item>
      <v-list-item
        v-if="event.requiredPPE !== null && event.requiredPPE.length > 0"
      >
        <v-list-item-icon>
          <v-icon>mdi-tshirt-crew</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle
          >Einsatzkleidung/PSA:
          <v-chip
            v-for="(item, index) in event.requiredPPE"
            :key="index"
            label
            small
            class="ml-2 my-2"
            >{{ item }}</v-chip
          ></v-list-item-subtitle
        >
      </v-list-item>
      <v-list-item v-if="event.description">
        <v-list-item-icon>
          <v-icon>mdi-calendar-text</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle style="white-space: normal">{{
          event.description
        }}</v-list-item-subtitle>
      </v-list-item>
      <v-list-item v-if="event.config.organizer.isAppointed">
        <v-list-item-icon>
          <v-icon>mdi-account-hard-hat</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle style="white-space: normal"
          >Organisation:
          <v-chip
            v-if="
              event.config.organizer.description !== null &&
              event.config.organizer.description !== ''
            "
            label
            small
            class="ml-2 my-2"
            >{{ event.config.organizer.description }}</v-chip
          >
          <v-chip
            v-for="item in event.config.organizer.personData"
            :key="item.uid"
            label
            small
            class="ml-2 my-2"
            ><v-icon small left>mdi-account-circle</v-icon
            >{{ item.displayName }}</v-chip
          >
        </v-list-item-subtitle>
      </v-list-item>
      <v-list-item v-if="event.config.attendance.isLimited">
        <v-list-item-icon>
          <v-icon>mdi-account-multiple-plus</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle style="white-space: normal"
          >Veranstaltung für:
          <v-chip
            v-if="
              event.config.attendance.description !== null &&
              event.config.attendance.description !== ''
            "
            label
            small
            class="ml-2 my-1"
            >{{ event.config.attendance.description }}</v-chip
          >
          <v-chip
            v-for="item in event.config.attendance.allowedDivisions"
            :key="item.id"
            label
            small
            class="ml-2 my-1"
            ><v-icon small left>mdi-home-group</v-icon>{{ item.title }}</v-chip
          >
          <v-chip
            v-for="item in event.config.attendance.allowedUserGroups"
            :key="item.id"
            label
            small
            class="ml-2 my-1"
            ><v-icon small left>mdi-folder-account</v-icon
            >{{ item.title }}</v-chip
          ><v-chip
            v-for="item in event.config.attendance.allowedPersons"
            :key="item.uid"
            label
            small
            class="ml-2 my-1"
            ><v-icon small left>mdi-account-circle</v-icon
            >{{ item.displayName }}</v-chip
          ></v-list-item-subtitle
        >
      </v-list-item>
      <v-list-item v-if="event.units.length > 0">
        <v-list-item-icon>
          <v-icon>mdi-fire-truck</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle style="white-space: normal"
          >Einsatzmittel:
          <v-chip
            v-for="unit in event.units"
            :key="unit.id"
            label
            small
            class="ml-2 my-1"
            >{{ unit.title }}</v-chip
          >
        </v-list-item-subtitle>
      </v-list-item>
      <v-list-item v-if="event.connectedRequestId">
        <v-list-item-icon>
          <v-icon>mdi-list-status</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle style="white-space: normal">
          <v-btn
            small
            depressed
            :to="{
              name: 'scheduling-requests-details-tab-general',
              params: { itemId: event.connectedRequestId },
            }"
            ><v-icon small class="mr-1">mdi-link-variant</v-icon>Verknüpfte
            Abfrage öffnen</v-btn
          >
        </v-list-item-subtitle>
      </v-list-item>
      <v-list-item v-if="event.connectedDutyReportId">
        <v-list-item-icon>
          <v-icon>mdi-file-table</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle style="white-space: normal">
          <v-btn
            small
            depressed
            :to="{
              name: 'duty-management-reports-details',
              params: { itemId: event.connectedDutyReportId },
            }"
            ><v-icon small class="mr-1">mdi-link-variant</v-icon>Verknüpften
            Dienstbericht öffnen</v-btn
          >
        </v-list-item-subtitle>
      </v-list-item>
      <v-card-actions v-if="showDetailsButton"
        ><v-spacer></v-spacer
        ><v-btn
          small
          depressed
          :to="{
            name: 'scheduling-event-details-tab-general',
            params: { itemId: event.meta.id },
          }"
          ><v-icon small class="mr-1">mdi-information-outline</v-icon
          >Details</v-btn
        ></v-card-actions
      >
    </v-card>
  </div>
</template>

<script>
import DataChip from "@/components/_systemwide/DataChip.vue";
export default {
  name: "event-details-card",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    minWidth: {
      required: false,
      default: undefined,
    },
    maxWidth: {
      required: false,
      default: undefined,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    showDetailsButton: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    event: {
      type: Object,
    },
  },
  components: { DataChip },
  methods: {
    statusText(status) {
      switch (status) {
        case "planned":
          return "Geplant";
        case "postponed":
          return "Verschoben";
        case "canceled":
          return "Abgesagt";
        case "finished":
          return "Abgeschlossen";
        case "deletion started":
          return "Löschung gestartet";
        default:
          break;
      }
    },
    statusColor(status) {
      switch (status) {
        case "planned":
          return "blue lighten-4";
        case "postponed":
          return "orange lighten-4";
        case "canceled":
          return "red lighten-4";
        case "finished":
          return "green lighten-4";
        case "deletion started":
          return "red lighten-4";
        default:
          break;
      }
    },
    statusTextClass(status) {
      switch (status) {
        case "planned":
          return "font-weight-medium blue--text text--darken-4";
        case "postponed":
          return "font-weight-medium orange--text text--darken-4";
        case "canceled":
          return "font-weight-medium red--text text--darken-4";
        case "finished":
          return "font-weight-medium green--text text--darken-4";
        case "deletion started":
          return "font-weight-medium red--text text--darken-4";
        default:
          break;
      }
    },
    timestampConverter(timestamp) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
  },
  computed: {
    attendeeItems() {
      return [];
    },
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
  },
};
</script>
