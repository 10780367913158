var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.response.status.id != '-2')?_c('v-card',{staticClass:"d-flex align-center text-center",class:[
      _vm.$vuetify.theme.dark ? 'border-dark' : 'border-light',
      _vm.getStatusColor(
        _vm.response.status.id,
        _vm.$vuetify.theme.dark ? 'dark' : 'light',
        'background'
      ),
    ],attrs:{"flat":"","height":"42px"},on:{"click":_vm.toggleResponseDetailDialog}},[_c('v-card-title',[_c('v-icon',{attrs:{"color":_vm.getStatusColor(
            _vm.response.status.id,
            _vm.$vuetify.theme.dark ? 'dark' : 'light',
            'text'
          )}},[_vm._v(_vm._s(_vm.getStatusIcon(_vm.response.status.id)))])],1),_c('v-card-text',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.response.comment)?_c('v-icon',_vm._g(_vm._b({staticClass:"px-1",attrs:{"small":"","light":""}},'v-icon',attrs,false),on),[_vm._v("mdi-comment-text")]):_vm._e()]}}],null,false,2179455893)},[_c('span',[_vm._v(_vm._s(_vm.response.comment))])]),(_vm.response.isAccepted)?_c('v-icon',{staticClass:"px-1",attrs:{"small":"","light":""}},[_vm._v("mdi-star")]):_vm._e(),(_vm.response.guests.count !== 0)?_c('v-icon',{staticClass:"px-1",attrs:{"small":"","light":""}},[_vm._v("mdi-account-group")]):_vm._e(),(_vm.response.assignedTasks.length)?_c('v-icon',{staticClass:"px-1",attrs:{"small":"","light":""}},[_vm._v("mdi-clipboard-list")]):_vm._e()],1)],1):_vm._e(),(_vm.showResponseDetailDialog)?_c('CustomDialog',{attrs:{"title":"Rückmeldung","fullscreenOnMobile":"","width":"s","showCloseBtn":"","hideCloseBtn":"","hideActionBtn":""},on:{"close":function($event){return _vm.toggleResponseDetailDialog()}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('br'),_c('event-response-details-card',{attrs:{"event":_vm.event,"response":_vm.response,"user":_vm.user,"outlined":""}})]},proxy:true}],null,false,2847343210)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }