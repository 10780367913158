<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="timeSheetsOutput"
      item-key="meta.id"
      multi-sort
      sort-by="billableDuration.start.timestamp"
      :items-per-page="25"
      :footer-props="footerProps"
      :search="search"
      groupBy="groupBy"
      class="elevation-2"
      max-height="800px auto"
      @click:row="routeDetails"
      no-data-text="Keine Stundenmeldungen vorhanden"
      no-results-text="Keine Stundenmeldungen gefunden"
    >
      <template v-slot:top>
        <v-card flat>
          <v-card-subtitle>
            <v-row>
              <v-col v-if="showAllTimeSheets" cols="12" sm="3" md="3">
                <v-text-field
                  v-model="search"
                  outlined
                  append-icon="mdi-magnify"
                  label="Nach Personen suchen"
                  :disabled="timeSheetsPersonFilter === 'own'"
                  hide-details="auto"
                  clearable
                  :dense="$vuetify.breakpoint.smAndDown"
                ></v-text-field>
              </v-col>
              <v-col
                v-if="showAllTimeSheets"
                :class="$vuetify.breakpoint.smAndUp ? 'ml-2' : ''"
              >
                <span class="mr-4">Filter nach Typ</span>
                <v-chip-group
                  v-model="timeSheetsPersonFilter"
                  mandatory
                  active-class="primary--text"
                >
                  <v-chip
                    label
                    value="all"
                    class="transparent-background font-weight-medium mr-2"
                    >Alle
                  </v-chip>
                  <v-chip
                    label
                    value="own"
                    class="transparent-background font-weight-medium"
                    >Eigene</v-chip
                  >
                </v-chip-group>
              </v-col>
              <v-col>
                <span class="mr-4">Filter nach Quelle</span>
                <v-chip-group
                  v-model="timeSheetsSourceFilter"
                  mandatory
                  active-class="primary--text"
                >
                  <v-chip
                    label
                    value="all"
                    class="transparent-background font-weight-medium mr-2"
                    >Alle</v-chip
                  >
                  <v-chip
                    label
                    value="manual"
                    class="transparent-background font-weight-medium mr-2"
                    >Manuell</v-chip
                  >
                  <v-chip
                    label
                    value="dutyReport"
                    class="transparent-background font-weight-medium mr-2"
                    >Dienstbericht</v-chip
                  >
                </v-chip-group>
              </v-col>
              <v-col>
                <span class="mr-4">Filter nach Status</span>
                <v-chip-group
                  v-model="timeSheetsStateFilter"
                  mandatory
                  active-class="primary--text"
                >
                  <v-chip
                    label
                    value="all"
                    class="transparent-background font-weight-medium mr-2"
                    >Alle</v-chip
                  >
                  <v-chip
                    label
                    value="toBeReviewed"
                    class="transparent-background font-weight-medium mr-2"
                    >Zu prüfen</v-chip
                  >
                  <v-chip
                    label
                    value="missingPayrollType"
                    class="transparent-background font-weight-medium mr-2"
                    >Fehlerhaft</v-chip
                  >
                </v-chip-group>
              </v-col>
              <v-col>
                <span class="mr-4">Filter nach Abrechnungsart</span>
                <v-select
                  v-model="timeSheetsPayrollTypeFilter"
                  :items="payrollTypesList"
                  item-text="title"
                  item-value="meta.id"
                  multiple
                  outlined
                  hide-details="auto"
                  dense
                  small-chips
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @mousedown.prevent
                      @click="selectAllPayrollTypes"
                    >
                      <v-list-item-action>
                        <v-icon
                          :color="
                            timeSheetsPayrollTypeFilter.length > 0
                              ? 'primary'
                              : ''
                          "
                        >
                          {{ payrollTypeSelectorIcon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title> Alle auswählen </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0" small label>
                      <span>{{ item.title }}</span>
                    </v-chip>
                    <span
                      v-if="index === 1"
                      small
                      label
                      class="grey--text text-caption"
                    >
                      (+{{ timeSheetsPayrollTypeFilter.length - 1 }} weitere)
                    </span>
                  </template></v-select
                >
              </v-col>
              <v-col
                ><table-column-selector-menu
                  v-if="showAllTimeSheets"
                  v-model="headers"
                  :headers="allColumnHeaders"
                >
                </table-column-selector-menu
              ></v-col>
            </v-row>
          </v-card-subtitle>
        </v-card>
        <v-divider></v-divider>
      </template>

      <template v-slot:group.header="{ items, isOpen, toggle }">
        <th :colspan="headers.length">
          <div class="my-1">
            <v-icon @click="toggle" class="mr-1"
              >{{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
            </v-icon>
            {{ stripPrefix(items[0].groupBy) }}
            <span class="font-weight-regular grey--text"
              >({{ getCountOfTimeSheetsInMonth(items[0].groupBy) }})</span
            >
          </div>

          <v-divider></v-divider>
          <div class="my-1">
            <small class="caption font-weight-regular"
              >{{ items.length }} angezeigte Einträge in Monat mit einer Summe
              von {{ getHoursSum(items) }} abrechenbaren Stunden und einem
              berechneten Aufwand in Höhe von
              {{ getCompensationSum(items) }} EUR</small
            >
          </div>
        </th>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <time-sheet-type-chip :type="item.type" small> </time-sheet-type-chip>
      </template>
      <template v-slot:[`item.payrollType.group.title`]="{ item }">
        <v-chip v-if="item.payrollType.group.title" small outlined label>{{
          item.payrollType.group.title
        }}</v-chip>
      </template>
      <template v-slot:[`item.additionalData.assignedPosition`]="{ item }">
        <span
          v-if="
            item.additionalData?.assignedPosition?.unit?.title &&
            item.additionalData?.assignedPosition?.position?.shortTitle
          "
        >
          {{ item.additionalData.assignedPosition.unit.title }} –
          {{ item.additionalData.assignedPosition.position.shortTitle }}
        </span>
      </template>
      <template v-slot:[`item.payrollType`]="{ item }">
        <v-chip v-if="item.payrollType.title" small outlined label>{{
          item.payrollType.title
        }}</v-chip>
      </template>
      <template v-slot:[`item.billableDuration.start.timestamp`]="{ item }">
        {{ item.billableDuration.start.formattedTimestamp }}
      </template>
      <template v-slot:[`item.billableDuration.end.timestamp`]="{ item }">
        {{ item.billableDuration.end.formattedTimestamp }}
      </template>
      <template #[`item.status`]="{ item }">
        <time-sheet-status-chip :status="item.status" small>
        </time-sheet-status-chip>
      </template>
      <template #[`item.totalSalary.amount`]="{ item }">
        <pre>{{ item.totalSalary.amount }} {{ item.totalSalary.currency }}</pre>
      </template>
      <template #[`item.totalSalary.baseVariables.HOURS`]="{ item }">
        {{ item.totalSalary.baseVariables.HOURS }}
      </template>
      <template #[`item.actions`]="{ item }"
        ><v-btn
          v-if="showActionBtn"
          depressed
          @click="openTimeSheet(item, 'newTab')"
          small
          ><v-icon left small>mdi-open-in-app</v-icon>Öffnen</v-btn
        ><v-menu v-else offset-y left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon text v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="routeDetails(item)">
              <v-list-item-icon>
                <v-icon dense>mdi-open-in-app</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Stundenmeldung öffnen</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item link @click="openTimeSheet(item, 'newTab')">
              <v-list-item-icon>
                <v-icon dense>mdi-open-in-new</v-icon>
              </v-list-item-icon>
              <v-list-item-title
                >Stundenmeldung öffnen (in neuem Tab)</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { auth } from "@/firebase";
import TableColumnSelectorMenu from "@/components/_system/helpers/TableColumnSelectorMenu.vue";
import TimeSheetStatusChip from "@/components/accounting/time-sheets/TimeSheetStatusChip.vue";
import TimeSheetTypeChip from "@/components/accounting/time-sheets/TimeSheetTypeChip.vue";
import { ACCOUNTING_TIME_SHEETS_read_all } from "@/data/permission-types.js";
import { ACCOUNTING } from "@/store/modules.js";
import { GET_PAYROLL_TYPES } from "@/store/action-types.js";
export default {
  name: "time-sheets-data-table",
  props: {
    timeSheets: {
      type: Array,
      required: true,
    },
    showActionBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    TableColumnSelectorMenu,
    TimeSheetStatusChip,
    TimeSheetTypeChip,
  },
  data() {
    return {
      search: "",
      timeSheetsPersonFilter: "own",
      timeSheetsStateFilter: "active",
      timeSheetsSourceFilter: "manual",
      timeSheetsPayrollTypeFilter: [],
      currentUserUid: auth.currentUser.uid,
      footerProps: {
        "items-per-page-options": [15, 25, 50, 100, -1],
      },

      allColumnHeaders: [
        {
          text: "Vorname",
          sortKey: 1,
          default: true,
          value: "user.firstName",
        },
        {
          text: "Nachname",
          sortKey: 2,
          default: true,
          value: "user.lastName",
          divider: true,
        },
        {
          text: "Tätigkeit",
          value: "title",
          default: true,
          sortKey: 3,
          divider: true,
        },
        {
          text: "Typ",
          sortKey: 4,
          default: true,
          value: "type",
          filter: (value) => {
            if (!this.timeSheetsSourceFilter) return true;
            if (this.timeSheetsSourceFilter === "all") return true;
            if (this.timeSheetsSourceFilter === "dutyReport") {
              return value === "dutyReport";
            }
            if (this.timeSheetsSourceFilter === "manual") {
              return value !== "dutyReport";
            }
          },
          divider: true,
        },
        {
          text: "Startzeitpunkt",
          sortKey: 5,
          default: true,
          value: "billableDuration.start.timestamp",
          divider: true,
        },
        {
          text: "Endzeitpunkt",
          sortKey: 6,
          default: true,
          value: "billableDuration.end.timestamp",
          divider: true,
        },
        {
          text: "Ort",
          sortKey: 7,
          default: false,
          value: "additionalData.location.name",
          divider: true,
        },
        {
          text: "Einsatzmittel",
          sortKey: 8,
          default: false,
          value: "additionalData.assignedPosition.unit.title",
          divider: true,
        },
        {
          text: "Funktion",
          sortKey: 9,
          default: false,
          value: "additionalData.assignedPosition.position.shortTitle",
          divider: true,
        },
        {
          text: "Einsatzmittel/Funktion",
          sortKey: 10,
          default: false,
          value: "additionalData.assignedPosition",
          divider: true,
        },
        {
          text: "Status",
          sortKey: 11,
          default: true,
          value: "status",
          filter: (value) => {
            if (!this.timeSheetsStateFilter) return true;
            if (this.timeSheetsStateFilter === "all") return true;
            return value === this.timeSheetsStateFilter;
          },
          divider: true,
        },
        {
          text: "Abrechnungsgruppe",
          sortKey: 12,
          default: false,
          value: "payrollType.group.title",
          sort: (a, b) => a.title.localeCompare(b.title),
          divider: true,
        },
        {
          text: "Abrechnungsart",
          sortKey: 13,
          default: true,
          value: "payrollType",
          filter: (value) => {
            if (!this.timeSheetsPayrollTypeFilter) return true;
            if (this.timeSheetsPayrollTypeFilter.includes(value.id)) {
              return true;
            }
            return false;
          },
          sort: (a, b) => a.title.localeCompare(b.title),
          divider: true,
        },
        {
          text: "Anzahl in H",
          sortKey: 20,
          default: false,
          value: "totalSalary.baseVariables.HOURS",
          divider: true,
        },
        {
          text: "Aufwand in EUR",
          sortKey: 21,
          default: false,
          value: "totalSalary.amount",
          divider: true,
        },
        {
          sortKey: 100,
          value: "actions",
          default: true,
          sortable: false,
        },
      ],
      headers: [],
    };
  },
  computed: {
    payrollTypes() {
      return this.$store.state.accounting.payrollTypes;
    },
    payrollTypesList() {
      if (!this.payrollTypes || !this.payrollTypes.length) return [];

      // Group all types together by their "group.id"
      const groupedItems = this.payrollTypes.reduce((acc, item) => {
        if (!acc[item.group.id]) acc[item.group.id] = [];
        acc[item.group.id].push(item);
        return acc;
      }, {});

      // Sort each group by their "sortKey"
      const sortedGroups = Object.values(groupedItems).map((group) =>
        group.sort((a, b) => a.sortKey - b.sortKey)
      );

      // Insert a header with the group.title in front of every group and a divider after every group
      return sortedGroups.flatMap((group) => [
        { header: group[0].group.title },
        ...group,
        { divider: true },
      ]);
    },
    showAllTimeSheets() {
      return this.$store.getters["organization/checkPermissionByID"](
        `${ACCOUNTING_TIME_SHEETS_read_all}`
      );
    },
    timeSheetsInput() {
      const output = this.timeSheets.map((timeSheet) => {
        return {
          ...timeSheet,
          groupBy: this.groupByDateText(
            timeSheet.billableDuration.start.timestamp.toDate()
          ),
        };
      });
      return output;
    },
    timeSheetsOutput() {
      return this.timeSheetsInput.filter((timeSheet) => {
        // Filter nach Person
        if (
          this.timeSheetsPersonFilter === "own" &&
          timeSheet.user.uid !== this.currentUserUid
        ) {
          return false;
        }
        return true;
      });
    },
    allPayrollTypesSelected() {
      return (
        this.timeSheetsPayrollTypeFilter.length > 0 &&
        this.timeSheetsPayrollTypeFilter.length ===
          this.payrollTypesList.filter((type) => !type.header && !type.divider)
            .length
      );
    },
    somePayrollTypesSelected() {
      return (
        this.timeSheetsPayrollTypeFilter.length > 0 &&
        this.timeSheetsPayrollTypeFilter.length <
          this.payrollTypesList.filter((type) => !type.header && !type.divider)
            .length
      );
    },
    payrollTypeSelectorIcon() {
      if (this.allPayrollTypesSelected) return "mdi-checkbox-marked";
      if (this.somePayrollTypesSelected) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  created() {
    this.getPayrollTypes();
    this.initializeHeaders();
  },
  methods: {
    routeDetails(item) {
      if (!this.showActionBtn) {
        this.openTimeSheet(item);
      }
    },
    getPayrollTypes() {
      return this.$store
        .dispatch(`${ACCOUNTING}${GET_PAYROLL_TYPES}`, {
          organizationId: this.$route.params.organizationId,
        })
        .then(() => {
          this.timeSheetsPayrollTypeFilter = this.payrollTypesList
            .filter((type) => !type.header && !type.divider)
            .map((type) => type.meta.id);
        });
    },
    initializeHeaders() {
      // if (this.timeSheetsPersonFilter === "all") {
      // Add all "default" = true items from allColumnHeaders
      this.headers = this.allColumnHeaders.filter((header) => header.default);
      // } else {
      //   // Add all "default" = true items from allColumnHeaders but ignore the first two items
      //   this.headers = this.allColumnHeaders.filter(
      //     (header, index) => header.default && index > 1
      //   );
      // }
    },
    selectAllPayrollTypes() {
      this.$nextTick(() => {
        if (this.allPayrollTypesSelected) {
          this.timeSheetsPayrollTypeFilter = [];
        } else {
          this.timeSheetsPayrollTypeFilter = this.payrollTypes.slice();
        }
      });
    },
    getHoursSum(items) {
      return items.reduce((acc, item) => {
        return acc + item.totalSalary.baseVariables.HOURS;
      }, 0);
    },
    getCompensationSum(items) {
      return items.reduce((acc, item) => {
        return acc + item.totalSalary.amount;
      }, 0);
    },
    openTimeSheet(item, type = "default") {
      if (type === "newTab") {
        window.open(
          this.$router.resolve({
            name: "accounting-time-sheets-details",
            params: {
              organizationId: this.$route.params.organizationId,
              itemId: item.meta.id,
            },
          }).href,
          "_blank"
        );
        return;
      } else {
        this.$router.push({
          name: "accounting-time-sheets-details",
          params: {
            organizationId: this.$route.params.organizationId,
            itemId: item.meta.id,
          },
        });
      }
    },
    groupByDateText(date) {
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      const monthLong = date.toLocaleString("de-DE", { month: "long" });
      return year + "-" + month + "_" + monthLong + " " + year;
    },
    stripPrefix(item) {
      return item.substring(item.indexOf("_") + 1);
    },
    getCountOfTimeSheetsInMonth(monthId) {
      return this.timeSheetsOutput.filter(
        (report) => report.groupBy === monthId
      ).length;
    },
  },
};
</script>
