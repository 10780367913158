<template>
  <div>
    <template>
      <v-container>
        <v-row>
          <v-col v-if="mode === 'edit'" cols="12">
            <v-alert border="left" colored-border type="info" elevation="2">
              Die Aktualisierung der Dienstart wirkt sich nur auf die Erstellung
              neuer Berichte aus. Bereits erfasste Berichte werden nicht
              angepasst.
            </v-alert>
          </v-col>
          <v-col cols="12">
            <Subheader
              icon="mdi-format-list-bulleted-type"
              title="Allgemein"
            ></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="5">
                        <v-text-field
                          v-model.trim="dutyTypeLocal.title"
                          :counter="32"
                          label="Bezeichnung"
                          required
                          :rules="[rules.required]"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model.trim="dutyTypeLocal.shortTitle"
                          :counter="6"
                          label="Kurzbezeichnung"
                          required
                          :rules="[rules.required]"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="3">
                        <v-text-field
                          v-model.number="dutyTypeLocal.sortKey"
                          label="Sortierschlüssel"
                          prepend-inner-icon="mdi-sort-numeric-ascending"
                          type="number"
                          hint="Veranstaltungsarten werden aufsteigend nach ihrem Sortierschlüssel sortiert."
                          persistent-hint
                          required
                          :rules="[rules.required]"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model.trim="dutyTypeLocal.description"
                          clearable
                          label="Kurzbeschreibung"
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="match-height">
                      <v-col cols="12" md="6">
                        <v-card outlined>
                          <v-card-text>
                            Icon auswählen
                            <data-avatar
                              :icon="dutyTypeLocal.icon"
                              :color="dutyTypeLocal.color"
                            ></data-avatar>
                            <v-text-field
                              v-model="dutyTypeLocal.icon"
                              label="Icon ID"
                              persistent-hint
                              required
                              prefix="mdi-"
                              :rules="[rules.required]"
                              outlined
                              hint="ID von Icon aus Material Design Icons Bibiliothek angeben"
                              prepend-inner-icon="mdi-emoticon"
                              class="mt-4"
                            >
                              <template v-slot:append-outer
                                ><v-btn
                                  depressed
                                  small
                                  @click="openMdiIconLibrary"
                                  >Icons durchsuchen
                                  <v-icon right>mdi-open-in-new</v-icon></v-btn
                                ></template
                              ></v-text-field
                            >
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-card outlined>
                          <v-card-text>
                            Farbe auswählen
                            <v-color-picker
                              v-model="dutyTypeLocal.color"
                              :swatches="availableColors"
                              mode="hexa"
                              hide-inputs
                              hide-canvas
                              hide-sliders
                              show-swatches
                              dot-size="2"
                              width="1200"
                              swatches-max-height="150"
                              class="ma-0 pa-0"
                            ></v-color-picker>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12">
            <Subheader
              icon="mdi-form-textarea"
              title="Vorausgefüllte Felder"
            ></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          v-model.trim="dutyTypeLocal.prefill.title"
                          label="Bezeichnung"
                          required
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          v-model.trim="dutyTypeLocal.prefill.location.name"
                          label="Ort"
                          hide-details="auto"
                          required
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          v-model="dutyTypeLocal.prefill.payrollType"
                          :items="payrollTypes"
                          label="Primäre Abrechnungsart"
                          item-text="title"
                          item-value="id"
                          outlined
                          hide-details="auto"
                          return-object
                          required
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import Subheader from "@/components/_systemwide/Subheader.vue";
import DataAvatar from "@/components/_systemwide/DataAvatar.vue";
import { ACCOUNTING } from "@/store/modules.js";
import { GET_PAYROLL_TYPES } from "@/store/action-types.js";
export default {
  name: "duty-type-editor",
  props: {
    dutyType: {},
    mode: {
      type: String,
      default: "new",
      required: true,
      validator: function (value) {
        return ["new", "edit"].indexOf(value) !== -1;
      },
    },
  },
  components: {
    Subheader,
    DataAvatar,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
      availableColors: [
        ["#F44336"],
        ["#E91E63"],
        ["#9C27B0"],
        ["#673AB7"],
        ["#3F51B5"],
        ["#2196F3"],
        ["#03A9F4"],
        ["#00BCD4"],
        ["#009688"],
        ["#4CAF50"],
        ["#8BC34A"],
        ["#CDDC39"],
        ["#FFEB3B"],
        ["#FFC107"],
        ["#FF9800"],
        ["#FF5722"],
        ["#795548"],
        ["#607D8B"],
        ["#9E9E9E"],
      ],
    };
  },
  model: {
    prop: "dutyType",
    event: "dutyTypeChange",
  },
  computed: {
    dutyTypeLocal: {
      get: function () {
        return this.dutyType;
      },
      set: function (value) {
        this.$emit("dutyTypeChange", value);
      },
    },
    payrollTypes() {
      return this.$store.state.accounting.payrollTypes.map((payrollType) => {
        return {
          title: payrollType.title,
          id: payrollType.meta.id,
        };
      });
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${ACCOUNTING}${GET_PAYROLL_TYPES}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    openMdiIconLibrary() {
      var res = confirm(
        "Hinweis: Mit dem Öffnen der MDI Icon Library verlässt Du Mira One und wirst auf eine externe Internetseite weitergeleitet. Für die Inhalte können wir keine Haftung übernehmen.\n\nSuche nach einem passenden Icon und kopiere den Namen des Icons in das Eingabefeld."
      );
      if (res == true) {
        window.open("https://materialdesignicons.com/", "_blank");
      }
    },
  },
};
</script>
