var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"elevation":"2","type":"article, divider, list-item-avatar, list-item-avatar, list-item-avatar"}}):_c('v-card',{attrs:{"to":{
      name: 'member-management-performance-targets-details',
      params: {
        organizationId: _vm.$route.params.organizationId,
        itemId: _vm.performanceTarget.meta.id,
      },
    }}},[_c('v-card-title',[_vm._v(_vm._s(_vm.performanceTarget.title))]),_c('v-card-text',[_c('v-row',{staticClass:"match-height",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-subtitle',[_vm._v(" Aktuelle Erfüllung ")]),_c('v-divider'),_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.performanceTarget.series),function(performanceTarget,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(performanceTarget.name))]),_c('td',{staticClass:"text-right"},[_c('v-chip',{attrs:{"small":"","outlined":"","label":""}},[_vm._v("XX")])],1)])}),0)]},proxy:true}])}),_c('v-divider'),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"sm"}},[_c('v-card',{staticClass:"pa-2",attrs:{"color":"green lighten-4","dark":"","disabled":_vm.value == 0,"outlined":""}},[_c('div',{staticClass:"font-weight-medium text-h5 text-center text--darken-4 green--text"},[_vm._v(" "+_vm._s(_vm.value)+" ")]),_c('div',{staticClass:"font-weight-medium subtitle-2 text-center text--darken-4 green--text"},[_vm._v(" Erfüllt ")])])],1),_c('v-col',{attrs:{"cols":"sm"}},[_c('v-card',{staticClass:"pa-2",attrs:{"color":"red lighten-4","dark":"","disabled":_vm.value == 0,"outlined":""}},[_c('div',{staticClass:"font-weight-medium text-h5 text-center text--darken-4 red--text"},[_vm._v(" "+_vm._s(_vm.value)+" ")]),_c('div',{staticClass:"font-weight-medium subtitle-2 text-center text--darken-4 red--text"},[_vm._v(" Nicht erfüllt ")])])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"9"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-subtitle',[_vm._v(" Erfüllung letzte 14 Tage (DD.MM.YYYY bis DD.MM.YYYY) ")]),_c('apexchart',{attrs:{"type":"area","height":"200","options":_vm.performanceTarget.chartOptions,"series":_vm.performanceTarget.series}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }