<template>
  <div>
    {{ lineItem.displayType }}
    <v-data-table
      :headers="headers"
      :items="lineItem.inputs.data"
      class="elevation-2"
      :sort-by="sortByKey"
      show-expand
      item-key="meta.id"
      :expanded.sync="expandedItems"
      dense
      :no-data-text="
        'Keine Einträge für Abrechnungsgruppe ' + lineItem.title + ' vorhanden'
      "
      :mobile-breakpoint="0"
    >
      <template v-slot:top>
        <v-card flat dense>
          <v-card-title class="body-2 v-card__subtitle"
            ><v-icon left small class="mr-2">{{ headerIcon }}</v-icon
            >{{ lineItem.title }}
            <v-spacer></v-spacer>
            <v-btn
              depressed
              small
              class="mr-2"
              :disabled="lineItem.inputs.data.length === expandedItems.length"
              ><v-icon left>mdi-expand-all</v-icon>Alle ausklappen</v-btn
            >
            <v-btn
              depressed
              small
              @click="expandedItems = []"
              :disabled="!expandedItems.length"
              ><v-icon left>mdi-collapse-all</v-icon>Alle einklappen</v-btn
            >
          </v-card-title>
        </v-card>
      </template>
      <template v-slot:item.title="{ item }">
        <div class="d-flex flex-column">
          <span class="d-block font-weight-semibold text-truncate"
            >{{ item.title }}

            <v-tooltip bottom v-if="item.meta.id">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  x-small
                  text
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  :to="{
                    name: 'accounting-time-sheets-details',
                    params: {
                      organizationId: $route.params.organizationId,
                      itemId: item.meta.id,
                    },
                  }"
                  ><v-icon x-small>mdi-open-in-app</v-icon></v-btn
                >
              </template>
              <span>Stundenmeldung öffnen</span>
            </v-tooltip>
          </span>
        </div>
      </template>
      <template v-slot:item.payrollType.title="{ item }">
        <v-chip v-if="item.payrollType.title" small outlined label>{{
          item.payrollType.title
        }}</v-chip>
      </template>
      <template v-slot:item.type="{ item }">
        <time-sheet-type-chip :type="item.type" small> </time-sheet-type-chip>
      </template>
      <template v-slot:item.totalSalary.amount="{ item }">
        <pre>{{ item.totalSalary.amount }} {{ item.totalSalary.currency }}</pre>
      </template>
      <template v-slot:[`item.billableDuration.start.timestamp`]="{ item }">
        {{ timestampConverter(item.billableDuration.start.timestamp) }}
      </template>
      <template v-slot:[`item.billableDuration.end.timestamp`]="{ item }">
        {{ timestampConverter(item.billableDuration.end.timestamp) }}
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="mx-0 px-0">
          <v-card-text>
            <v-row dense>
              <v-col cols="12" sm="6" md="6">
                <v-card outlined>
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.description ? item.description : "N/A" }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="font-weight-regular"
                        >Bemerkungen</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-card outlined>
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        timestampConverter(item.workedDuration.start.timestamp)
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="font-weight-regular"
                        >Startzeitpunkt (Tatsächlich)</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-card outlined>
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        timestampConverter(item.workedDuration.end.timestamp)
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="font-weight-regular"
                        >Endzeitpunkt (Tatsächlich)</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import TimeSheetTypeChip from "@/components/accounting/time-sheets/TimeSheetTypeChip.vue";

export default {
  name: "payslips-data-table",
  props: {
    lineItem: {
      type: Object,
      required: true,
    },
  },
  components: {
    TimeSheetTypeChip,
  },
  data() {
    return {
      expandedItems: [],
      footerProps: {
        "items-per-page-options": [15, 25, 50, 100, -1],
      },
    };
  },
  computed: {
    payslipsOutput() {
      return this.payslips.filter((leave) => {
        if (this.payslipsPersonFilter === "own") {
          return leave.user.uid === this.currentUserUid;
        }
        return true;
      });
    },
    headers() {
      switch (this.lineItem.displayType) {
        case "dutyTime":
          return [
            { text: "Tätigkeit", value: "title" },
            // {
            //   text: "Einsatzmittel/Position",
            //   value: "assignedPosition.unit.title",
            // },
            { text: "Quelle", value: "type" },
            { text: "Abrechnungsart", value: "payrollType.title" },
            {
              text: "Startzeitpunkt (Abrechenbar)",
              value: "billableDuration.start.timestamp",
            },
            {
              text: "Endzeitpunkt (Abrechenbar)",
              value: "billableDuration.end.timestamp",
            },
            {
              text: "Geleistete Zeit in H",
              value: "billableDuration.inHours",
              align: "end",
            },
            {
              text: "Summe in EUR",
              value: "totalSalary.amount",
              align: "end",
              divider: true,
            },
            { text: "", value: "data-table-expand", width: "1%" },
          ];
        case "courseTime":
          return [
            {
              text: "Lehrgangsbezeichnung",
              value: "additionalData.externalName",
            },
            { text: "Lehrgangskennung", value: "additionalData.externalId" },
            { text: "Abrechnungsart", value: "payrollType.title" },
            {
              text: "Startzeitpunkt (Abrechenbar)",
              value: "billableDuration.start.timestamp",
            },
            {
              text: "Endzeitpunkt (Abrechenbar)",
              value: "billableDuration.end.timestamp",
            },
            {
              text: "Geleistete Zeit in D",
              value: "billableDuration.inHours",
              align: "end",
            },
            {
              text: "Summe in EUR",
              value: "totalSalary.amount",
              align: "end",
              divider: true,
            },
            { text: "", value: "data-table-expand", width: "1%" },
          ];
        case "default":
        default:
          return [];
      }
    },
    sortByKey() {
      return "title";
    },
    headerIcon() {
      return "mdi-clock-time-four-outline";
    },
    noDataText() {
      return "";
    },
  },
  methods: {
    timestampConverter(timestamp) {
      const options = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
  },
};
</script>
