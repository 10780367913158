<template>
  <div v-if="responses.length > 0">
    <v-card
      v-if="responses.length == 1 && responses[0].attendanceStatus != '-2'"
      flat
      height="42px"
      light
      class="d-flex align-center"
      :class="[
        $vuetify.theme.dark ? 'border-dark' : 'border-light',
        getStatusColor(
          responses[0].attendanceStatus,
          $vuetify.theme.dark ? 'dark' : 'light',
          'background'
        ),
      ]"
      @click="toggleResponseDetailDialog"
    >
      <v-card-title class="px-2">
        <v-icon
          :color="
            getStatusColor(
              responses[0].attendanceStatus,
              $vuetify.theme.dark ? 'dark' : 'light',
              'text'
            )
          "
          >{{ getStatusIcon(responses[0].attendanceStatus) }}</v-icon
        >
      </v-card-title>
      <v-card-text class="text-caption px-1 py-1" style="line-height: 1.2">
        <strong v-if="responses[0].isWaiver">Verzicht</strong>
        <strong>{{ responsesUnitPositionDisplay.unitTitle }}</strong
        ><br />
        {{ responsesUnitPositionDisplay.positionTitle }}
      </v-card-text>
    </v-card>
    <v-card
      v-else-if="responses.length > 1"
      flat
      height="42px"
      light
      class="d-flex align-center"
      :class="[
        $vuetify.theme.dark ? 'border-dark' : 'border-light',
        getStatusColor(
          responses[0].attendanceStatus,
          $vuetify.theme.dark ? 'dark' : 'light',
          'background'
        ),
      ]"
      @click="toggleResponseDetailDialog"
    >
      <v-card-title class="px-2">
        <v-icon
          :color="
            getStatusColor(
              responses[0].attendanceStatus,
              $vuetify.theme.dark ? 'dark' : 'light',
              'text'
            )
          "
          >{{ getStatusIcon(responses[0].attendanceStatus) }}</v-icon
        >
      </v-card-title>
      <v-divider vertical></v-divider>
      <v-card-text class="text-caption px-1 py-1" style="line-height: 1.2">
        <strong>{{ responses.length }} Einträge</strong><br />
      </v-card-text>
    </v-card>
    <CustomDialog
      v-if="showResponseDetailDialog"
      @close="toggleResponseDetailDialog()"
      title="Anwesenheitseinträge"
      fullscreenOnMobile
      width="s"
      showCloseBtn
      hideCloseBtn
      hideActionBtn
    >
      <template v-slot:content>
        <v-card outlined>
          <v-card-title
            class="py-2 subtitle-1 font-weight-medium"
            style="word-break: break-word"
          >
            {{ responses[0].user.displayName }}
            <v-spacer></v-spacer>
          </v-card-title>
          <v-divider></v-divider>
          <v-list class="py-0">
            <div v-for="(attendanceEntry, index) in responses" :key="index">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-regular subtitle-2"
                    ><strong>Eintrag {{ index + 1 }}:</strong>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <attendance-status-chip
                    :status="attendanceEntry.attendanceStatus"
                    small
                  ></attendance-status-chip>
                </v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-content class="pt-0">
                  <v-list-item-subtitle>
                    <p style="line-height: 1.6" class="mb-0">
                      <v-icon small left>mdi-calendar-clock</v-icon
                      >{{
                        timestampConverter(
                          attendanceEntry.duration.start.timestamp
                        )
                      }}
                      bis
                      {{
                        tryToDisplayOnlyEndTime(
                          attendanceEntry.duration.start.timestamp,
                          attendanceEntry.duration.end.timestamp,
                          attendanceEntry.duration.end.timestamp
                        )
                      }}
                      <span
                        v-if="
                          attendanceEntry.attendanceStatus == '1' &&
                          attendanceEntry.payrollType.title
                        "
                      >
                        <br />
                        <v-icon small left>mdi-account-credit-card</v-icon
                        >{{ attendanceEntry.payrollType.title }}
                      </span>
                      <br />
                      <span
                        v-if="
                          attendanceEntry.attendanceStatus == '1' &&
                          attendanceEntry.assignedPosition
                        "
                      >
                        <v-icon small left>mdi-fire-truck</v-icon
                        >{{ attendanceEntry.assignedPosition.unit.title }} –
                        {{
                          attendanceEntry.assignedPosition.position.shortTitle
                        }}
                        <br />
                      </span>
                      <span v-if="attendanceEntry.note">
                        <br />
                        <v-icon small left>mdi-card-text</v-icon
                        >{{ attendanceEntry.note }}
                      </span>
                    </p>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="responses.length > 1"></v-divider>
            </div>
          </v-list>
        </v-card>
      </template>
    </CustomDialog>
  </div>
</template>

<script>
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import AttendanceStatusChip from "@/components/duty-management/AttendanceStatusChip.vue";
// import ResponseEditor from "@/components/scheduling/ResponseEditor.vue";
export default {
  name: "teamplanner-response-status-card",
  components: {
    CustomDialog,
    AttendanceStatusChip,
    //  ResponseEditor
  },
  props: {
    responses: {
      type: Array,
      required: true,
    },
    dutyReport: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showResponseDetailDialog: false,
    };
  },
  computed: {
    responsesComment() {
      return this.responses
        .map((response) => response.comment)
        .filter((comment) => comment && comment.trim() !== "");
    },
    responsesUnitPositionDisplay() {
      const unitTitles = new Set();
      const positionTitles = new Set();

      this.responses.forEach((response) => {
        if (response.assignedPosition.unit.title) {
          unitTitles.add(response.assignedPosition.unit.title);
        }
        if (response.assignedPosition.position.shortTitle) {
          positionTitles.add(response.assignedPosition.position.shortTitle);
        }
      });

      return {
        unitTitle: [...unitTitles].join(", "),
        positionTitle: [...positionTitles].join(", "),
      };
    },
  },
  methods: {
    toggleResponseDetailDialog() {
      this.showResponseDetailDialog = !this.showResponseDetailDialog;
    },
    tryToDisplayOnlyEndTime(startTimestamp, endTimestamp, endTimestampString) {
      var startDate = startTimestamp.toDate();
      var endDate = endTimestamp.toDate();
      if (
        startDate.getDate() == endDate.getDate() &&
        startDate.getMonth() == endDate.getMonth() &&
        startDate.getFullYear() == endDate.getFullYear()
      ) {
        return endDate.toLocaleTimeString("de-DE", {
          hour: "2-digit",
          minute: "2-digit",
        });
      } else {
        return endTimestampString;
      }
    },
    timestampConverter(timestamp) {
      const options = {
        weekday: "short",
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
    getStatusColor(status, theme = "light", colorScheme = "background") {
      if (colorScheme === "background") {
        switch (status) {
          case "-1":
            return theme === "dark" ? "purple lighten-4" : "purple lighten-4";
          case "0":
          case "Unknown":
            return "blue-grey lighten-2";
          case "1":
          case "present":
            return theme === "dark" ? "green lighten-4" : "green lighten-4";
          case "2":
          case "excused":
            return theme === "dark" ? "orange lighten-4" : "orange lighten-4";
          case "3":
          case "unexcused":
            return theme === "dark" ? "red lighten-4" : "red lighten-4";
          case "":
          default:
            return "blue-grey lighten-2";
        }
      } else {
        switch (status) {
          case "-1":
            return theme === "dark" ? "purple darken-2" : "purple darken-2";
          case "0":
          case "Unknown":
            return "blue-grey darken-2";
          case "1":
          case "present":
            return theme === "dark" ? "green darken-2" : "green darken-2";
          case "2":
          case "excused":
            return theme === "dark" ? "orange darken-2" : "orange darken-2";
          case "3":
          case "unexcused":
            return theme === "dark" ? "red darken-2" : "red darken-2";
          case "":
          default:
            return "blue-grey darken-2";
        }
      }
    },
    getStatusIcon(status) {
      switch (status) {
        case "-1":
          return "mdi-cancel";
        case "0":
        case "Unknown":
          return "mdi-help-circle";
        case "1":
        case "present":
          return "mdi-check-circle";
        case "2":
        case "excused":
          return "mdi-close-circle";
        case "3":
        case "unexcused":
          return "mdi-close-octagon";
        case "4":
        case "":
        default:
          return "mdi-alert-outline";
      }
    },
  },
};
</script>

<style>
.border-light {
  border: thin solid rgba(0, 0, 0, 0.12) !important;
}

.border-light.lighten-4 {
  border: thin solid rgba(0, 0, 0, 0.12) !important;
}

.border-dark {
  border: thin solid hsla(0, 0%, 100%, 0.12) !important;
}

.border-dark.lighten-4 {
  border: thin solid hsla(0, 0%, 100%, 0.12) !important;
}
</style>
