<template>
  <div>
    <section>
      <TippCard
        hintID="Mitgliederverwaltung_Abteilungen_1_V1"
        text="Mitglieder können in einzelnen Abteilungen gruppiert und organisiert werden."
      >
      </TippCard>
      <v-snackbar v-model="helpers.snackbar.model" timeout="5000"
        >{{ helpers.snackbar.text }}.</v-snackbar
      >
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-home-group"
                title="Abteilungen"
                :actions="actions"
              ></Subheader>
              <div class="mt-7">
                <v-data-table
                  :headers="divisionHeaders"
                  :items="divisions"
                  sort-by="sortKey"
                  :sort-desc="false"
                  class="elevation-2"
                  no-data-text="Keine Abteilungen vorhanden"
                  @click:row="openDivision"
                >
                  <template v-slot:item.isActive="{ item }">
                    <v-simple-checkbox
                      v-model="item.isActive"
                      color="primary"
                      disabled
                    ></v-simple-checkbox>
                  </template>
                </v-data-table></div
            ></v-col>
            <v-col cols="12">
              <Subheader
                icon="mdi-cogs"
                title="Abteilung für neue Benutzer"
              ></Subheader>
              <div class="mt-7">
                <v-card>
                  <v-card-text>
                    <v-container>
                      <div class="subtitle-1">
                        Diese Abteilung wird auf neue Nutzer angewendet, die zu
                        dieser Organisation eingeladen werden, soweit nicht
                        abweichende Einstellungen festgelegt werden.
                      </div>
                      <v-form
                        v-model="helpers.formIsValid"
                        @submit.prevent="updateDefaultDivision"
                        ref="form"
                      >
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-autocomplete
                              v-model="
                                helpers.defaultDivisionForm.defaultDivisionId
                              "
                              :items="divisions"
                              item-text="title"
                              item-value="meta.id"
                              required
                              :rules="[rules.required]"
                              outlined
                              label="Abteilung auswählen"
                              class="mt-4"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-form>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          depressed
                          color="success"
                          :loading="helpers.loading"
                          :disabled="!helpers.formIsValid"
                          @click="updateDefaultDivision()"
                        >
                          <v-icon left> mdi-content-save </v-icon>
                          Speichern
                        </v-btn>
                      </v-card-actions>
                    </v-container>
                  </v-card-text>
                </v-card>
              </div>
            </v-col></v-row
          ></v-container
        ></template
      >
    </section>
    <custom-dialog
      v-if="helpers.dataImport.dialogModel"
      @close="toggleImportDialog()"
      title="Abteilungen importieren"
      text="Importierte Abteilungen stehen nach dem Import sofort zur Verfügung."
      width="m"
      :errorMessage="helpers.dataImport.errorMessage"
      :btnAction="startDataImport"
      :btnDisabled="helpers.dataImport.selectedImportTemplates.length === 0"
      :loading="helpers.dataImport.loading"
      btnText="Auswahl importieren"
    >
      <template v-slot:content>
        <v-form
          v-model="helpers.dataImport.formIsValid"
          @submit.prevent="startDataImport()"
        >
          <v-card outlined class="mt-2">
            <v-data-table
              v-model="helpers.dataImport.selectedImportTemplates"
              :items="helpers.dataImport.importTemplates"
              :headers="helpers.dataImport.tableHeaders"
              :search="helpers.dataImport.search"
              item-key="title"
              show-select
              :sort-by="helpers.dataImport.sortSelector"
              :sort-desc="false"
              checkbox-color="primary"
              disable-pagination
              hide-default-footer
              mobile-breakpoint="0"
              fixed-header
              height="35vh"
              class="mt-1"
              no-results-text="Keine Ergebnisse für Suchbegriff"
            >
              <template v-slot:top>
                <v-card flat>
                  <v-card-subtitle>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="helpers.dataImport.search"
                          label="Nach Abteilung suchen"
                          prepend-inner-icon="mdi-magnify"
                          dense
                          hide-details="auto"
                          outlined
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <span class="mr-4">Sortieren nach</span>
                        <v-chip-group
                          v-model="helpers.dataImport.sortSelector"
                          mandatory
                          active-class="primary--text"
                        >
                          <v-chip
                            label
                            value="sortKey"
                            class="transparent-background font-weight-medium mr-2 mb-3"
                            ><v-icon left small>mdi-sort-numeric-variant</v-icon
                            >Sortierschlüssel
                          </v-chip>
                          <v-chip
                            label
                            value="title"
                            class="transparent-background font-weight-medium mb-3"
                            ><v-icon left small
                              >mdi-sort-alphabetical-variant</v-icon
                            >Alphabetisch</v-chip
                          >
                        </v-chip-group>
                      </v-col>
                    </v-row>
                  </v-card-subtitle>
                </v-card>
                <v-divider></v-divider>
              </template>
              <template v-slot:[`item.title`]="{ item }">
                <div class="d-flex flex-column">
                  <span
                    class="d-block font-weight-semibold text--primary text-truncate"
                    >{{ item.title }}</span
                  >
                  <small class="grey--text">{{ item.shortTitle }}</small>
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-form>
      </template>
    </custom-dialog>
  </div>
</template>

<script>
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import { importableDivisions } from "@/data/import/importable-divisions.js";
import { ADMIN, MEMBER_MANAGEMENT } from "@/store/modules.js";
import {
  GET_DIVISIONS,
  CREATE_DIVISIONS,
  UPDATE_DEFAULT_DIVISION,
} from "@/store/action-types.js";
import { MEMBER_MANAGEMENT_CONFIG_DIVISIONS_create } from "@/data/permission-types.js";

export default {
  name: "member-management-config-divisions",
  components: {
    TippCard,
    Subheader,
    CustomDialog,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Erforderlich.",
      },
      actions: [
        {
          title: "Erstellen",
          icon: "mdi-plus",
          permission: `${MEMBER_MANAGEMENT_CONFIG_DIVISIONS_create}`,
          actionStyle: "textBtn",
          function: () => {
            this.$router.push({
              name: "member-management-config-divisions-new",
            });
          },
        },
        {
          title: "Importieren",
          icon: "mdi-import",
          permission: `${MEMBER_MANAGEMENT_CONFIG_DIVISIONS_create}`,
          actionStyle: "textBtn",
          function: this.toggleImportDialog,
        },
      ],
      divisionHeaders: [
        {
          text: "Kurzbezeichnung",
          value: "shortTitle",
          sortable: false,
        },
        { text: "Bezeichnung", value: "title", sortable: false },
        {
          text: "Sortierschlüssel",
          value: "sortKey",
          sortable: true,
        },
        {
          text: "Aktiv",
          value: "isActive",
          sortable: true,
        },
      ],
      helpers: {
        defaultDivisionForm: {
          loading: false,
          formIsValid: false,
          defaultDivisionId: "",
        },
        dataImport: {
          dialogModel: false,
          loading: false,
          formIsValid: false,
          importTemplates: importableDivisions,
          sortSelector: "priority",
          errorMessage: "",
          search: "",
          selectedImportTemplates: [],
          tableHeaders: [
            {
              text: "Titel",
              value: "title",
              align: "start",
            },
            { text: "Sortierschlüssel", value: "sortKey" },
          ],
        },
        snackbar: { model: false, text: "" },
      },
    };
  },
  computed: {
    divisions() {
      return this.$store.state.memberManagement.divisions;
    },
    defaultDivisionId() {
      return this.$store.state.organization.activeOrganization.config
        .memberManagement.defaultDivisionId;
    },
  },
  watch: {
    defaultDivisionId(newValue) {
      this.helpers.defaultDivisionForm.defaultDivisionId = newValue;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.helpers.defaultDivisionForm.defaultDivisionId =
        this.defaultDivisionId;
      this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_DIVISIONS}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    openDivision(item) {
      this.$router.push({
        name: "member-management-config-divisions-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item.meta.id,
        },
      });
    },
    updateDefaultDivision() {
      this.$refs.form.validate();
      if (this.helpers.formIsValid) {
        this.helpers.loading = true;
        this.$store
          .dispatch(`${ADMIN}${UPDATE_DEFAULT_DIVISION}`, {
            organizationId: this.$route.params.organizationId,
            defaultDivisionId:
              this.helpers.defaultDivisionForm.defaultDivisionId,
          })
          .then(() => {
            this.helpers.loading = false;
            this.helpers.snackbar.model = true;
            this.helpers.snackbar.text = "Einstellung aktualisiert";
          })
          .catch((error) => {
            this.helpers.loading = false;
            this.helpers.snackbar.model = true;
            this.helpers.snackbar.text = "Fehler bei Aktualisierung";
            console.log(error);
          });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
    toggleImportDialog() {
      this.helpers.dataImport.dialogModel =
        !this.helpers.dataImport.dialogModel;
    },
    startDataImport() {
      if (this.helpers.dataImport.selectedImportTemplates.length > 0) {
        this.helpers.dataImport.loading = true;
        this.$store
          .dispatch(`${MEMBER_MANAGEMENT}${CREATE_DIVISIONS}`, {
            organizationId: this.$route.params.organizationId,

            divisions: this.helpers.dataImport.selectedImportTemplates,
          })
          .then(() => {
            return this.initialize();
          })
          .then(() => {
            this.toggleImportDialog();
            this.helpers.dataImport.loading = false;
            this.helpers.dataImport.selectedImportTemplates = [];
            this.helpers.dataImport.search = "";
            this.helpers.snackbar.model = true;
            this.helpers.snackbar.text = "Import erfolgreich";
          })
          .catch((error) => {
            this.helpers.dataImport.loading = false;
            console.log(error);
            this.helpers.dataImport.errorMessage = error.message;
          });
      } else {
        alert(
          "Wähle mindestens eine Abteilung aus, um den Import duchrzuführen."
        );
      }
    },
  },
};
</script>
