<template>
  <div>
    <section>
      <TippCard
        hintID="Abrechnung_Konfiguration_Genehmigungsprozess_1_V1"
        text="Dokumentierte Stundenmeldungen unterliegen einem frei konfigurierbaren Genehmigungsprozess."
      >
      </TippCard>
    </section>
    <v-snackbar v-model="helpers.snackbar.model" timeout="5000"
      >{{ helpers.snackbar.text }}.</v-snackbar
    >
    <section>
      <template>
        <v-container>
          <v-row>
            <v-col cols="12">
              <Subheader
                icon="mdi-check-decagram"
                title="Genehmigungsprozess für Stundenmeldungen (Standard)"
                :actions="actions"
              ></Subheader>
              <div class="mt-7">
                <approval-process-editor
                  v-model="accountingConfig.timeSheets.approvalProcess"
                ></approval-process-editor>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import ApprovalProcessEditor from "@/components/processes/ApprovalProcessEditor.vue";
// import { DUTY_MANAGEMENT_CONFIG_REPORT_TYPES_create } from "@/data/permission-types.js";

export default {
  name: "accounting-config-tab-approval-process",
  components: {
    TippCard,
    Subheader,
    ApprovalProcessEditor,
    // CustomDialog,
  },
  data() {
    return {
      helpers: {
        snackbar: { model: false, text: "" },
      },
    };
  },
  computed: {
    accountingConfig() {
      return this.$store.state.organization.activeOrganization.config
        .accounting;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
  },
};
</script>
