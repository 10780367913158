<template>
  <div>
    <section>
      <TippCard
        hintID="Admin_Benutzer_Tab_Benutzergruppen_1_V1"
        text="Benutzergruppen dienen der einfachen Sortierung von Benutzern innerhalb der Organisation. Informationen werden in der Anwendung auch nach diesen Kriterien gefiltert. Du kannst die Zuweisung von Benutzerkonten zu Benutzergruppen automatisieren und bspw. von Qualifikationen abhängig machen."
      >
      </TippCard>
    </section>
    <section>
      <template>
        <v-container fluid>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-folder-account"
                title="Benutzergruppen"
                :actions="actions"
              ></Subheader>
              <div class="mt-7">
                <v-data-table
                  :headers="headers"
                  :items="userGroups"
                  sort-by="sortKey"
                  :sort-desc="false"
                  class="elevation-2"
                  no-data-text="Keine Benutzergruppen vorhanden"
                  @click:row="openDetails"
                >
                </v-data-table>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import { ADMIN } from "@/store/modules.js";
import { GET_USER_GROUPS } from "@/store/action-types.js";
import { ADMINISTRATION_USERS_GROUPS_create } from "@/data/permission-types.js";

export default {
  name: "admin-users-tab-groups",
  components: {
    TippCard,
    Subheader,
  },
  data() {
    return {
      sortDesc: false,
      sortBy: "sortKey",
      headers: [
        {
          text: "Sortierschlüssel",
          value: "sortKey",
          divider: true,
        },
        {
          text: "Name",
          value: "title",
        },
        {
          text: "Beschreibung",
          value: "description",
        },
      ],
      actions: [
        {
          title: "Erstellen",
          icon: "mdi-plus-circle",
          permission: `${ADMINISTRATION_USERS_GROUPS_create}`,
          actionStyle: "textBtn",
          function: this.createItem,
        },
      ],
    };
  },
  computed: {
    userGroups() {
      return this.$store.state.admin.userGroups;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${ADMIN}${GET_USER_GROUPS}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    createItem() {
      this.$router.push({ name: "admin-users-tab-groups-group-new" });
    },
    openDetails(item) {
      this.$router.push({
        name: "admin-users-tab-groups-group-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item.meta.id,
        },
      });
    },
  },
};
</script>
